import { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import './styles.scss';
import { FilterSelect, FilterMultipleSelectSearch, FilterSelectSearch } from '@components/globalFilter';
import { CouponIcon, MarketPlaceIcon } from '@assets/icons';
import { updateFilterQueriesHandler } from '@helpers/util';
import { CalendarIcon, PeopleIcon1 } from '@assets/icons';

import { fetchAssetsHandler } from '../../helpers/crud';

import 'react-datepicker/src/stylesheets/datepicker.scss';

const SectionHead = ({ state, filter, onChangeFilter, assets, onChangeAssets }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        /* eslint-disable */
        fetchAssetsHandler({ onChangeAssets });
    }, []);

    return (
        <Flex className="section-header__container__reporting-achievement">
            {/** -------------------------- LEFT SIDE ----------------------------**/}

            <Flex className="left-side">
                <Text className="title">Reporting Achievement</Text>
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            <Flex className="right-side" gap={4}>
                <FilterMultipleSelectSearch
                    placeholder="Pilih brand"
                    icon={<CouponIcon color="gray" />}
                    options={assets?.brands?.map((brand) => {
                        return {
                            id: brand?.name,
                            name: brand?.name,
                            value: brand?.name,
                        };
                    })}
                    filterName="brand"
                    onChange={({ type, value }) => {
                        updateFilterQueriesHandler({
                            location,
                            type: 'brand',
                            value: `[${value}]`,
                            navigate,
                            valueName: type,
                        });
                    }}
                    value={filter?.brand}
                />
                {(state?.reportingType === 'Achievement Account Platform' || state?.reportingType === 'Session') && (
                    <>
                        <FilterSelectSearch
                            placeholder="Account"
                            icon={<PeopleIcon1 color="gray" />}
                            options={assets?.accounts?.map((acc) => {
                                return {
                                    name: acc,
                                    value: acc,
                                };
                            })}
                            filterName="name"
                            onChange={({ type, value }) =>
                                updateFilterQueriesHandler({
                                    location,
                                    type: 'name',
                                    value,
                                    navigate,
                                    valueName: type,
                                })
                            }
                        />

                        <FilterSelect
                            placeholder="Marketplace"
                            icon={<MarketPlaceIcon color="gray" />}
                            options={assets?.marketplaces?.map((marketplace) => {
                                return {
                                    name: marketplace?.name,
                                    value: marketplace?.name,
                                };
                            })}
                            filterName="ecommerce.name"
                            onChange={({ type, value }) =>
                                updateFilterQueriesHandler({
                                    location,
                                    type: 'ecommerce.name',
                                    value,
                                    navigate,
                                    valueName: type,
                                })
                            }
                        />
                    </>
                )}

                {/** //----------------------------- LIVE SELLING FILTER -----------------------*        */}
                {state?.reportingType === 'Achievement Account Platform' && (
                    <FilterSelect
                        placeholder="Type Account"
                        icon={<PeopleIcon1 color="gray" />}
                        options={assets?.typeAccounts?.map((acc) => {
                            return {
                                name: acc?.name,
                                value: acc?.name,
                            };
                        })}
                        filterName="accountType.name"
                        onChange={({ type, value }) =>
                            updateFilterQueriesHandler({
                                location,
                                type: 'accountType.name',
                                value,
                                navigate,
                                valueName: type,
                            })
                        }
                    />
                )}

                {/** //----------------------------- SESSION FILTER -----------------------  */}
                {state?.reportingType === 'Session' && (
                    <>
                        <Box position="relative" zIndex="5">
                            <DatePicker
                                selected={filter?.dateFilter?.startDate}
                                selectsStart
                                onChange={(value) =>
                                    onChangeFilter({
                                        dateFilter: {
                                            ...filter?.dateFilter,
                                            startDate: value,
                                        },
                                    })
                                }
                                filterDate={(date) => {
                                    return date <= filter?.dateFilter?.endDate;
                                }}
                                placeholderText="Tanggal mulai"
                                dateFormat="dd MMM yyyy"
                                wrapperClassName="session-form"
                                showIcon
                                icon={<CalendarIcon color="grey" />}
                            />
                        </Box>
                        <Box mt="8px">-</Box>
                        <Box position="relative" zIndex="5">
                            <DatePicker
                                selected={filter?.dateFilter?.endDate}
                                selectsEnd
                                onChange={(value) =>
                                    onChangeFilter({
                                        dateFilter: {
                                            ...filter?.dateFilter,
                                            endDate: value,
                                        },
                                    })
                                }
                                filterDate={(date) => {
                                    return date >= filter?.dateFilter?.startDate;
                                }}
                                placeholderText="Tanggal selesai"
                                dateFormat="dd MMM yyyy"
                                wrapperClassName="session-form"
                                showIcon
                                icon={<CalendarIcon color="grey" />}
                            />
                        </Box>
                    </>
                )}
                {/** //----------------------------- OTHER FILTER ----------------------- */}
                {state?.reportingType !== 'Achievement Account Platform' && state?.reportingType !== 'Session' && (
                    <Box position="relative" zIndex="5">
                        <DatePicker
                            selected={filter?.dateFilter?.date}
                            onChange={(value) => {
                                onChangeFilter({
                                    dateFilter: {
                                        ...filter?.dateFilter,
                                        date: value,
                                    },
                                });
                            }}
                            placeholderText="Pilih bulan"
                            dateFormat="MMM yyyy"
                            wrapperClassName="session-form"
                            showMonthYearPicker
                            showIcon
                            icon={<CalendarIcon color="grey" />}
                        />
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};

export default SectionHead;
