import { Box } from '@chakra-ui/react';
import TableTemplate from '@components/tableTemplate';
import { tableList } from './const';

import './styles.scss';

const SectionBody = ({ state, handlePageClick }) => {
    const { accounts, dataCount, totalPage, totalData, page } = state;
    return (
        <Box className="section-body__container__product-highlight">
            <TableTemplate
                tableList={tableList}
                data={accounts}
                dataCount={dataCount}
                totalData={totalData}
                page={page}
                totalPage={totalPage}
                handlePageClick={handlePageClick}
                isDynamicTable={false}
            />
        </Box>
    );
};

export default SectionBody;
