export const initialState = {
    isLoading: false,
    coins: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    selectedKoin: {},
};

export const initialAssets = {
    brands: [],
    marketPlaces: [],
    accounts: [],
};

export const initialMultiSelect = {
    isParamaConfirmation: false,
    coins: [],
}