import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { Flex, Text, Button, Box, Spinner } from '@chakra-ui/react';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { PencilIcon } from '@assets/icons';
import ModalTemplate from '@components/modal';
import { ScheduleContext } from '@pages/schedule';
import { LoadingState2 } from '@components/loadingState';
import { onDeleteEvent, onOpenEditPopUp } from '@pages/schedule/helpers/event';
import { formattingAccountsOptionHandler, formattingOptionHandler } from '@pages/schedule/helpers/utlis';
import { SelectComponent } from '@components/formFields';
import { fetchScheduleDetail, onUpdateDetailField } from '@pages/schedule/helpers/crud';

import './styles.scss';

const ScheduleDetail = ({ onClose, state, setState }) => {
    const dispatch = useDispatch();
    const { refetchScheduleHandler, assets } = useContext(ScheduleContext);
    const [detailState, setDetailState] = useState({});
    const { isOpenEditHost, selectedEvent, isLoading, isOpenEditOperator } = detailState;
    const { isLoadingDetail } = state;
    const schedule = selectedEvent?.schedule;
    const productHighlight = selectedEvent?.productHighlights;
    const coins = selectedEvent?.coins;
    const promotions = selectedEvent?.promotions;
    const vouchers = selectedEvent?.vouchers;

    const onChangeDetailState = (newState) => setDetailState({ ...detailState, ...newState });

    const refetchDetailScheduleHandler = () => {
        refetchScheduleHandler();
        fetchScheduleDetail({ id: state?.selectedEvent?.schedule?.id, onChangeDetailState });
    };
    useEffect(() => {
        if (!isEmpty(state?.selectedEvent)) {
            fetchScheduleDetail({ id: state?.selectedEvent?.schedule?.id, onChangeDetailState });
        }
    }, [state?.selectedEvent]);

    return (
        <ModalTemplate width="600px" onClose={onClose}>
            <Flex className="schedule-detail__container">
                <Flex className="header">
                    <Text className="title">Title</Text>
                    <CloseIcon color="grey" fontSize={12} onClick={onClose} />
                </Flex>
                <Flex className="body">
                    {isLoadingDetail && <LoadingState2 />}
                    {!isLoadingDetail && (
                        <>
                            <Box className="schedule-information__container">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Text className="label">Schedule Information :</Text>
                                    <Button
                                        className="button-remove"
                                        onClick={() =>
                                            onDeleteEvent({ dispatch, selectedEvent, onClose, refetchScheduleHandler })
                                        }
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Flex>
                                <Box className="box-info__item">
                                    <Text className="left-text">Title</Text>
                                    <Text className="divider-text">:</Text>
                                    <Text className="right-text">{schedule?.title} </Text>
                                </Box>
                                <Box className="box-info__item">
                                    <Text className="left-text">Account</Text>
                                    <Text className="divider-text">:</Text>
                                    <Flex className="right-text__wrapper">
                                        {schedule?.accountLivestreams?.map((item, idx) => (
                                            <Text className="right-text">
                                                {idx + 1}. {item.name} | <span>{item?.brand?.name}</span> |{' '}
                                                <span> {item?.ecommerce?.name}</span>
                                            </Text>
                                        ))}
                                    </Flex>
                                </Box>

                                <Box className="box-info__item">
                                    <Text className="left-text">Date and Time</Text>
                                    <Text className="divider-text">:</Text>
                                    <Text className="right-text">
                                        {moment(schedule?.startedAt).format('DD/MM/YYYY')}{' '}
                                        {moment(schedule?.startedAt).format('HH:mm')} -
                                        {moment(schedule?.endedAt).format('HH:mm')}
                                    </Text>
                                </Box>
                                <Box className="box-info__item">
                                    <Text className="left-text">Studio</Text>
                                    <Text className="divider-text">:</Text>
                                    <Text className="right-text"> {schedule?.studio?.name}</Text>
                                </Box>
                                <Box className="box-info__item">
                                    <Text className="left-text">Host</Text>
                                    <Text className="divider-text">:</Text>
                                    {isOpenEditHost ? (
                                        <Flex
                                            className="right-text__wrapper"
                                            mt="12px"
                                            flexDirection="row !important"
                                            alignItems="center"
                                        >
                                            <SelectComponent
                                                isMulti={true}
                                                label=""
                                                placeholder="Pilih host"
                                                value={formattingOptionHandler({ arr: schedule?.hosts })}
                                                onChange={({ value }) =>
                                                    onUpdateDetailField({
                                                        eventId: selectedEvent?.schedule?.id,
                                                        payload: { hostId: value?.map((item) => item?.id) },
                                                        refetchDetailScheduleHandler,
                                                    })
                                                }
                                                options={formattingAccountsOptionHandler({ arr: assets?.hosts })}
                                            />
                                            {isLoading ? (
                                                <Spinner color="var(--main-color)" ml="16px" />
                                            ) : (
                                                <Box
                                                    ml="16px"
                                                    cursor="pointer"
                                                    onClick={() =>
                                                        onChangeDetailState({
                                                            isOpenEditHost: false,
                                                        })
                                                    }
                                                >
                                                    <CloseIcon
                                                        color="var(--main-color)"
                                                        fontWeight="bold"
                                                        fontSize={12}
                                                    />
                                                </Box>
                                            )}
                                        </Flex>
                                    ) : (
                                        <Flex>
                                            <Flex className="right-text__wrapper">
                                                {schedule?.hosts?.map((item, idx) => (
                                                    <Text className="right-text">
                                                        {idx + 1}. {item.name}
                                                    </Text>
                                                ))}
                                            </Flex>
                                            <Box
                                                cursor="pointer"
                                                onClick={() =>
                                                    onChangeDetailState({
                                                        isOpenEditHost: true,
                                                        isOpenEditOperator: false,
                                                    })
                                                }
                                            >
                                                <PencilIcon color="var(--main-color)" />
                                            </Box>
                                        </Flex>
                                    )}
                                </Box>

                                <Box className="box-info__item">
                                    <Text className="left-text">Operator bertugas</Text>
                                    <Text className="divider-text">:</Text>
                                    {isOpenEditOperator ? (
                                        <Flex
                                            className="right-text__wrapper"
                                            mt="12px"
                                            flexDirection="row !important"
                                            alignItems="center"
                                        >
                                            <SelectComponent
                                                isMulti={true}
                                                label=""
                                                placeholder="Pilih host"
                                                value={formattingOptionHandler({ arr: schedule?.operators })}
                                                onChange={({ value }) =>
                                                    onUpdateDetailField({
                                                        eventId: selectedEvent?.schedule?.id,
                                                        payload: { operatorId: value?.map((item) => item?.id) },
                                                        refetchDetailScheduleHandler,
                                                    })
                                                }
                                                options={formattingAccountsOptionHandler({ arr: assets?.operators })}
                                            />
                                            {isLoading ? (
                                                <Spinner color="var(--main-color)" ml="16px" />
                                            ) : (
                                                <Box
                                                    ml="16px"
                                                    cursor="pointer"
                                                    onClick={() =>
                                                        onChangeDetailState({
                                                            isOpenEditOperator: false,
                                                        })
                                                    }
                                                >
                                                    <CloseIcon
                                                        color="var(--main-color)"
                                                        fontWeight="bold"
                                                        fontSize={12}
                                                    />
                                                </Box>
                                            )}
                                        </Flex>
                                    ) : (
                                        <Flex>
                                            <Flex className="right-text__wrapper">
                                                {schedule?.operators?.map((item, idx) => (
                                                    <Text className="right-text">
                                                        {idx + 1}. {item.name}
                                                    </Text>
                                                ))}
                                            </Flex>
                                            <Box
                                                cursor="pointer"
                                                onClick={() =>
                                                    onChangeDetailState({
                                                        isOpenEditHost: false,
                                                        isOpenEditOperator: true,
                                                    })
                                                }
                                            >
                                                <PencilIcon color="var(--main-color)" />
                                            </Box>
                                        </Flex>
                                    )}
                                </Box>
                            </Box>
                            <Box className="box-additional">
                                <Text className="label">Additional Live Information :</Text>
                                <Box className="box-info__additional">
                                    <Box className="box-item">
                                        <Text className="label-item">• Product Highlight</Text>
                                        <Box className="list-info">
                                            {productHighlight?.map((item) => (
                                                <>
                                                    <Text className="text-item">- {item?.title}</Text>
                                                    <Box className="rich-text__box">
                                                        {parse(draftToHtml(JSON.parse(item.description)))}
                                                    </Box>
                                                </>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box className="box-item">
                                        <Text className="label-item">• Promo Regular</Text>
                                        <Box className="list-info">
                                            {promotions?.map((item) => (
                                                <>
                                                    <Text className="text-item">- {item?.title}</Text>
                                                    <Box className="rich-text__box">
                                                        {parse(draftToHtml(JSON.parse(item.description)))}
                                                    </Box>
                                                </>
                                            ))}
                                        </Box>
                                    </Box>

                                    <Box className="box-item">
                                        <Text className="label-item">• Voucher</Text>
                                        <Box className="list-info">
                                            {vouchers?.map((item) => (
                                                <>
                                                    <Text className="text-item">- {item?.title}</Text>
                                                    <Box className="rich-text__box">
                                                        {parse(draftToHtml(JSON.parse(item.description)))}
                                                    </Box>
                                                </>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box className="box-item">
                                        <Text className="label-item">• Coin</Text>
                                        <Box className="list-info">
                                            {coins?.map((item) => (
                                                <>
                                                    <Text className="text-item">- {item?.title}</Text>
                                                    <Box className="rich-text__box">
                                                        {parse(draftToHtml(JSON.parse(item.description)))}
                                                    </Box>
                                                </>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </Flex>
                {!isLoadingDetail && (
                    <Flex className="footer">
                        <Button className="button-cancel" onClick={onClose}>
                            Close
                        </Button>
                        <Button onClick={() => onOpenEditPopUp({ setState, state, schedule })}>Edit</Button>
                    </Flex>
                )}
            </Flex>
        </ModalTemplate>
    );
};

export default ScheduleDetail;
