import { useNavigate } from "react-router-dom";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import logo from "./page-error-icon.svg";
import "./styles.scss";

const NotAvalaible = () => {
  const navigate = useNavigate();
  return (
    <Box className="not-found__container">
      <Box className="icon">
        <Image src={logo} />
      </Box>
      <Text className="title">Page Not Avalaible!</Text>
      <Text className="desc">
        Page belum tersedia. Silahkan menuju main page
      </Text>
      <Button onClick={() => navigate("/")}>Home</Button>
    </Box>
  );
};

export default NotAvalaible;
