import * as XLSX from 'xlsx';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { popupHandler, resetPopUpHandler } from '@store/list/popup';
import moment from 'moment';
import { onMappingUploadData } from './utils';
import { onRemoveTarget, onUpdateRowTarget } from './crud';

export const onChangeFile = ({ files, onChangeState, dispatch, refetchTargetUpload }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const reader = new FileReader();

    onChangeState({ uploadState: 'process', isDragging: false });

    reader.onload = async (e) => {
        //============================ READ FILE TO JSON ========================

        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const data = onMappingUploadData({ json });

        //============================ SEND TO BACKEND ========================

        try {
            const response = await Axios({
                method: 'POST',
                url: '/target/import',
                headers: { Authorization: 'Bearer ' + userToken },
                data,
            });

            const invalidData = response?.data?.data?.invalidData;
            const successDataLength = data?.lines?.length - invalidData?.length;
            const invalidaDataLength = invalidData?.length;

            setTimeout(() => {
                if (invalidData?.length) {
                    onChangeState({ uploadState: 'invalid-data', isDragging: false, invalidData });
                    dispatch(
                        popupHandler({
                            popUpType: 'popup-error',
                            popUpTitle: 'Warning',
                            popUpDescription: `${successDataLength} data berhasil terupload, ${invalidaDataLength} data gagal terupload`,
                        })
                    );
                } else {
                    onChangeState({ uploadState: '', isDragging: false });
                    refetchTargetUpload();

                    dispatch(
                        popupHandler({
                            popUpType: 'popup-success',
                            popUpTitle: 'Berhasil mengupload file',
                        })
                    );
                }
            }, 2000);
        } catch (error) {
            console.log(error, '<<<<');

            dispatch(
                popupHandler({
                    popUpType: 'popup-failed',
                    popUpTitle: 'Failed',
                })
            );
        }
    };

    reader.readAsBinaryString(files);
};

export const onDownloadTemplate = async ({ dispatch, selectedMonth, onClose }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-download',
            popUpTitle: 'Sedang mendownload data',
        })
    );

    selectedMonth = moment(selectedMonth).format('YYYY-MM');

    try {
        const res = await Axios({
            method: 'POST',
            responseType: 'blob',
            url: `/target/export-file`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                startedAt: selectedMonth,
                endedAt: selectedMonth,
            },
        });

        const urlHref = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlHref;
        link.setAttribute('download', `target template.xlsx`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        dispatch(resetPopUpHandler());
    } catch (error) {
        console.log(error, '<<<<');
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mendownload data!',
            })
        );
    } finally {
        onClose();
    }
};

export const onShowingRemovePopUp = async ({ selectedTarget, dispatch, refetchTargetUpload }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus target ini?',
            popUpDescription: '',
            onSubmit: () =>
                onRemoveTarget({
                    selectedTarget,
                    refetchTargetUpload,
                }),
        })
    );
};

export const onShowingUpdateRowPopUp = async ({ selectedTarget, dispatch, refetchTargetUpload }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: 'Apakah anda yakin ingin mengupdate target ini?',
            popUpDescription: '',
            onSubmit: () =>
                onUpdateRowTarget({
                    selectedTarget,
                    refetchTargetUpload,
                }),
        })
    );
};