import { Text, Flex, Box } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from '@assets/icons';
import { useContext } from 'react';
import { ProductHighlightContext } from '@pages/productHighlight';

const ActionComponent = ({ selectedProduct }) => {
    const { onEditColumnHandler, onShowingRemovePopUp } = useContext(ProductHighlightContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedProduct)} className="box-icon">
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedProduct)} className="remove-icon">
                <RemoveIcon />
            </Box>
        </Flex>
    );
};

export const tableList = [
    {
        name: 'Nama',
        value: 'title',
        width: 260,
        isSticky: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'title',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Marketplace',
        width: 160,
        isSticky: true,
        value: 'accountLivestreams',
        dataManipulationValue: 'accountLivestreams.ecommerce.name',
        dataManipulationType: 'search',
        component: ({ value }) => <Text className="column-text">{value[0]?.ecommerce?.name}</Text>,
    },
    {
        name: 'Account',
        width: 300,
        value: 'accountLivestreams',
        dataManipulationValue: 'accountLivestreams.name',
        dataManipulationType: 'search',
        component: ({ value }) => {
            return (
                <Flex className="account-container__product-highlight">
                    {value?.map(({ name, brands }) => (
                        <Text className="column-text">
                            {name}
                            <span>
                                {`(`}
                                {brands.map((brand) => (
                                    <span>{brand.name}</span>
                                ))}
                                {` )`}
                            </span>
                        </Text>
                    ))}
                </Flex>
            );
        },
    },

    {
        name: 'Periode',
        width: 180,
        value: 'rangePeriod',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: '-',
        width: 100,
        component: ({ values }) => <ActionComponent selectedProduct={values} />,
    },
];
