import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { accountRevenueMapping, formattingPayloadHandler } from './utils';
import { popupHandler } from '@store/list/popup';

export const fetchLiveDetail = async ({ onChangeState, id, title }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    let form = {};

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        if (title === 'Edit') {
            form = await fetchRevenueLiveDetail({ onChangeState, id });
        }

        onChangeState({
            schedule: data?.data?.schedule,
            isLoading: false,
            form,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

export const fetchRevenueLiveDetail = async ({ onChangeState, id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/revenue/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return accountRevenueMapping({ revenues: data?.data?.revenues });
    } catch (error) {
        console.log(error, '<<<<');
        return {};
    }
};

export const onSubmitHandler = async ({ state, dispatch, navigate, isEdit }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengirim data.....',
        })
    );

    try {
        await Axios({
            method: isEdit ? 'PATCH' : 'POST',
            url: `/productivity-host`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: formattingPayloadHandler({ state, isEdit }),
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil mengirim data',
            })
        );

        navigate('/');
    } catch (error) {
        console.log(error);

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mengirim data',
            })
        );
    }
};

export const cancelLiveHandler = async ({ id, dispatch, navigate }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mencancel live.....',
        })
    );

    try {
        await Axios({
            method: 'POST',
            url: `/productivity-host/cancel-live`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                scheduleId: id,
            },
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil mencancel live',
            })
        );

        navigate('/');
    } catch (error) {
        console.log(error);

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mencancel live',
            })
        );
    }
};