import moment from 'moment';
import { chain, capitalize, padStart } from 'lodash';
import { convertToRaw } from 'draft-js';

export const payloadFormatting = ({ state }) => {
    const { title, description, startedAt, endedAt, accounts } = state;
    const accountLivestream = accounts?.map((item) => item.id);
    const dateTimeStarted = `${moment(startedAt).format('YYYY-MM-DD')}T00:00:00`;
    const dateTimeEnded = `${moment(endedAt).format('YYYY-MM-DD')}T23:59:59`;

    return {
        title,
        description: JSON.stringify(convertToRaw(description.getCurrentContent())),
        startedAt: `${moment(dateTimeStarted).utc().toISOString()}`,
        endedAt: `${moment(dateTimeEnded).utc().toISOString()}`,
        accountLivestream,
    };
};

export const formattingSelectedStateHandler = ({ arr, type }) => {
    const obj = {
        accounts: arr?.map(({ name, id, ecommerce }) => {
            return {
                label: name,
                value: name,
                id,
            };
        }),
        marketPlaces: arr?.map(({ ecommerce }) => {
            return {
                label: ecommerce?.name,
                value: ecommerce?.name,
            };
        })[0],
    };
    return obj[type];
};

export const formattedDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    return chain(dateObj)
        .thru((date) => [
            capitalize(date.toLocaleString('en-us', { month: 'short' })),
            padStart(String(date.getDate()), 2, '0'),
            date.getFullYear(),
            ',',
            padStart(String(date.getHours()), 2, '0'),
            ':',
            padStart(String(date.getMinutes()), 2, '0'),
        ])
        .join(' ')
        .value();
};
