import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        popUpType: '',
        popUpTitle: '',
        popUpDescription: '',
        submitLabel: 'Submit',
        hidePopUpSuccess: true,
        onSubmit: null,
        poUpData: [],
    },
    reducers: {
        popupHandler: (state, action) => {
            const { popUpType, popUpDescription, popUpTitle, onSubmit, submitLabel, hidePopUpSuccess, popUpData } =
                action.payload;
            state.popUpType = popUpType;
            state.popUpTitle = popUpTitle;
            state.popUpDescription = popUpDescription;
            state.submitLabel = submitLabel;
            state.onSubmit = onSubmit;
            state.hidePopUpSuccess = hidePopUpSuccess;
            state.popUpData = popUpData;
        },
        resetPopUpHandler: (state) => {
            state.popUpType = '';
            state.popUpTitle = '';
            state.popUpDescription = '';
            state.submitLabel = 'Submit';
            state.hidePopUpSuccess = true;
            state.poUpData = [];
        },
    },
});

export const { popupHandler, resetPopUpHandler } = popupSlice.actions;

export default popupSlice.reducer;
