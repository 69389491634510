import { Flex, Text } from '@chakra-ui/react';
import { ButtonAdd } from '@components/button';

import './styles.scss';

const SectionHead = ({ setState, state }) => {
    return (
        <Flex className="section-header__container__studio">
            {/** -------------------------- LEFT SIDE ----------------------------**/}
            <Flex className="left-side">
                <Text className="title">Studio</Text>
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            <Flex className="right-side">
                <ButtonAdd
                    textButton="Add studio"
                    width="140px"
                    onClick={() => setState({ ...state, isCreatePage: true })}
                />
            </Flex>
        </Flex>
    );
};

export default SectionHead;
