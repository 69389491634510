import { Box, Flex, Text } from "@chakra-ui/react";
import { UserIcon } from "./icons";
import Notification from '@components/notification';
import './styles.scss';
import Cookies from 'universal-cookie';

const Header = () => {
    const cookies = new Cookies();
    const username = cookies.get('username');
    return (
        <Box className="global-header__container">
            <Flex className="left-side"></Flex>
            <Flex className="right-side">
                <Notification />
                <Flex className="username-container">
                    <UserIcon />
                    <Text className="username-text__global-header">{username}</Text>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Header;
