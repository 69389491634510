import { Text, Flex, Box } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from '@assets/icons';
import { useContext } from 'react';
import { StudioContext } from '@pages/studio';

const ActionComponent = ({ selectedStudio }) => {
    const { onEditColumnHandler, onShowingRemovePopUp } = useContext(StudioContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedStudio)} className="box-icon">
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedStudio)} className="remove-icon">
                <RemoveIcon />
            </Box>
        </Flex>
    );
};

export const tableList = [
    {
        name: 'Nama',
        width: 160,
        isSticky: true,
        value: 'name',
        dataManipulationType: 'search',
        dataManipulationValue: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Ruangan',
        width: 160,
        isSticky: true,
        value: 'room',
        dataManipulationType: 'search',
        dataManipulationValue: 'room',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Alamat',
        width: 180,
        value: 'address',
        dataManipulationType: 'search',
        dataManipulationValue: 'address',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Area',
        width: 180,
        dataManipulationType: 'search',
        dataManipulationValue: 'area.name',
        value: 'area',
        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: 'Tipe',
        value: 'appType',
        width: 260,
        isSticky: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'appType.name',

        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: '-',
        width: 100,
        component: ({ values }) => <ActionComponent selectedStudio={values} />,
    },
];
