import { Flex, Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { isActivePageHandler, onClickSideMenu } from "../../functions";
import { ArrowRightIcon } from "@assets/icons";

import "./styles.scss";

const SideMenuChildren = ({ children, navigate, page, icon, path, name }) => {
  const [state, setState] = useState({ isOpenChildren: false });

  useEffect(() => {
    /* eslint-disable */
    setState({ isOpenChildren: isActivePageHandler({ page, path, children }) });
  }, [page]);
  return (
    <>
      <Box>
        <Flex
          onClick={() =>
            onClickSideMenu({ children, navigate, path, setState, state })
          }
          className={`section-body-item__side-menu ${
            isActivePageHandler({ page, path, children }) &&
            "section-body-item__side-menu__active"
          }`}
        >
          <Box>
            {icon({
              isActive: isActivePageHandler({ page, path, children }),
            })}
          </Box>
          <Text className="section-body-item__text">{name}</Text>
          {children && (
            <Box
              className={`arrow-icon ${
                state?.isOpenChildren && "arrow-icon__transform"
              }`}
            >
              <ArrowRightIcon
                color={
                  isActivePageHandler({ page, path, children })
                    ? "white"
                    : "black"
                }
              />
            </Box>
          )}
        </Flex>

        {/** ----------------------- SUB SIDEMENU OR CHILDREN ------------- */}

        {children && (
          <Box
            className="sidemenu-children__container"
            height={state?.isOpenChildren ? `${children?.length * 56}px` : 0} // 1 submenu  = 56px
          >
            {children?.map((child) => (
              <Flex
                onClick={() => navigate(child?.path)}
                className={`section-body-item__side-menu ${
                  page === child?.path &&
                  "section-body-item-__side-menu-children__active"
                }`}
              >
                <Box>{child?.icon({ isActive: false })}</Box>
                <Text className="section-body-item__text">{child?.name}</Text>
              </Flex>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SideMenuChildren;
