import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ScreenTemplate from '@components/screenTemplate';
import { SelectComponent, TextAreaComponent } from '@components/formFields';
import { LeftArrowIcon } from '@assets/icons';
import { formattingSelectedStateHandler } from '@pages/accountMasterdata/helpers/utils';

import { onSubmitAccount } from '../../helpers/crud';
import { initialState, accountTypes } from './const';
import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, assets, refetchAccountHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { brands, marketPlaces, name, accountType, parentAccount, isLoading } = state;

    const onClose = () => {
        mainSetState({ ...mainState, isCreatePage: false, isEditPage: false, selectedAccount: {} });
        setState(initialState);
    };
    const onChangeFormField = ({ fieldName, value }) => setState({ ...state, [fieldName]: value });

    useEffect(() => {
        /* eslint-disable */
        if (mainState?.selectedAccount?.id) {
            setState({
                ...state,
                name: mainState?.selectedAccount?.name,
                brands: formattingSelectedStateHandler({
                    arr: mainState?.selectedAccount?.brands,
                    type: 'brands',
                }),
                marketPlaces: formattingSelectedStateHandler({
                    arr: mainState?.selectedAccount?.ecommerce,
                    type: 'marketPlaces',
                }),
                accountType: formattingSelectedStateHandler({
                    arr: mainState?.selectedAccount?.accountType,
                    type: 'accountType',
                }),
                parentAccount: formattingSelectedStateHandler({
                    arr: mainState?.selectedAccount?.parentAccount,
                    type: 'parentAccount',
                }),
            });
        }
    }, [mainState?.selectedAccount]);

    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__product-highlight">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} Account</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <SelectComponent
                        label="Marketplace"
                        placeholder="Pilih marketplace"
                        errorMessage="Marketplace tidak boleh kosong!"
                        options={assets?.marketPlaces}
                        onChange={onChangeFormField}
                        fieldName="marketPlaces"
                        isMulti={false}
                        value={marketPlaces}
                    />
                    <TextAreaComponent
                        label="User ID Account"
                        placeholder="User ID Account"
                        errorMessage="Deskripsi tidak boleh kosong!"
                        value={name}
                        onChange={onChangeFormField}
                        fieldName="name"
                    />
                    <SelectComponent
                        label="Brand"
                        placeholder="Pilih Brand"
                        errorMessage="Brand tidak boleh kosong!"
                        value={brands}
                        options={assets?.brands} //filtering accounts based selected marketplace
                        onChange={onChangeFormField}
                        fieldName="brands"
                    />
                    <SelectComponent
                        label="Tipe Akun"
                        placeholder="Pilih Tipe Akun"
                        errorMessage="Tipe Akun tidak boleh kosong!"
                        options={accountTypes}
                        onChange={onChangeFormField}
                        fieldName="accountType"
                        isMulti={false}
                        value={accountType}
                    />
                    {state?.accountType?.label !== 'Official' && state?.marketPlaces?.label === 'Tiktokshop' && (
                        <SelectComponent
                            label="Parent Account"
                            placeholder="Pilih Parent Account"
                            errorMessage="Parent Account tidak boleh kosong!"
                            options={assets?.parentAccounts}
                            onChange={onChangeFormField}
                            fieldName="parentAccount"
                            isMulti={false}
                            value={parentAccount}
                        />
                    )}
                </Flex>
                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className="save-button"
                        onClick={() =>
                            onSubmitAccount({
                                state,
                                dispatch,
                                setState,
                                refetchAccountHandler,
                                onClose,
                                isEdit,
                                selectedAccount: mainState?.selectedAccount,
                            })
                        }
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
