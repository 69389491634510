import { useEffect, useState } from 'react';
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CloseIcon } from '@assets/icons';
import ModalTemplate from '@components/modal';
import {
    IllustrationFailed,
    IllustrationFailed2,
    IllustrationMailBox,
    IllustrationSuccess,
    IllustrationTrash,
    IllustrationWaiting1,
} from '@assets/illustrations';
import { InputComment } from '@components/formFields';
import { formattedDate } from '@pages/promotion/helpers/utils';
import { Spacer, HStack } from '@chakra-ui/react';
import { resetPopUpHandler, popupHandler } from '@store/list/popup';
import { fetchSingleComment } from '@pages/voucher/helpers/crud';
import './styles.scss';

const onClosePopup = ({ dispatch }) => dispatch(resetPopUpHandler());

export const PopUpTemplate = ({ popUpTitle, popUpDescription, type }) => {
    const dispatch = useDispatch();

    return (
        <ModalTemplate onClose={() => onClosePopup({ dispatch })}>
            <Flex className="popup-template__container">
                <Box className="icon-close" onClick={() => onClosePopup({ dispatch })}>
                    <CloseIcon color="black" />
                </Box>
                <Box className="icon-jiggle">
                    {type === 'waiting' && <IllustrationWaiting1 />}
                    {type === 'success' && <IllustrationSuccess />}
                    {type === 'failed' && <IllustrationFailed />}
                </Box>
                <Text className="title">{popUpTitle}</Text>
                <Text className="description">{popUpDescription || ''}</Text>
            </Flex>
        </ModalTemplate>
    );
};

export const PopUpLoading = () => {
    const dispatch = useDispatch();
    return (
        <ModalTemplate onClose={() => onClosePopup({ dispatch })}>
            <Flex className="popup-loading_container">
                <Box className="icon-close" onClick={() => onClosePopup({ dispatch })}>
                    <CloseIcon color="black" />
                </Box>
                <Box className="icon-loading">
                    <Box className="box1" />
                    <Box className="box2" />
                    <Box className="box3" />
                </Box>
            </Flex>
        </ModalTemplate>
    );
};

export const PopUpConfirmation = ({ popUpTitle, popUpDescription, onSubmit, type, hidePopUpSuccess, submitLabel }) => {
    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();

    const onApplyConfirmation = async () => {
        try {
            setIsLoading(true);
            const successData = await onSubmit();
            dispatch(
                popupHandler({
                    popUpType: hidePopUpSuccess ? '' : 'popup-success',
                    popUpTitle: successData ? successData.title : 'Sukses',
                    popUpDescription: successData ? successData.desc : '',
                })
            );
        } catch (error) {
            dispatch(
                popupHandler({
                    popUpType: 'popup-failed',
                    popUpTitle: error || 'Error',
                    popUpDescription: '',
                })
            );
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <ModalTemplate onClose={() => onClosePopup({ dispatch })}>
            <Flex className="popup-confirmation__container">
                <Box className="icon-jiggle">
                    {type === 'delete' && <IllustrationTrash />}
                    {type === 'submit' && <IllustrationMailBox />}
                </Box>
                <Text className="title">{popUpTitle}</Text>
                <Text className="description">{popUpDescription}</Text>
                <Flex className="button-container">
                    <Button className="button-cancel" onClick={() => dispatch(resetPopUpHandler())}>
                        Cancel
                    </Button>
                    <Button onClick={onApplyConfirmation} isLoading={isLoading}>
                        {submitLabel || 'Submit'}
                    </Button>
                </Flex>
            </Flex>
        </ModalTemplate>
    );
};

export const PopUpComment = ({ popUpTitle, popUpDescription, onSubmit, type, selectedPromotion }) => {
    const [inputComments, setInputComments] = useState('');
    const [comments, setComments] = useState([]);
    const dispatch = useDispatch();

    const { id } = popUpTitle;

    const handleFetchComments = async (id) => {
        try {
            const { comments } = await fetchSingleComment(id);

            setComments(comments);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    useEffect(() => {
        handleFetchComments(id);
    }, [id]);

    return (
        <ModalTemplate onClose={() => onClosePopup({ dispatch })}>
            <Flex className="popup-comment__container">
                <Box w="100%" background="white" h="max-content">
                    <InputComment
                        label="Post a Comment"
                        placeholder="Write your comment here"
                        errorMessage="Comment tidak boleh kosong!"
                        value={inputComments}
                        dispatch={dispatch}
                        valueId={id}
                        onChange={(e) => setInputComments(e.value)}
                        setComments={setComments}
                    />
                    <Text className="comment" mb={5}>
                        Comments
                    </Text>
                    <Box h="max-content">
                        {comments.map((item) => (
                            <Box mb="16px" borderBottom="1px solid #dbdbdb" pb="12px">
                                <HStack spacing={1} alignItems="baseline">
                                    <Text className="name-text">{item.user.name}</Text>
                                    <Spacer />
                                    <Text className="date-text">{formattedDate(item.createdAt)}</Text>
                                </HStack>
                                <Text className="comment-text">{item.comment}</Text>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Flex>
        </ModalTemplate>
    );
};

export const PopUpFailed = ({ popUpTitle, popUpDescription }) => {
    const dispatch = useDispatch();

    return (
        <ModalTemplate onClose={() => onClosePopup({ dispatch })}>
            <Flex className="popup-template__container">
                <Box className="icon-close" onClick={() => onClosePopup({ dispatch })}>
                    <CloseIcon color="black" />
                </Box>
                <Box className="icon-jiggle">
                    <IllustrationFailed2 />
                </Box>
                <Text className="title" mt="32px">
                    {popUpTitle}
                </Text>
                <Text className="description">{popUpDescription || ''}</Text>
            </Flex>
        </ModalTemplate>
    );
};