import { Flex, Text } from '@chakra-ui/react';
import { CatIllustration } from '@assets/illustrations';

import './styles.scss';

const EmptyState = ({ text, description }) => (
    <Flex className="empty-state-global-mobile__container">
        <CatIllustration />
        <Text className="empty-state__text">{text}</Text>
        <Text className="empty-state__desc">{description}</Text>
    </Flex>
);

export default EmptyState;
