//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------

import TableSkeleton from '@components/tableSkeleton';

//---------------- LOCAL COMPONENT ------------------------------------------

import SectionHead from './sections/sectionHead';
import CreateAndEditModal from './sections/sectionCreateAndEdit';
import SectionBody from './sections/sectionBody';

//---------------- UTILS ------------------------------------------

import { fetchProductHighlightHandler, fetchAssetHandler } from './helpers/crud';
import { onShowingRemovePopUp } from './helpers/events';
import { initialState, initialAssets } from './const';
import './styles.scss';

export const ProductHighlightContext = createContext();

const ProductHighlight = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();

    const [state, setState] = useState(initialState);
    const [assets, setAsset] = useState(initialAssets);
    const { isLoading, page, isCreatePage, isEditPage } = state;

    //----------------------------------- EVENT FUNCTION -----------------------------------

    const onPagePaginationClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const refetchProductHandler = () => fetchProductHighlightHandler({ state, setState, search });

    //----------------------------------- USEFFECT FUNCTION -----------------------------------

    useEffect(() => {
        /* eslint-disable */
        fetchProductHighlightHandler({ state, setState, search });
    }, [page, search]);

    useEffect(() => {
        fetchAssetHandler({ assets, setAsset });
    }, []);

    return (
        <ProductHighlightContext.Provider
            value={{
                onEditColumnHandler: (selectedProduct) =>
                    setState({ ...state, selectedProduct, isEditPage: !state?.isEditPage }),
                onShowingRemovePopUp: (selectedProduct) =>
                    onShowingRemovePopUp({ dispatch, refetchProductHandler, selectedProduct }),
                state,
                assets,
            }}
        >
            <Box className="product-highlight__container">
                <SectionHead setState={setState} state={state} />

                {!isLoading && <SectionBody state={state} handlePageClick={onPagePaginationClick} />}
                {isLoading && <TableSkeleton />}

                {isCreatePage && (
                    <CreateAndEditModal
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchProductHandler={refetchProductHandler}
                    />
                )}
                {isEditPage && (
                    <CreateAndEditModal
                        isEdit
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchProductHandler={refetchProductHandler}
                    />
                )}
            </Box>
        </ProductHighlightContext.Provider>
    );
};

export default ProductHighlight;
