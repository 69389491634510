import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ScreenTemplate from '@components/screenTemplate';
import { InputComponent, SelectComponent, ToggleFieldComponent } from '@components/formFields';
import { initialState, isLdapTypes } from './const';

import { LeftArrowIcon } from '@assets/icons';
import { onSubmitUser } from '@pages/userManagement/helpers/crud';
import { formattingSelectedStateHandler } from '@pages/userManagement/helpers/utils';
import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, assets, refetchUserHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { brand, email, name, password, handphone, roleId, username, isLdap, baId, isLoading } = state;
    const onClose = () => {
        mainSetState({ ...mainState, isCreatePage: false, isEditPage: false, selectedUser: {} });
        setState(initialState);
    };
    const onChangeFormField = ({ fieldName, value }) => {
        setState({ ...state, [fieldName]: value });
    };

    useEffect(() => {
        /* eslint-disable */
        if (mainState?.selectedUser?.id) {
            setState({
                ...state,
                address: mainState?.selectedUser?.address,
                baId: mainState?.selectedUser?.baId,
                name: mainState?.selectedUser?.name,
                username: mainState?.selectedUser?.username,
                email: mainState?.selectedUser?.email,
                password: mainState?.selectedUser?.password,
                handphone: mainState?.selectedUser?.handphone,
                domicile: mainState?.selectedUser?.domicile,
                placeOfBirth: mainState?.selectedUser?.placeOfBirth,
                dateOfBirth: mainState?.selectedUser?.dateOfBirth,
                isLdap: mainState?.selectedUser?.isLdap,
                gender: formattingSelectedStateHandler({
                    arr: mainState?.selectedUser?.gender,
                    type: 'gender',
                }),
                roleId: formattingSelectedStateHandler({
                    arr: mainState?.selectedUser?.role,
                    type: 'role',
                }),
                brand: formattingSelectedStateHandler({
                    arr: mainState?.selectedUser?.brands,
                    type: 'brands',
                }),
            });
        }
    }, [mainState?.selectedUser]);

    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__usermanagement">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} User</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <InputComponent
                        label="BA ID"
                        placeholder="Masukan BA Id"
                        errorMessage="BA Id boleh kosong!"
                        type="text"
                        value={baId}
                        onChange={onChangeFormField}
                        fieldName="baId"
                    />
                    <InputComponent
                        label="Nama"
                        placeholder="Masukan Nama"
                        errorMessage="Nama tidak boleh kosong!"
                        type="text"
                        value={name}
                        onChange={onChangeFormField}
                        fieldName="name"
                    />
                    <InputComponent
                        label="Username"
                        placeholder="Masukan Username"
                        errorMessage="Username tidak boleh kosong!"
                        type="text"
                        value={username}
                        onChange={onChangeFormField}
                        fieldName="username"
                    />
                    <ToggleFieldComponent
                        label="Punya email PTI?"
                        placeholder="Masukan Ldap"
                        value={isLdap}
                        onChange={onChangeFormField}
                        options={isLdapTypes}
                        fieldName="isLdap"
                    />
                    <InputComponent
                        label="Email"
                        placeholder="Masukan Email"
                        errorMessage="Email tidak boleh kosong!"
                        type="text"
                        value={email}
                        onChange={onChangeFormField}
                        fieldName="email"
                    />
                    {!state?.isLdap && (
                        <InputComponent
                            label="Password"
                            placeholder="Masukan Password"
                            errorMessage="Password tidak boleh kosong!"
                            type="password"
                            value={password}
                            onChange={onChangeFormField}
                            fieldName="password"
                        />
                    )}
                    <InputComponent
                        label="No WA"
                        placeholder="Masukan WA"
                        errorMessage="No WA tidak boleh kosong!"
                        type="number"
                        value={handphone}
                        onChange={onChangeFormField}
                        fieldName="handphone"
                    />
                    <SelectComponent
                        label="Role"
                        placeholder="Pilih role"
                        errorMessage="role tidak boleh kosong!"
                        options={assets?.roles}
                        onChange={onChangeFormField}
                        fieldName="roleId"
                        isMulti={false}
                        value={roleId}
                    />
                    <SelectComponent
                        label="Brand"
                        placeholder="Pilih brand"
                        errorMessage="brand tidak boleh kosong!"
                        options={assets?.brands}
                        onChange={onChangeFormField}
                        fieldName="brand"
                        isMulti={true}
                        value={brand}
                    />
                </Flex>
                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className="save-button"
                        onClick={() =>
                            onSubmitUser({
                                state,
                                dispatch,
                                setState,
                                refetchUserHandler,
                                onClose,
                                isEdit,
                                selectedUser: mainState?.selectedUser,
                            })
                        }
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
