import { Text, Flex, Box, Checkbox } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';

import { EditIcon, RemoveIcon, ResetIcon } from '@assets/icons';
import { KoinContext } from '@pages/coin';
import { BadgeStatus } from '@components/badgeStatus';
import { onShowingRejectApprovalPopUp } from '@pages/coin/helpers/events';

// --------------------------------- TABLE LIST ---------------------------
export const tableList = (multiSelect) => {
    const commonTableList = [
        {
            name: 'Nama',
            width: 180,
            dataManipulationType: 'search',
            dataManipulationValue: 'title',
            value: 'title',
            isDivider: true,
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'Marketplace',
            width: 160,
            isDivider: true,
            isSticky: true,
            value: 'accountLivestreams',
            dataManipulationType: 'search',
            dataManipulationValue: 'accountLivestreams.ecommerce.name',
            component: ({ value }) => <Text className="column-text">{value[0]?.ecommerce?.name}</Text>,
        },
        {
            name: 'Account',
            width: 300,
            value: 'accountLivestreams',
            isDivider: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'accountLivestreams.name',
            isSticky: true,
            component: ({ value }) => {
                return (
                    <Flex className="account-container__coin">
                        {value?.map(({ name, brands }) => (
                            <Text className="column-text">
                                {name}
                                <span>
                                    {`(`}
                                    {brands.map((brand) => (
                                        <span>{brand.name}</span>
                                    ))}
                                    {` )`}
                                </span>
                            </Text>
                        ))}
                    </Flex>
                );
            },
        },
        {
            name: 'Date Start',
            width: 180,
            value: 'startedAt',
            component: ({ value }) => {
                return <Text className="column-text">{moment(value).format('YYYY-MM-DD')}</Text>;
            },
        },
        {
            name: 'Date End',
            width: 180,
            value: 'endedAt',
            isDivider: true,
            component: ({ value }) => {
                return <Text className="column-text">{moment(value).format('YYYY-MM-DD')}</Text>;
            },
        },
        {
            name: 'Status Promosi',
            width: 200,
            isDivider: true,
            value: 'status',
            component: ({ value }) => {
                const statusMap = {
                    Mendatang: { color: '#0076CC', backgroundColor: '#BDE2FF' },
                    Berlangsung: { color: '#13AE5C', backgroundColor: '#AFF4C6' },
                    Selesai: { color: '#D47C00', backgroundColor: '#FFE8A3' },
                };

                const statusInfo = statusMap[value] || { color: '', backgroundColor: '' };

                return (
                    <BadgeStatus title={value} color={statusInfo.color} backgroundColor={statusInfo.backgroundColor} />
                );
            },
        },
        {
            name: 'Konfirmasi Parama',
            width: 200,
            isDivider: true,
            value: 'isActivated',
            component: ({ value }) => {
                const konfirmasiMap = {
                    true: { title: 'Completed', color: '#13AE5C', backgroundColor: '#AFF4C6' },
                    false: { title: 'To do', color: '#F24822', backgroundColor: '#FFC7C2' },
                };

                const konfirmasiInfo = konfirmasiMap[value];

                return <BadgeStatus {...konfirmasiInfo} />;
            },
        },
        {
            name: '',
            width: 30,
            component: ({ values }) => {
                return multiSelect.isParamaConfirmation ? (
                    <CheckboxComponent selectedKoin={values} multiSelectKoin={multiSelect?.coins} />
                ) : (
                    <ActionComponent selectedKoin={values} />
                );
            },
        },
    ];

    return commonTableList;
};

//------------------------------------ ADD ON -----------------------------------------
const ActionComponent = ({ selectedKoin }) => {
    const { onEditColumnHandler, onShowingRemovePopUp } = useContext(KoinContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedKoin)} className="box-icon">
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedKoin)} className="remove-icon">
                <RemoveIcon />
            </Box>
        </Flex>
    );
};

const CheckboxComponent = ({ selectedKoin, multiSelectKoin }) => {
    const { onCheckboxHandler, refetchKoinHandler } = useContext(KoinContext);
    const dispatch = useDispatch();
    const isActivated = selectedKoin?.isActivated; // is approved by parama

    const isChecked = includes(multiSelectKoin, selectedKoin?.id);

    return (
        <div>
            {isActivated ? (
                <Box onClick={() => onShowingRejectApprovalPopUp({ dispatch, selectedKoin, refetchKoinHandler })}>
                    <ResetIcon color="var(--main-color)" />
                </Box>
            ) : (
                <Checkbox
                    size="lg"
                    verticalAlign="middle"
                    onChange={() => onCheckboxHandler(selectedKoin)}
                    checked={isChecked}
                    colorScheme="purple"
                    className="checkbox-field"
                />
            )}
        </div>
    );
};
