import { removeAccountHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingRemovePopUp = async ({ selectedStudio, dispatch, refetchStudioHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus studio ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeAccountHandler({
                    selectedStudio,
                    refetchStudioHandler,
                }),
        })
    );
};
