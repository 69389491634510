import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { serviceHandler, responseHandler } from './utils';
import moment from 'moment';

//------------------------ FETCH TABLE DATA ---------------------------------

export const fetchReportingAchievement = async ({ state, setState, filter, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );

    const dateFormatted = {
        date: moment(filter?.dateFilter?.date).format('YYYY-MM-DD'),
        startDate: moment(filter?.dateFilter?.startDate).format('YYYY-MM-DD'),
        endDate: moment(filter?.dateFilter?.endDate).format('YYYY-MM-DD'),
    };
    const services = serviceHandler({
        reportingType: state?.reportingType,
        filterStringify,
        filter: dateFormatted,
        moment,
    });

    try {
        const { data } = await Axios({
            method: 'POST',
            url: services?.url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: { ...services?.data },
        });

        const response = responseHandler({ data: data?.data, reportingType: state?.reportingType });
        const { totalData, totalPage } = data?.data;

        setState({
            ...state,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: totalData,
            reporting: response,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, reporting: [], isLoading: false });
    }
};

//------------------------ FETCH ASSETS ---------------------------------

const fetchAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const accounts = await Axios({
            method: 'GET',
            url: `/achievement/account-livestream/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return accounts?.data?.data?.accountLivestreams?.map((acc) => acc.name);
    } catch (error) {
        return [];
    }
};

const fetchTypeAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const typeAccounts = await Axios({
            method: 'GET',
            url: `/account-type/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return typeAccounts?.data?.data?.accountTypes;
    } catch (error) {
        return [];
    }
};

const fetchMarketplaceHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const marketplaces = await Axios({
            method: 'GET',
            url: `/ecommerce/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return marketplaces?.data?.data?.ecommerces;
    } catch (error) {
        return [];
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const brands = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return brands?.data?.data?.brands;
    } catch (error) {
        return [];
    }
};

export const fetchAssetsHandler = async ({ onChangeAssets }) => {
    try {
        const promises = [
            fetchBrandsHandler(),
            fetchAccountHandler(),
            fetchTypeAccountHandler(),
            fetchMarketplaceHandler(),
        ];

        const [brands, accounts, typeAccounts, marketplaces] = await Promise.all(promises);

        onChangeAssets({
            brands,
            accounts,
            marketplaces,
            typeAccounts,
        });
    } catch (error) {
        console.error(error);
    }
};
