export const initialState = {
    isLoading: false,
    studios: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    selectedStudio: {},
};

export const initialAssets = {
    areas: [],
    appTypes: [],
    parentAccounts: [],
};
