import moment from 'moment';

export const initialState = {
    isLoading: true,
    data: [],
    isOpenMultipleDate: false,
    searchQuery: '',
};

export const initialStateOthers = {
    'finishing-live': {
        isFinish: false,
        endedAt: new Date(),
        startedAt: moment().subtract(7, 'days').utc().format('YYYY-MM-DD'),
        maxDate: new Date(),
    },
    'comingsoon-live': {
        isFinish: false,
        startedAt: new Date(),
        endedAt: moment().add(7, 'days').utc().format('YYYY-MM-DD'),
        minDate: new Date(),
    },
    'done-live': {
        isFinish: true,
        endedAt: new Date(),
        startedAt: moment().subtract(7, 'days').utc().format('YYYY-MM-DD'),
    },
};
