import { Text, Flex, Box } from '@chakra-ui/react';
import { EditIcon } from '@assets/icons';
import { useContext } from 'react';
import { AccountContext } from '@pages/accountMasterdata';

const ActionComponent = ({ selectedAccount }) => {
    const { onEditColumnHandler } = useContext(AccountContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedAccount)} className="box-icon">
                <EditIcon />
            </Box>
            {/* <Box onClick={() => onShowingRemovePopUp(selectedAccount)} className="remove-icon">
                <RemoveIcon />
            </Box> */}
        </Flex>
    );
};

export const tableList = [
    {
        name: 'USER ID ACCOUNT',
        width: 160,
        isSticky: true,
        value: 'name',
        dataManipulationType: 'search',
        dataManipulationValue: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'BRAND',
        width: 300,
        value: 'brands',
        isDivider: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'brands.name',

        isSticky: true,
        component: ({ value }) => {
            return (
                <Flex className="account-container__product-highlight">
                    {value?.map(({ name }) => (
                        <Text className="column-text">
                            <span>{`${name}`}</span>
                        </Text>
                    ))}
                </Flex>
            );
        },
    },

    {
        name: 'MARKETPLACE',
        width: 180,
        value: 'ecommerce',
        dataManipulationType: 'search',
        dataManipulationValue: 'ecommerce.name',
        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: 'ACCOUNT TYPE',
        width: 180,
        dataManipulationType: 'search',
        dataManipulationValue: 'accountType.name',
        value: 'accountType',
        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: 'PARENT ACCOUNT',
        value: 'parentAccount',
        width: 260,
        isSticky: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'parentAccount.name',

        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: '-',
        width: 100,
        component: ({ values }) => <ActionComponent selectedAccount={values} />,
    },
];
