export const filterList = [
    {
        name: 'Brand',
        value: 'brand',
    },
    {
        name: 'Account',
        value: 'account',
    },

    {
        name: 'Ecommerce',
        value: 'ecommerce',
    },
    {
        name: 'Host',
        value: 'host',
    },
    {
        name: 'Studio',
        value: 'studio',
    },
    {
        name: 'Operator',
        value: 'operator',
    },
];


export const calendarTypeOption = [
    {
        value: 'Daily',
        name: 'Daily',
        days: 1,
    },
    {
        value: '3 Days',
        name: '3 Days',
        days: 3,
    },
    {
        value: 'Weekly',
        name: 'Weekly',
        days: 7,
    },
];

export const calendarTypeMobileOption = [
    {
        value: 'Daily',
        name: 'Daily',
        days: 1,
    },
    {
        value: '3 Days',
        name: '3 Days',
        days: 3,
    },
];