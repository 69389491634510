import TableTemplate from '@components/tableTemplate';
import { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { tableList } from './const';
import './styles.scss';

const InvalidDataTable = ({ data }) => {
    const limit = 20;
    const [state, setState] = useState({
        page: 1,
        invalidDatas: data,
        totalPage: 0,
    });

    const onChangeState = (newState) => setState({ ...state, ...newState });

    const onPagePaginationClick = ({ selected }) => {
        const arr = [...data];
        const dataSliced = arr?.slice(selected * limit, limit * (selected + 1));

        onChangeState({
            invalidDatas: dataSliced,
            page: selected + 1,
        });
    };

    const { page, invalidDatas, totalPage } = state;

    useEffect(() => {
        /* eslint-disable */

        if (data?.length) {
            const arr = [...data];
            const dataSliced = arr?.slice(0, limit);
            const totalPage = data?.length / 20;

            onChangeState({ invalidDatas: dataSliced, totalPage });
        }
    }, [data]);

    return (
        <Box className="invalid-data__table">
            <TableTemplate
                tableList={tableList}
                data={invalidDatas}
                dataCount={invalidDatas?.length}
                totalData={data?.length}
                page={page}
                totalPage={totalPage}
                handlePageClick={onPagePaginationClick}
            />
        </Box>
    );
};

export default InvalidDataTable;
