//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';

//---------------- GLOBAL COMPONENT ------------------------------------------

import { routeList } from '@routers';
import DashboardContainer from '@components/dashboardContainer';
import { PopUpConfirmation, PopUpTemplate, PopUpComment, PopUpFailed } from '@components/popUp';

//---------------- STYLES ------------------------------------------
import '@styles/global.scss';
import '@styles/baseColor.scss';
import PopUpLoadingDownload from '@components/popUpLoadingDownload';

function App() {
    const { popUpType, popUpTitle, popUpDescription, onSubmit, submitLabel, hidePopUpSuccess, poUpData } = useSelector(
        ({ popupSlice }) => popupSlice
    );

    const routeListHandler = () => {
        const arr = [];
        routeList?.forEach((route) => {
            route.children ? route.children.forEach((item) => arr.push(item)) : arr.push(route);
        });

        return arr;
    };

    return (
        <div className="App" id="main-app">
            <ChakraProvider>
                <Router>
                    <DashboardContainer>
                        {/** --------------------------- ROUTES -------------------- */}

                        <Routes>
                            {routeListHandler().map((route) => {
                                return (
                                    <Route
                                        exact
                                        path={route.path}
                                        element={route.element}
                                        key={route.path}
                                        errorElement={route.errorElement}
                                    />
                                );
                            })}
                        </Routes>

                        {/** --------------------------- POPUP -------------------- */}

                        {popUpType === 'popup-waiting' && (
                            <PopUpTemplate popUpTitle={popUpTitle} popUpDescription={popUpDescription} type="waiting" />
                        )}
                        {popUpType === 'popup-success' && (
                            <PopUpTemplate popUpTitle={popUpTitle} popUpDescription={popUpDescription} type="success" />
                        )}
                        {popUpType === 'popup-failed' && (
                            <PopUpTemplate popUpTitle={popUpTitle} popUpDescription={popUpDescription} type="failed" />
                        )}

                        {popUpType === 'popup-error' && (
                            <PopUpFailed popUpTitle={popUpTitle} popUpDescription={popUpDescription} type="failed" />
                        )}

                        {popUpType === 'popup-confirmation-submit' && (
                            <PopUpConfirmation
                                popUpTitle={popUpTitle}
                                onSubmit={onSubmit}
                                type="submit"
                                submitLabel={submitLabel}
                                hidePopUpSuccess={hidePopUpSuccess}
                            />
                        )}
                        {popUpType === 'popup-confirmation-delete' && (
                            <PopUpConfirmation popUpTitle={popUpTitle} onSubmit={onSubmit} type="delete" />
                        )}

                        {popUpType === 'popup-comment' && (
                            <PopUpComment popUpTitle={popUpTitle} onSubmit={onSubmit} type="waiting" />
                        )}

                        {popUpType === 'popup-download' && <PopUpLoadingDownload description={popUpTitle} />}
                    </DashboardContainer>
                </Router>
            </ChakraProvider>
        </div>
    );
}

export default App;
