import { Text, Flex, Box, Tooltip } from '@chakra-ui/react';
import { getBrandColor, sortByBrandHandler } from './functions';
import { EditIcon, RemoveIcon } from '@assets/icons';

import { useContext } from 'react';
import { UserManagementContext } from '@pages/userManagement';

const ActionComponent = ({ selectedUser }) => {
    const { onEditColumnHandler, onShowingRemovePopUp } = useContext(UserManagementContext);
    return (
        <Flex className="action-container">
            <Box
                onClick={() => {
                    onEditColumnHandler(selectedUser);
                }}
                className="box-icon"
            >
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedUser)} className="remove-icon">
                <RemoveIcon />
            </Box>
        </Flex>
    );
};

export const tableList = [
    {
        name: 'Nama',
        value: 'name',
        width: 200,
        isSticky: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'name',

        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Surel',
        width: 240,
        isSticky: true,
        value: 'email',
        dataManipulationType: 'search',
        dataManipulationValue: 'email',

        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Merek',
        width: 300,
        value: 'brands',
        isDivider: true,
        isSticky: true,
        dataManipulationType: 'search',
        dataManipulationValue: 'brands.name',

        component: ({ value }) => {
            return (
                <Flex className="brand-container__usermanagement">
                    {sortByBrandHandler(value)?.map(({ name }) => (
                        <Tooltip label={getBrandColor({ name })?.name}>
                            <img src={getBrandColor({ name })?.link} alt={name} />
                        </Tooltip>
                    ))}

                    {!value?.length && '-'}
                </Flex>
            );
        },
    },
    {
        name: 'Peran',
        width: 180,
        value: 'role',
        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: 'WA',
        width: 180,
        value: 'handphone',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Jenis Kelamin',
        width: 160,
        value: 'gender',
        dataManipulationType: 'search',
        dataManipulationValue: 'gender',

        component: ({ value }) => <Text className="column-text ">{value}</Text>,
    },
    {
        name: 'Alamat',
        width: 320,
        value: 'address',
        component: ({ value }) => (
            <Tooltip label={value}>
                <Text className="column-text ">{value}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Domisili',
        width: 320,
        value: 'domicile',

        component: ({ value }) => (
            <Tooltip label={value}>
                <Text className="column-text ">{value}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Tempat Lahir',
        width: 160,
        value: 'placeOfBirth',

        component: ({ value }) => <Text className="column-text ">{value}</Text>,
    },
    {
        name: 'Tanggal Lahir',
        width: 160,
        value: 'dateOfBirth',

        component: ({ value }) => <Text className="column-text ">{value}</Text>,
    },
    {
        name: '-',
        width: 100,
        component: ({ values }) => <ActionComponent selectedUser={values} />,
    },
];
