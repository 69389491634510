import { Input, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { UploadPopUpContext } from '@pages/targetSchedule/components/uploadPopUp';
import { useState, useContext } from 'react';

const EditableColumn = ({ value, index, fieldType, errorMessage, isDisabled }) => {
    const { onChangePopUpState, popUpState } = useContext(UploadPopUpContext);

    const onChangeInvalidData = (e) => {
        const value = e.target.value;

        const invalidData = popUpState?.invalidData;

        invalidData[index] = {
            ...invalidData[index],
            [fieldType]: {
                errorMessage,
                value,
            },
        };

        onChangePopUpState({
            invalidData,
        });
    };

    return (
        <Tooltip label={value?.errorMessage}>
            <Input
                isDisabled={isDisabled}
                value={value?.value}
                className="input-edit"
                onChange={onChangeInvalidData}
                borderColor={value?.errorMessage ? 'red !important' : 'grey !important'}
            />
        </Tooltip>
    );
};

export const tableList = [
    {
        name: 'BA ID',
        value: 'baId',
        width: 200,
        component: ({ value }) => <Text className="column-text">{value?.value || '-'}</Text>,
    },
    {
        name: 'User ID',
        value: 'userId',
        width: 200,
        component: ({ value, index }) => (
            <EditableColumn value={value} index={index} fieldType="userId" errorMessage={value?.errorMessage} />
        ),
    },
    {
        name: 'Host',
        width: 320,
        value: 'name',
        component: ({ value, index }) => (
            <EditableColumn
                value={value}
                index={index}
                fieldType="name"
                errorMessage={value?.errorMessage}
                isDisabled
            />
        ),
    },

    {
        name: 'Brand',
        width: 180,
        value: 'brandName',
        component: ({ value, index }) => (
            <EditableColumn value={value} index={index} fieldType="brandName" errorMessage={value?.errorMessage} />
        ),
    },
    {
        name: 'Target Sales',
        width: 180,
        value: 'targetAchievement',
        component: ({ value, index }) => (
            <EditableColumn
                value={value}
                index={index}
                fieldType="targetAchievement"
                errorMessage={value?.errorMessage}
            />
        ),
    },
    {
        name: 'Target Jam',
        width: 160,
        value: 'targetDuration',
        component: ({ value, index }) => (
            <EditableColumn value={value} index={index} fieldType="targetDuration" errorMessage={value?.errorMessage} />
        ),
    },
    {
        name: 'Year Month',
        width: 320,
        value: 'yearMonth',
        component: ({ value, index }) => {
            const data = {
                value: moment(value?.value).format('YYYY-MM'),
                errorMessage: value?.errorMessage,
            };
            return (
                <EditableColumn
                    value={data}
                    index={index}
                    fieldType="yearMonth"
                    errorMessage={data?.errorMessage}
                    isDisabled
                />
            );
        },
    },
];
