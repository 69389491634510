import { Flex, Text } from '@chakra-ui/react';
import { EmptyStateIcon } from './icons';
import './styles.scss';

const EmptyState = ({ text, description }) => (
    <Flex className="empty-state-global__container">
        <EmptyStateIcon />
        <Text className="empty-state__text">{text}</Text>
        <Text className="empty-state__desc">{description}</Text>
    </Flex>
);

export default EmptyState;
