import { removeNonNumeric } from '@helpers/util';
import { forEach } from 'lodash';

export const accountRevenueMapping = ({ revenues }) => {
    const form = {};

    revenues?.forEach(
        (
            {
                accountLivestreams,
                buyer,
                ctrPercentage,
                fulltimeDuration,
                fulltimeAvgViewDuration,
                viewer,
                revenue,
                file,
                id,
            },
            idx
        ) => {
            form[accountLivestreams?.id] = {
                buyer,
                ctrPercentage,
                duration: fulltimeDuration,
                avgViewDuration: fulltimeAvgViewDuration,
                viewer,
                revenue,
                fileId: file?.id,
                revenueId: id,
            };
        }
    );

    return form;
};

export const formattingPayloadHandler = ({ state, isEdit }) => {
    const { form, schedule } = state;

    const lines = [];

    forEach(form, (value, accountLivestreamId) => {
        lines.push({
            accountLivestreamId: accountLivestreamId,
            fileId: value?.fileId,
            revenue: removeNonNumeric(value?.revenue),
            viewer: removeNonNumeric(value?.viewer),
            ctrPercentage: value?.ctrPercentage,
            duration: value?.duration || '00:00:00',
            buyer: value?.buyer,
            avgViewDuration: value?.avgViewDuration,
            id: value?.revenueId,
        });
    });

    let result = {
        lines,
    };

    if (!isEdit) {
        result.scheduleId = schedule?.id;
    }

    return result;
};
