import { cancelLiveHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingCancelLivePopUp = async ({ state, dispatch, navigate }) => {
    const { id } = state?.schedule;
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin submit tidak live?',
            popUpDescription: '',
            onSubmit: () => {
                cancelLiveHandler({
                    id,
                    dispatch,
                    navigate,
                });
            },
        })
    );
};
