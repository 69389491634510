//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { useEffect, useState, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@chakra-ui/react';

//---------------- GLOBAL COMPONENT ------------------------------------------
import TableSkeleton from '@components/tableSkeleton';

//---------------- LOCAL COMPONENT ------------------------------------------
import SectionHead from './sections/sectionHead';
import CreateAndEditModal from './sections/sectionCreateAndEdit';
import SectionBody from './sections/sectionBody';

//---------------- UTILS ------------------------------------------
import { fetchUserManagementHandler, fetchAssetHandler } from './helpers/crud';
import { onShowingRemovePopUp } from './helpers/events';
import { initialState, initialAssets } from './const';
import './styles.scss';

export const UserManagementContext = createContext();

const UserManagement = () => {
    const [state, setState] = useState(initialState);
    const [assets, setAsset] = useState(initialAssets);

    const { isLoading, page, isCreatePage, isEditPage } = state;
    const dispatch = useDispatch();
    const { search } = useLocation();

    const handlePageClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const refetchUserHandler = () => fetchUserManagementHandler({ state, setState, search });

    useEffect(() => {
        /* eslint-disable */
        fetchUserManagementHandler({ state, setState, search });
    }, [page, search]);

    useEffect(() => {
        fetchAssetHandler({ assets, setAsset });
    }, []);

    return (
        <UserManagementContext.Provider
            value={{
                onEditColumnHandler: (selectedUser) => {
                    setState({ ...state, selectedUser, isEditPage: !isEditPage });
                },
                onShowingRemovePopUp: (selectedUser) =>
                    onShowingRemovePopUp({ dispatch, refetchUserHandler, selectedUser }),
                state,
                assets,
            }}
        >
            <Box className="usermanagement-container">
                <SectionHead setState={setState} state={state} />
                {!isLoading && <SectionBody state={state} handlePageClick={handlePageClick} />}
                {isLoading && <TableSkeleton />}
                {isCreatePage && (
                    <CreateAndEditModal
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchUserHandler={refetchUserHandler}
                    />
                )}
                {isEditPage && (
                    <CreateAndEditModal
                        isEdit
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchUserHandler={refetchUserHandler}
                    />
                )}
            </Box>
        </UserManagementContext.Provider>
    );
};

export default UserManagement;
