import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const fetchStudioHandler = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const fetchedStudios = await Axios({
            method: 'GET',
            url: `/studio/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { studios, totalData, totalPage } = fetchedStudios?.data?.data;
        // Transform the studios array
        const transformedStudios = studios.map((studio) => ({
            id: studio?.id,
            room: studio?.room,
            name: studio?.name,
            address: studio?.address,
            appType: studio?.appType,
            area: studio?.area,
        }));

        setState({
            ...state,
            studios: transformedStudios,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: transformedStudios.length,
            isCreatePage: false,
            isEditPage: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, studios: [], isLoading: false });
    }
};

const fetchAppTypesHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/app-type/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { appTypes } = data?.data;

        appTypes = appTypes?.map((item) => {
            return { label: item.name, value: item?.id, id: item?.id };
        });

        return appTypes || [];
    } catch (error) {
        return [];
    }
};

const fetchAreasHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/area/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { areas } = data?.data;

        areas = areas?.map((item) => {
            return { label: item.name, value: item?.id, id: item?.id };
        });

        return areas || [];
    } catch (error) {
        return [];
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const areas = await fetchAreasHandler();
        const appTypes = await fetchAppTypesHandler();

        setAsset({ areas, appTypes });
    } catch (error) {
        // console.log(error);
    }
};

export const removeAccountHandler = async ({ selectedStudio, refetchStudioHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'DELETE',
            url: `/studio/${selectedStudio?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchStudioHandler();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const onSubmitStudio = async ({ state, dispatch, setState, refetchStudioHandler, isEdit, selectedStudio }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    console.log(state);
    const payloads = payloadFormatting({ state });

    setState({ ...state, isLoading: true });

    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/studio/${selectedStudio?.id}` : `/studio`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchStudioHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil menambahkan studio',
                popUpDescription: '',
            })
        );
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: message || `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} studio`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};
