import MainDashboard from '@pages/mainDashboard';
import {
    AchievementIcon,
    AssetManagementIcon,
    ChartIcon1,
    CoinIcon,
    // CouponIcon,
    IconBubble,
    MainDashboardIcon,
    // MarketPlaceIcon,
    PaperIcon,
    PeopleIcon3,
    PromotionIcon,
    ScheduleIcon,
    ScheduleIcon2,
    // SettingIcon,
    UploadIcon1,
    VoucherIcon,
    // WalletIcon,
} from '@assets/icons';
import Login from '@pages/login';
import UserManagement from '@pages/userManagement';
import NotAvalaible from '@pages/notAvalaible';
import Schedule from '@pages/schedule';
import ProductHighlight from '@pages/productHighlight';
import Koin from '@pages/coin';
import Account from '@pages/accountMasterdata';
import Voucher from '@pages/voucher';
import Promotion from '@pages/promotion';
import ResetPassword from '@pages/resetPassword';
import Role from '@pages/roleManagement';
import ScheduleDetail from '@pages/scheduleDetail/';
import Studio from '@pages/studio';
import TargetSchedule from '@pages/targetSchedule';
import ScheduleSubmission from '@pages/scheduleSubmission';
import ReportingAchievement from '@pages/reportingAchievement';

export const routeList = [
    {
        path: '/',
        element: <MainDashboard />,
        isSideMenu: true,
        icon: ({ isActive }) => <MainDashboardIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
        name: 'Main Dashboard',
    },
    {
        path: '/master-data',
        isSideMenu: true,
        icon: ({ isActive }) => <AssetManagementIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
        name: 'Master Data',
        children: [
            {
                path: '/user-management',
                element: <UserManagement />,
                isSideMenu: true,
                icon: ({ isActive }) => <PeopleIcon3 color={isActive ? 'white' : 'grey'} />,
                name: 'Users',
            },
            // {
            //     path: '/market-place',
            //     element: <NotAvalaible />,
            //     isSideMenu: true,
            //     icon: ({ isActive }) => <MarketPlaceIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
            //     name: 'Market Place',
            // },
            // {
            //     path: '/brand',
            //     element: <NotAvalaible />,
            //     isSideMenu: true,
            //     icon: ({ isActive }) => <CouponIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
            //     name: 'Brand',
            // },
            {
                path: '/account-data',
                element: <Account />,
                isSideMenu: true,
                icon: ({ isActive }) => <IconBubble color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Account',
            },
            {
                path: '/role',
                element: <Role />,
                isSideMenu: true,
                icon: ({ isActive }) => <IconBubble color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Role',
            },
            {
                path: '/studio',
                element: <Studio />,
                isSideMenu: true,
                icon: ({ isActive }) => <IconBubble color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Studio',
            },
        ],
    },
    {
        path: '/scheduling',
        element: <NotAvalaible />,
        isSideMenu: true,
        icon: ({ isActive }) => <ScheduleIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
        name: 'Scheduling',
        children: [
            {
                path: '/schedule',
                element: <Schedule />,
                isSideMenu: true,
                icon: ({ isActive }) => <ScheduleIcon2 color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Schedule',
            },

            {
                path: '/highlight-product',
                element: <ProductHighlight />,
                isSideMenu: true,
                icon: ({ isActive }) => <PaperIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Highlight Product',
            },
            {
                path: '/promotion',
                element: <Promotion />,
                isSideMenu: true,
                icon: ({ isActive }) => <PromotionIcon color={isActive ? 'white' : 'grey'} />,
                name: 'Promotion',
            },
            {
                path: '/voucher',
                element: <Voucher />,
                isSideMenu: true,
                icon: ({ isActive }) => <VoucherIcon color={isActive ? 'white' : 'grey'} />,
                name: 'Voucher',
            },
            // {
            //     path: '/gwp',
            //     element: <NotAvalaible />,
            //     isSideMenu: true,
            //     icon: ({ isActive }) => <WalletIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
            //     name: 'GWP',
            // },
            {
                path: '/coin',
                element: <Koin />,
                isSideMenu: true,
                icon: ({ isActive }) => <CoinIcon color={isActive ? 'white' : 'grey'} />,
                name: 'Koin',
            },
        ],
    },
    {
        path: '/achievement',
        element: <NotAvalaible />,
        isSideMenu: true,
        icon: ({ isActive }) => <AchievementIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
        name: 'Achievement',
        children: [
            {
                path: '/reporting-achievement',
                element: <ReportingAchievement />,
                isSideMenu: true,
                icon: ({ isActive }) => <ChartIcon1 color={isActive ? 'white' : 'var(--text-color-1'} />,
                name: 'Reporting',
            },
            {
                path: '/target-schedule',
                element: <TargetSchedule />,
                isSideMenu: true,
                icon: ({ isActive }) => <UploadIcon1 color={isActive ? 'white' : 'grey'} />,
                name: 'Target',
            },
        ],
    },
    // {
    //     path: '/manage-user',
    //     element: <NotAvalaible />,
    //     isSideMenu: true,
    //     icon: ({ isActive }) => <SettingIcon color={isActive ? 'white' : 'var(--text-color-1'} />,
    //     name: 'User Management',
    // },

    {
        path: '/login',
        element: <Login />,
        isSideMenu: false,
    },
    {
        path: '/schedule/:id',
        element: <ScheduleDetail />,
        isSideMenu: false,
    },
    {
        path: '/schedule-submission/:id',
        element: <ScheduleSubmission />,
        isSideMenu: false,
    },
    {
        path: '/reset-password/:token',
        element: <ResetPassword />,
        isSideMenu: false,
    },
    {
        path: '*',
        isSideMenu: false,
        element: <NotAvalaible />,
    },
];
