import DatePicker from 'react-datepicker';
import { DownloadIcon2 } from '@assets/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { onDownloadTemplate } from '@pages/targetSchedule/helpers/events';
import { CalendarIcon } from '@assets/icons';
import { useState } from 'react';

import 'react-datepicker/src/stylesheets/datepicker.scss';
import './styles.scss';

const DownloadTemplate = () => {
    const dispatch = useDispatch();
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box className="download-template__container">
            <Button className="download-template__button" onClick={() => setIsOpen(true)}>
                <Box>
                    <DownloadIcon2 color="#0066b1" />
                </Box>
                <Text className="title-download">Download Template</Text>
            </Button>

            {isOpen && (
                <Box className="floating-select-month__container">
                    <DatePicker
                        selected={selectedMonth}
                        onChange={(value) => {
                            setSelectedMonth(value);
                        }}
                        placeholderText="Pilih bulan"
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        showIcon
                        icon={<CalendarIcon color="grey" />}
                    />
                    <Button
                        className="download-button"
                        onClick={() => onDownloadTemplate({ dispatch, selectedMonth, onClose: () => setIsOpen(false) })}
                    >
                        Download
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default DownloadTemplate;
