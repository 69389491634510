import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const fetchAllVoucher = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const response = await Axios({
            method: 'GET',
            url: `/voucher/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { vouchers, totalData, totalPage } = response?.data.data;
        setState({
            ...state,
            vouchers: vouchers,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: vouchers?.length,
            isEditPage: false,
            isCreatePage: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, vouchers: [], isLoading: false });
    }
};

export const fetchSingleVoucher = async ({ id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        await Axios({
            method: 'GET',
            url: `/voucher/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengambil voucher dengan id tersebut';
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { brands } = data?.data;

        brands = brands?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return brands || [];
    } catch (error) {
        return [];
    }
};

const fetchMarketPlaceHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/ecommerce/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { ecommerces } = data?.data;

        ecommerces = ecommerces?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return ecommerces || [];
    } catch (error) {
        return [];
    }
};

const fetchAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/account-livestream/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { accountLivestreams } = data?.data;

        accountLivestreams = accountLivestreams?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id, ecommerce: item?.ecommerce?.name };
        });

        return accountLivestreams || [];
    } catch (error) {
        return [];
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const brands = await fetchBrandsHandler();
        const marketPlace = await fetchMarketPlaceHandler();
        const accounts = await fetchAccountHandler();
        setAsset({ brands, marketPlace, accounts });
    } catch (error) {
        console.log(error);
    }
};

export const fetchSingleComment = async (id) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const response = await Axios({
            method: 'GET',
            url: `/voucher/comment/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        return response.data.data;
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengambil komen id tersebut';
    }
};

export const addSingleComment = async ({ value, valueId, dispatch }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = {
        comment: value,
    };

    try {
        const response = await Axios({
            method: 'POST',
            url: `/voucher/comment/${valueId}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        // dispatch(
        //     popupHandler({
        //         popUpType: 'popup-success',
        //         popUpTitle: 'Berhasil menambahkan comment',
        //         popUpDescription: '',
        //     })
        // );

        return response;
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengupload komen id tersebut';
    }
};

export const removeSingleVoucher = async ({ selectedVoucher, refetchVoucherHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        await Axios({
            method: 'DELETE',
            url: `/voucher/${selectedVoucher.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchVoucherHandler();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const approveBulkVoucher = async ({ dispatch, refetchVoucherHandler, state }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const payloads = {
            id: state?.multiSelectVoucher,
            isActivated: true,
        };

        await Axios({
            method: 'PATCH',
            url: `/voucher/approved`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });
        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil approve  voucher`,
                popUpDescription: '',
            })
        );

        await refetchVoucherHandler();
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal memodifikasi data!';
    }
};

export const rejectSingleVoucherHandler = async ({ dispatch, idVoucher, refetchVoucherHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const payloads = {
            id: [idVoucher],
            isActivated: false,
        };

        await Axios({
            method: 'PATCH',
            url: `/voucher/approved`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil Reject  voucher`,
                popUpDescription: '',
            })
        );

        await refetchVoucherHandler();
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: `Gagal Reject  voucher`,
                popUpDescription: '',
            })
        );
    }
};

export const onSubmitProduct = async ({
    state,
    dispatch,
    setState,
    refetchVoucherHandler,
    isEdit,
    selectedProduct,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = payloadFormatting({ state });

    setState({ ...state, isLoading: true });
    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: `Sedang mengirim data...`,
            popUpDescription: '',
        })
    );

    setState({ ...state, isLoading: true });

    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/voucher/${selectedProduct?.id}` : `/voucher`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchVoucherHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil ${isEdit ? 'mengupdate' : 'menambahkan'} voucher`,
                popUpDescription: '',
            })
        );
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        const messageDate = state.startedAt === '' || state.endedAt === '' ? 'Silahkan Isi Tanggal' : '';

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: message || `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} voucher\n ${messageDate}`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};
