import { Text, Flex, Box, Checkbox } from '@chakra-ui/react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import { BadgeStatus } from '@components/badgeStatus';

import { VoucherContext } from '@pages/voucher';
import { onShowingResetApprovalPopUp } from '@pages/voucher/helpers/events';
import { EditIcon, RemoveIcon, ResetIcon } from '@assets/icons';

export const tableList = ({ state }) => {
    return [
        {
            name: 'Nama',
            value: 'title',
            width: 260,
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'title',
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'Marketplace',
            width: 160,
            isSticky: true,
            value: 'accountLivestreams',
            dataManipulationValue: 'accountLivestreams.ecommerce.name',
            dataManipulationType: 'search',
            component: ({ value }) => <Text className="column-text">{value[0]?.ecommerce?.name}</Text>,
        },
        {
            name: 'Account',
            width: 300,
            value: 'accountLivestreams',
            dataManipulationValue: 'accountLivestreams.name',
            dataManipulationType: 'search',
            isSticky: true,
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        {value?.map(({ name, brands }) => (
                            <Text className="column-text">
                                {name}
                                <span>{`(${brands?.map(({ name }) => name).toString()})`}</span>
                            </Text>
                        ))}
                    </Flex>
                );
            },
        },

        {
            name: 'Date Start',
            width: 180,
            value: 'startedAt',
            component: ({ value }) => {
                const formattedDate = value.slice(0, 10);
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                const date = new Date(formattedDate);
                const formattedDateString = date.toLocaleDateString(undefined, options);

                return <Text className="column-text">{formattedDateString}</Text>;
            },
        },
        {
            name: 'Date End',
            width: 180,
            value: 'endedAt',
            component: ({ value }) => {
                const formattedDate = value.slice(0, 10);
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                const date = new Date(formattedDate);
                const formattedDateString = date.toLocaleDateString(undefined, options);

                return <Text className="column-text">{formattedDateString}</Text>;
            },
        },
        {
            name: 'Status Promosi',
            width: 200,
            isDivider: true,
            value: 'status',
            dataManipulationType: 'search',
            dataManipulationValue: 'status',
            component: ({ value }) => {
                const statusMap = {
                    Mendatang: { color: '#0076CC', backgroundColor: '#BDE2FF' },
                    Berlangsung: { color: '#13AE5C', backgroundColor: '#AFF4C6' },
                    Selesai: { color: '#D47C00', backgroundColor: '#FFE8A3' },
                };

                const statusInfo = statusMap[value] || { color: '', backgroundColor: '' };

                return (
                    <BadgeStatus title={value} color={statusInfo.color} backgroundColor={statusInfo.backgroundColor} />
                );
            },
        },
        {
            name: 'Konfirmasi Parama',
            width: 200,
            isDivider: true,
            value: 'isActivated',
            component: ({ value }) => {
                const konfirmasiMap = {
                    true: { title: 'Completed', color: '#13AE5C', backgroundColor: '#AFF4C6' },
                    false: { title: 'To do', color: '#F24822', backgroundColor: '#FFC7C2' },
                };

                const konfirmasiInfo = konfirmasiMap[value];

                return <BadgeStatus {...konfirmasiInfo} />;
            },
        },
        {
            name: '',
            width: 30,
            component: ({ values }) => {
                return state?.isConfirmation ? (
                    <CheckboxComponent selectedVoucher={values} multiSelectVouchers={state?.multiSelect} />
                ) : (
                    <ActionComponent selectedVoucher={values} />
                );
            },
        },
    ];
};

//---------------------------------------------- ADD ON ------------------------------

const ActionComponent = ({ selectedVoucher }) => {
    const { onEditColumnHandler, onShowingRemovePopUp } = useContext(VoucherContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedVoucher)} className="box-icon">
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedVoucher)} className="remove-icon">
                <RemoveIcon />
            </Box>
        </Flex>
    );
};

const CheckboxComponent = ({ selectedVoucher, multiSelectKoin }) => {
    const { onCheckboxHandler, refetchVoucherHandler } = useContext(VoucherContext);
    const dispatch = useDispatch();
    const isActivated = selectedVoucher?.isActivated; // is approved by parama

    const isChecked = includes(multiSelectKoin, selectedVoucher?.id);

    return (
        <div>
            {isActivated ? (
                <Box onClick={() => onShowingResetApprovalPopUp({ dispatch, selectedVoucher, refetchVoucherHandler })}>
                    <ResetIcon color="var(--main-color)" />
                </Box>
            ) : (
                <Checkbox
                    size="lg"
                    verticalAlign="middle"
                    onChange={() => onCheckboxHandler(selectedVoucher)}
                    checked={isChecked}
                    colorScheme="purple"
                    className="checkbox-field"
                />
            )}
        </div>
    );
};
