import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';

export const payloadFormatting = ({ state, isEdit }) => {
    const {
        brand,
        email,
        name,
        password,
        handphone,
        address,
        dateOfBirth,
        gender,
        placeOfBirth,
        domicile,
        roleId,
        username,
        isLdap,
        baId,
    } = state;
    const formattedPayload = {
        brand: brand?.map((item) => item.id) || '',
        email: email || '',
        name: name || '',
        handphone: handphone || '',
        address: address || '',
        gender: gender ? gender.value : '',
        placeOfBirth: placeOfBirth || '',
        domicile: domicile || '',
        roleId: roleId ? roleId.id.toString() : '',
        username: username || '',
        isLdap: isLdap,
        baId: baId ? baId.toString() : '',
    };
    if (isEdit && !isEmpty(password)) {
        formattedPayload.password = password;
    }
    if (!isEdit && !isLdap && !isEmpty(password)) {
        formattedPayload.password = password;
    }

    return formattedPayload;
};

export const getBrandColor = ({ name }) => {
    const obj = {
        Wonderly: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wonderly.png',
            name: 'Wonderly',
        },
        Biodef: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/biodef.jpg',
            name: 'Biodef',
        },
        Instaperfect: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/instaperfect.png',
            name: 'Instaperfect',
        },
        OMG: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/omg-icon.png',
            name: 'OMG',
        },
        Putri: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/putri.png',
            name: 'Putri',
        },
        'Make Over': {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/makeover.jpg',
            name: 'Make Over',
        },
        Labore: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/labore.png',
            name: 'Labore',
        },
        Beyondly: {
            link: 'https://www.beyondlyid.com/favicon.ico',
            name: 'Beyondly',
        },
        Crystallure: {
            link: 'https://crystallurebeauty.com/favicon.ico',
            name: 'Crystallure',
        },
        Tavi: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/tavi.jpg',
            name: 'Tavi',
        },
        Emina: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/emina.jpeg',
            name: 'Emina',
        },
        Kahf: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/kahf.jpeg',
            name: 'Kahf',
        },
        Wardah: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wardah.jpg',
            name: 'Wardah',
        },
    };
    return obj[name];
};

export const sortByBrandHandler = (arr) => {
    return sortBy(arr, ({ name }) => name);
};

export const formattingSelectedStateHandler = ({ arr, type }) => {
    if (arr === null || arr === undefined) {
        if (type === 'brands') {
            return [];
        } else {
            return null;
        }
    }

    const dataArray = Array.isArray(arr) ? arr : [arr];

    const obj = {
        gender: dataArray.map((value) => {
            return {
                label: value,
                value: value,
            };
        })[0],
        role: dataArray.map(({ name, id }) => {
            return {
                label: name,
                value: name,
                id,
            };
        })[0],
        brands: dataArray.map(({ name, id }) => {
            return {
                label: name,
                value: name,
                id,
            };
        }),
        isLdap: dataArray.map((value) => {
            return {
                label: `${value.toString().slice(0, 1).toUpperCase()}${value.toString().slice(1)}`,
                value: value,
            };
        }),
    };

    // Return the formatted data based on the 'type' provided
    return obj[type];
};
