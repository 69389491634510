export const initialState = {
    isLoading: false,
    accounts: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    selectedAccount: {},
};

export const initialAssets = {
    brands: [],
    marketPlaces: [],
    parentAccounts: [],
};