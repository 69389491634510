import { sortBy } from "lodash";

export const getBrandColor = ({ name }) => {
  const obj = {
    Wonderly: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/wonderly.png",
      name: "Wonderly",
    },
    Biodef: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/biodef.jpg",
      name: "Biodef",
    },
    Instaperfect: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/instaperfect.png",
      name: "Instaperfect",
    },
    OMG: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/omg-icon.png",
      name: "OMG",
    },
    Putri: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/putri.png",
      name: "Putri",
    },
    "Make Over": {
      link: "https://bivi.pti-cosmetics.com/logo_brand/makeover.jpg",
      name: "Make Over",
    },
    Labore: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/labore.png",
      name: "Labore",
    },
    Beyondly: {
      link: "https://www.beyondlyid.com/favicon.ico",
      name: "Beyondly",
    },
    Crystallure: {
      link: "https://crystallurebeauty.com/favicon.ico",
      name: "Crystallure",
    },
    Tavi: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/tavi.jpg",
      name: "Tavi",
    },
    Emina: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/emina.jpeg",
      name: "Emina",
    },
    Kahf: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/kahf.jpeg",
      name: "Kahf",
    },
    Wardah: {
      link: "https://bivi.pti-cosmetics.com/logo_brand/wardah.jpg",
      name: "Wardah",
    },
  };
  return obj[name];
};

export const sortByBrandHandler = (arr) => {
  return sortBy(arr, ({ name }) => name);
};
