import moment from 'moment';

export const serviceHandler = ({ filterStringify, reportingType, filter }) => {
    const { date, startDate, endDate } = filter;

    const obj = {
        'Achievement Host': {
            url: `/achievement/summary?${filterStringify}`,
            data: {
                date: date,
            },
        },

        'All Parameter by Host': {
            url: `/achievement/all-parameter?${filterStringify}`,
            data: {
                date: date,
            },
        },
        Session: {
            url: `/achievement/session?${filterStringify}`,
            data: {
                startedAt: startDate,
                endedAt: endDate,
            },
        },
        'Achievement Account Platform': {
            url: `/achievement/live-selling-performace?${filterStringify}`,
            data: {
                startedAt: startDate,
                endedAt: endDate,
            },
        },
    };

    return obj[reportingType];
};

export const responseHandler = ({ data, reportingType }) => {
    const obj = {
        'Achievement Host': 'summaryHost',
        Session: 'sessions',
        'Achievement Account Platform': 'liveSellingPerformance',
        'All Parameter by Host': 'data',
    };

    return data[obj[reportingType]];
};

export const downloadServiceHandler = ({ reportingType, filter }) => {
    const obj = {
        'Achievement Host': {
            url: `/achievement/download/summary`,
            body: {
                date: moment(filter?.dateFilter?.date).format('YYYY-MM-DD'),
            },
            fileName: 'Achievement Host',
        },
        'All Parameter by Host': {
            url: `/achievement/download/all-parameter`,
            body: {
                date: moment(filter?.dateFilter?.date).format('YYYY-MM-DD'),
            },
            fileName: 'All Parameter by Host',
        },
        Session: {
            url: `/achievement/download/session`,
            body: {
                startedAt: moment(filter?.dateFilter?.startDate).format('YYYY-MM-DD'),
                endedAt: moment(filter?.dateFilter?.endDate).format('YYYY-MM-DD'),
            },
            fileName: 'Session',
        },
        'Achievement Account Platform': {
            url: `/achievement/download/live-selling-performace`,
            body: {
                startedAt: moment(filter?.dateFilter?.startDate).format('YYYY-MM-DD'),
                endedAt: moment(filter?.dateFilter?.endDate).format('YYYY-MM-DD'),
            },
            fileName: 'Achievement Account Platform',
        },
    };

    return obj[reportingType];
};
