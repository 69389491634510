import { Flex, Text } from '@chakra-ui/react';
import { ButtonAdd } from '@components/button';
import { useEffect, useState } from 'react';
import { FilterSelectSearch } from '@components/globalFilter';
import { PeopleIcon1 } from '@assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { updateFilterQueriesHandler } from '@helpers/util';

import { fetchFilterOptionsHandler } from './functions';

import './styles.scss';

const SectionHead = ({ setState, state }) => {
    const [filterOptions, setFilterOptions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        /* eslint-disable */
        fetchFilterOptionsHandler({ setFilterOptions, filterOptions });
    }, []);

    return (
        <Flex className="section-header__container__usermanagement">
            {/** -------------------------- LEFT SIDE ----------------------------**/}
            <Flex className="left-side">
                <Text className="title">User Management</Text>
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            <Flex className="right-side">
                <FilterSelectSearch
                    placeholder="Pilih role"
                    icon={<PeopleIcon1 color="grey" />}
                    options={[
                        {
                            name: 'Host',
                            value: 'host',
                        },
                        {
                            name: 'Operator',
                            value: 'operator',
                        },
                    ]}
                    filterName="role.name"
                    onChange={({ type, value }) =>
                        updateFilterQueriesHandler({ location, type: 'searchparam', value, navigate, valueName: type })
                    }
                />
                <ButtonAdd
                    textButton="Add User"
                    width="120px"
                    onClick={() => setState({ ...state, isCreatePage: true })}
                />
            </Flex>
        </Flex>
    );
};

export default SectionHead;
