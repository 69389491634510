import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { CloseIcon, SortIcon } from '@assets/icons';

import './styles.scss';
import { updateFilterQueriesHandler } from '@helpers/util';

export const SearchByColumn = ({ dataManipulationValue }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({ isOpen: false, query: '' });

    const onApplyHandler = () => {
        setState({ ...state, isOpen: false });
        updateFilterQueriesHandler({
            location,
            navigate,
            type: 'searchparam',
            value: state?.query,
            valueName: dataManipulationValue,
        });
    };

    return (
        <Box className="search-by-column__container">
            {/** ---------------- ICON ---------------- */}
            <Box className="icon-container" onClick={() => setState({ ...state, isOpen: true })}>
                <SearchIcon />
            </Box>

            {/** ---------------- BOX ---------------- */}

            {state?.isOpen && (
                <OutsideClickHandler onOutsideClick={() => setState({ ...state, isOpen: false })}>
                    <Box className="search-box">
                        <Flex justifyContent="space-between" mb="16px" alignItems="center">
                            <Text className="label-search">Search</Text>
                            <Box onClick={() => setState({ ...state, isOpen: false })} cursor="pointer">
                                <CloseIcon color="black" />
                            </Box>
                        </Flex>
                        <Box alignItems="center">
                            <Box className="input-container">
                                <input
                                    autoFocus
                                    onChange={(e) => setState({ ...state, query: e.target.value })}
                                    onKeyUp={(e) => e.key === 'Enter' && onApplyHandler()}
                                />
                            </Box>
                            <Button className="submit-button" onClick={onApplyHandler}>
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </OutsideClickHandler>
            )}
        </Box>
    );
};

export const SortByColumn = ({ dataManipulationValue }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;

    const onApplyHandler = () => {
        updateFilterQueriesHandler({
            location,
            navigate,
            type: 'sort',
            value: search?.includes('desc') ? 'asc' : 'desc',
            valueName: dataManipulationValue,
        });
    };

    return (
        <Box className="search-by-column__container">
            {/** ---------------- ICON ---------------- */}
            <Box className="icon-container" onClick={onApplyHandler}>
                <SortIcon color="white" size={16} />
            </Box>
        </Box>
    );
};
