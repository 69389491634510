export const initialState = {
    isLoading: true,
    vouchers: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    isConfirmation: false,
    queueConfirm: [],
    multiSelectVoucher: [],
};
export const initialAssets = {
    brands: [],
    marketPlaces: [],
    accounts: [],
};
