import { CloseIcon } from '@assets/icons';
import { useEffect, useState, useContext } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import ModalTemplate from '@components/modal';
import { popupHandler } from '@store/list/popup';
import { formattingOptionHandler, formattingAccountsOptionHandler } from '@pages/schedule/helpers/utlis';
import { ScheduleContext } from '@pages/schedule';
import { submitScheduleHandler } from '@pages/schedule/helpers/crud';
import Switch from '@components/switch';
import { InputComponent, SelectComponent, TextAreaComponent, TimePickerComponent } from '@components/formFields';
import { initialState } from './const';

import './styles.scss';

const CreateOrEditEventPopUp = ({ onClose, selectedDate, isEdit, selectedEvent, mainState }) => {
    const { assets, refetchScheduleHandler } = useContext(ScheduleContext);
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);

    const onChangeFormField = ({ fieldName, value }) => setState({ ...state, [fieldName]: value });
    const onRestrictClosePopUp = () => {
        dispatch(
            popupHandler({
                popUpType: 'popup-confirmation-submit',
                popUpTitle: 'Apakah anda yakin ingin meninggalkan popup ini?',
                popUpDescription: 'data yang sudah anda submit akan menghilang',
                submitLabel: 'Ya',
                hidePopUpSuccess: true,
                onSubmit: () => onClose(),
            })
        );
    };

    useEffect(() => {
        /* eslint-disable */

        if (isEdit) {
            const { schedule } = selectedEvent;
            setState({
                ...state,
                scheduleType: schedule?.scheduleType,
                startedAt: moment(schedule?.startedAt).format('HH:mm'),
                endedAt: moment(schedule?.endedAt).format('HH:mm'),
                hostId: formattingOptionHandler({ arr: schedule?.hosts }),
                operatorId: formattingOptionHandler({ arr: schedule?.operators }),
                title: schedule?.title,
                studioId: { label: schedule?.studio?.name, value: schedule?.studio?.name, id: schedule?.studio?.id },
                reason: schedule?.reason,
                accountLivestreamId: formattingOptionHandler({ arr: schedule?.accountLivestreams }),
                date: moment(schedule?.startedAt).format('YYYY-MM-DD'),
            });
        }
        if (selectedDate?.dateStr) {
            const gapDurationHour = selectedDate?.timeOffset || 2;

            setState({
                ...state,
                startedAt: moment(selectedDate?.dateStr).format('LT')?.replace('.', ':'),
                endedAt: moment(selectedDate?.dateStr).add(gapDurationHour, 'hour').format('LT')?.replace('.', ':'),
                date: moment(selectedDate?.dateStr).format('YYYY-MM-DD'),
                accountLivestreamId: formattingOptionHandler({
                    arr: assets.accounts?.filter((item) => mainState?.account?.includes(item?.id)),
                }),
                studioId: formattingOptionHandler({
                    arr: assets.studios?.filter((item) => item?.value === mainState?.studio),
                })[0],
                operatorId: formattingOptionHandler({
                    arr: assets.operators?.filter((item) => item?.value === mainState?.operator),
                }),
                hostId: formattingOptionHandler({
                    arr: assets.hosts?.filter((item) => item?.value === mainState?.host),
                }),
            });
        }
    }, [selectedDate, selectedEvent, assets]);

    return (
        <ModalTemplate onClose={onRestrictClosePopUp}>
            <Box className="create-or-edit-event-popup__container">
                <Flex className="header">
                    <Text className="title">{isEdit ? 'Edit' : 'Add'} Schedule</Text>
                    <Box cursor="pointer" onClick={onRestrictClosePopUp}>
                        <CloseIcon color="grey" />
                    </Box>
                </Flex>
                <Flex className="body">
                    <Flex justifyContent="flex-end">
                        <Switch
                            value1="Live"
                            value2="Block"
                            width={160}
                            fieldName="scheduleType"
                            onChange={onChangeFormField}
                        />
                    </Flex>
                    <InputComponent
                        label="Judul"
                        placeholder="Masukan judul event"
                        errorMessage="Title required!"
                        value={state?.title}
                        onChange={onChangeFormField}
                        fieldName="title"
                    />
                    <SelectComponent
                        isMulti={true}
                        label="Account"
                        fieldName="accountLivestreamId"
                        placeholder="Pilih account"
                        value={state?.accountLivestreamId}
                        onChange={onChangeFormField}
                        options={formattingAccountsOptionHandler({ arr: assets?.accounts })}
                    />
                    <Flex className="time-picker__container">
                        <TimePickerComponent
                            label="Waktu"
                            fieldName="startedAt"
                            onChange={onChangeFormField}
                            value={state?.startedAt}
                        />
                        <Text className="divider">-</Text>
                        <TimePickerComponent
                            label=""
                            fieldName="endedAt"
                            onChange={onChangeFormField}
                            value={state?.endedAt}
                        />
                    </Flex>
                    {state?.scheduleType === 'Live' && (
                        <>
                            <SelectComponent
                                isMulti={true}
                                label="Host"
                                onChange={onChangeFormField}
                                fieldName="hostId"
                                placeholder="Pilih host"
                                value={state?.hostId}
                                options={formattingOptionHandler({ arr: assets?.hosts })}
                            />
                            <SelectComponent
                                isMulti={false}
                                label="Studio"
                                fieldName="studioId"
                                onChange={onChangeFormField}
                                value={state?.studioId}
                                placeholder="Pilih studio"
                                options={formattingOptionHandler({ arr: assets?.studios })}
                            />
                            <SelectComponent
                                isMulti={true}
                                label="Operator"
                                fieldName="operatorId"
                                onChange={onChangeFormField}
                                value={state?.operatorId}
                                placeholder="Pilih operator"
                                options={formattingOptionHandler({ arr: assets?.operators })}
                            />
                        </>
                    )}

                    {state?.scheduleType === 'Block' && (
                        <TextAreaComponent
                            label="Alasan"
                            placeholder="Masukan alasan blok"
                            errorMessage="Title required!"
                            onChange={onChangeFormField}
                            fieldName="reason"
                        />
                    )}
                </Flex>

                <Flex className="footer">
                    <Button className="button-cancel" onClick={onRestrictClosePopUp}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() =>
                            submitScheduleHandler({
                                state,
                                dispatch,
                                refetchScheduleHandler,
                                onClose,
                                isEdit,
                                eventId: selectedEvent?.schedule?.id,
                            })
                        }
                    >
                        Submit
                    </Button>
                </Flex>
            </Box>
        </ModalTemplate>
    );
};

export default CreateOrEditEventPopUp;
