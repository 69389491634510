import { LockIcon, PeopleIcon1 } from '@assets/icons';
import { useState } from 'react';
import { Box, Text, Button, Input, VStack, InputRightElement, InputGroup } from '@chakra-ui/react';
import { ArrowBackIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { loginHandler, forgotPasswordHandler } from './functions';
import { initialState } from './const';
import './styles.scss';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const [state, setState] = useState(initialState);
    // const [showPassword, setShowPassword] = useState(false);

    return (
        <Box className="login-container">
            {state?.isForgotPasswordModalOpen ? (
                // --------------------------- FORGOT PASSWORD -------------------------
                <Box className="white-box">
                    <Box className="back-icon" onClick={() => setState({ ...state, isForgotPasswordModalOpen: false })}>
                        <ArrowBackIcon color="black" fontSize="22px" />
                    </Box>
                    <Text className="title">Forgot Password</Text>
                    <Box className="input-container">
                        <Text className="label">Email</Text>
                        <Box className="input-field__container">
                            <Box className="icon">
                                <PeopleIcon1 color={'grey'} />
                            </Box>
                            <Input
                                className="input-field"
                                placeholder="Type your email"
                                autoComplete="off"
                                value={state?.resetEmail}
                                onChange={(e) => setState({ ...state, resetEmail: e.target.value })}
                                onKeyUp={(e) =>
                                    e.key === 'Enter' && forgotPasswordHandler({ setState, state, dispatch })
                                }
                            />
                        </Box>
                    </Box>

                    <Button
                        className="submit-button"
                        isLoading={state?.isLoading}
                        onClick={() => forgotPasswordHandler({ setState, navigate, state, dispatch })}
                    >
                        Send
                    </Button>
                </Box>
            ) : (
                // --------------------------- LOGIN  ---------------------------

                <Box className="white-box">
                    <Text className="title">Login</Text>

                    {/** ---------------------- USERNAME -------------------------- */}

                    <Box className="input-container">
                        <Text className="label">Username</Text>
                        <Box className="input-field__container">
                            <Box className="icon">
                                <PeopleIcon1 color={'grey'} />
                            </Box>
                            <Input
                                className="input-field"
                                placeholder="Type your username"
                                autoComplete="off"
                                value={state?.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                onKeyUp={(e) =>
                                    e.key === 'Enter' && loginHandler({ setState, navigate, state, dispatch, cookies })
                                }
                            />
                        </Box>
                    </Box>

                    {/** ---------------------- PASSWORD -------------------------- */}

                    <Box className="input-container">
                        <Text className="label">Password</Text>
                        <Box className="input-field__container">
                            <Box className="icon">
                                <LockIcon color={'grey'} />
                            </Box>
                            <InputGroup>
                                <Input
                                    type={state.showPassword ? 'text' : 'password'}
                                    className="input-field"
                                    placeholder="Type your password"
                                    autoComplete="off"
                                    onChange={(e) => setState({ ...state, password: e.target.value })}
                                    value={state?.password}
                                    onKeyUp={(e) =>
                                        e.key === 'Enter' &&
                                        loginHandler({ setState, navigate, state, dispatch, cookies })
                                    }
                                />
                                <InputRightElement width="4.5rem">
                                    <Button bg="white" onClick={() => setState({ ...state, showPassword: !state.showPassword })} mt="16px">
                                        {state.showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Box>

                    {/** ---------------------- LOGIN BUTTON -------------------------- */}

                    <Button
                        className="submit-button"
                        isLoading={state?.isLoading}
                        onClick={() => loginHandler({ setState, navigate, state, dispatch, cookies })}
                    >
                        LOGIN
                    </Button>
                    <VStack>
                        <Button
                            className="forgot-password__text"
                            onClick={() => setState({ ...state, isForgotPasswordModalOpen: true })}
                        >
                            Forgot Password
                        </Button>
                    </VStack>
                </Box>
            )}
        </Box>
    );
};

export default Login;
