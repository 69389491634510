import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';
import { forEach } from 'lodash';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { TableListComingSoon, TableListDone, FinishingTableList } from '../sections/sectionListLiveMobile/const';
import { removeNonNumeric } from '@helpers/util';

export const getTableListHandler = ({ selectedTableType }) => {
    const obj = {
        'done-live': TableListDone,
        'comingsoon-live': TableListComingSoon,
        'finishing-live': FinishingTableList,
    };
    return obj[selectedTableType];
};

export const getDate = ({ schedule }) => {
    const date = moment(schedule?.startedAt).format('DD MMM YYYY');
    return date;
};

export const getRangeTime = ({ schedule }) => {
    const startedAt = moment(schedule?.startedAt).format('hh:mm');
    const endedAt = moment(schedule?.endedAt).format('hh:mm');

    return `${startedAt} - ${endedAt}`;
};

export const getAccounts = ({ schedule }) => {
    const accounts = schedule?.accountLivestreams?.map((item) => item.name);

    return (
        <Flex className="account-container">
            {accounts?.map((item) => (
                <Text className="account-text">{item}</Text>
            ))}
        </Flex>
    );
};

export const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

export const getInputWording = ({ ecommerce, type }) => {
    let obj = {};
    switch (ecommerce) {
        case 'Shopee':
            obj = {
                revenue: 'penjualan',
                viewer: 'dilihat',
                ctrPercentage: 'presentasi klik',
                duration: 'durasi aktual live',
                avgViewDuration: 'durasi rerata menonton',
            };
            break;

        default:
            obj = {
                revenue: 'penghasilan',
                viewer: 'penonton',
                ctrPercentage: 'Rasio klik(CTR)',
                duration: 'durasi aktual live',
                avgViewDuration: 'durasi rerata',
            };
            break;
    }

    return obj[type];
};

export const formattingPayloadHandler = ({ state }) => {
    const { schedule, form } = state;

    const lines = [];

    forEach(form, (value, accountLivestreamId) => {
        lines.push({
            accountLivestreamId: accountLivestreamId,
            fileId: value?.fileId,
            revenue: removeNonNumeric(value?.revenue),
            viewer: removeNonNumeric(value?.viewer),
            ctrPercentage: value?.ctrPercentage,
            duration: value?.duration,
            buyer: value?.buyer,
            avgViewDuration: value?.avgViewDuration,
        });
    });

    return {
        scheduleId: schedule?.id,
        lines,
    };
};

export const parseRichText = (value) => {
    let result = '';

    try {
        result = parse(draftToHtml(JSON.parse(value)));
    } catch (error) {}

    return result;
};