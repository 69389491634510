import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { CloseIcon } from '@assets/icons';

import './styles.scss';
import { updateFilterQueriesHandler } from '@helpers/util';

export const SearchByColumn = ({ valueName }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({ isOpen: false, query: '' });

    const onApplyHandler = () => {
        setState({ ...state, isOpen: false });
        updateFilterQueriesHandler({ location, navigate, type: 'searchparam', value: state?.query, valueName });
    };

    return (
        <Box className="search-by-column__container">
            {/** ---------------- ICON ---------------- */}
            <Box className="icon-container" onClick={() => setState({ ...state, isOpen: true })}>
                <SearchIcon />
            </Box>

            {/** ---------------- BOX ---------------- */}

            {state?.isOpen && (
                <OutsideClickHandler onOutsideClick={() => setState({ ...state, isOpen: false })}>
                    <Box className="search-box">
                        <Flex justifyContent="space-between" mb="16px" alignItems="center">
                            <Text className="label-search">Search</Text>
                            <Box onClick={() => setState({ ...state, isOpen: false })} cursor="pointer">
                                <CloseIcon color="black" />
                            </Box>
                        </Flex>
                        <Box alignItems="center">
                            <Box className="input-container">
                                <input onChange={(e) => setState({ ...state, query: e.target.value })} />
                            </Box>
                            <Button className="submit-button" onClick={onApplyHandler}>
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </OutsideClickHandler>
            )}
        </Box>
    );
};
