import { Box, Skeleton, Text, Input, Flex } from '@chakra-ui/react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useState } from 'react';
import CircleActive from '@components/circleActive';
import { get, find, isEmpty } from 'lodash';
import { filteringDataByFuzzball } from '@helpers/util';
import { Search2Icon } from '@chakra-ui/icons';

import './styles.scss';
import { CheckIcon, ResetIcon } from '@assets/icons';

export const FilterSelect = ({
    options,
    placeholder,
    icon,
    margin,
    isLoading,
    onChange,
    filterName,
    value,
    isReset = true,
}) => {
    const [state, setState] = useState({ isOpen: false, selected: '' });
    const { selected, isOpen } = state;

    const onClickMenu = ({ selected }) => {
        onChange({ type: filterName, value: selected });
        setState({ ...state, selected, isOpen: false });
    };

    const onResetFilter = (e) => {
        e.stopPropagation();
        setState({ isOpen: false, selected: '', query: '' });
        onChange({ type: filterName, value: [] });
    };

    useEffect(() => {
        /* eslint-disable */

        setState({ ...state, selected: value });
    }, [value]);

    return (
        <Flex alignItems="center" gap="2">
            <Box className="filter-popup__container" margin={margin}>
                {/** ---------------------- BOX FILTER ---------------------------------- */}
                <Box className="box-filter" onClick={() => setState({ ...state, isOpen: true })}>
                    {isLoading ? (
                        <Skeleton h="20px" w="80px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                    ) : (
                        <>
                            {selected?.length ? (
                                <Text className="selected-text">{selected}</Text>
                            ) : (
                                <Text className="placeholder">{placeholder}</Text>
                            )}
                        </>
                    )}

                    <Box className="icon">
                        {isLoading ? (
                            <Skeleton h="20px" w="20px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                        ) : (
                            <>
                                {selected?.length && isReset ? (
                                    <ResetIcon color="var(--main-color)" size="16" onClick={onResetFilter} />
                                ) : (
                                    icon
                                )}
                            </>
                        )}
                    </Box>

                    {!isEmpty(selected) && <CircleActive styles={{ top: '-4px', right: '-3px' }} />}
                </Box>

                {/** ---------------------- FLOATING OPTIONS FILTER ---------------------------------- */}

                {isOpen && (
                    <OutsideClickHandler onOutsideClick={() => setState({ ...state, isOpen: false })}>
                        <Box className="floating-options__container">
                            {options?.map((item) => (
                                <Box className="filter-item" onClick={() => onClickMenu({ selected: item.value })}>
                                    <Text className="text">{item?.name}</Text>
                                </Box>
                            ))}
                        </Box>
                    </OutsideClickHandler>
                )}
            </Box>
        </Flex>
    );
};

export const FilterSelectSearch = ({ options, placeholder, icon, margin, isLoading, onChange, filterName, value }) => {
    const [state, setState] = useState({ isOpen: false, selected: '', query: '' });
    const { selected, isOpen } = state;

    const onClickMenu = ({ selected }) => {
        onChange({ type: filterName, value: selected });
        setState({ ...state, selected, isOpen: false, query: '' });
    };

    const onResetFilter = (e) => {
        e.stopPropagation();
        setState({ isOpen: false, selected: '', query: '' });
        onChange({ type: filterName, value: [] });
    };

    useEffect(() => {
        /* eslint-disable */
        setState({ ...state, selected: value });
    }, [value]);

    return (
        <Box className="filter-popup__container" margin={margin}>
            {/** ---------------------- BOX FILTER ---------------------------------- */}
            <Box className="box-filter" onClick={() => setState({ ...state, isOpen: true, query: '' })}>
                {isLoading ? (
                    <Skeleton h="20px" w="80px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                ) : (
                    <>
                        {selected?.length ? (
                            <Text className="selected-text">{selected}</Text>
                        ) : (
                            <Text className="placeholder">{placeholder}</Text>
                        )}
                    </>
                )}

                <Box className="icon">
                    {isLoading ? (
                        <Skeleton h="20px" w="20px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                    ) : (
                        <>
                            {selected?.length ? (
                                <ResetIcon color="var(--main-color)" size="16" onClick={onResetFilter} />
                            ) : (
                                icon
                            )}
                        </>
                    )}
                </Box>

                {selected && <CircleActive styles={{ top: '-4px', right: '-3px' }} />}
            </Box>

            {/** ---------------------- FLOATING OPTIONS FILTER ---------------------------------- */}

            {isOpen && (
                <OutsideClickHandler onOutsideClick={() => setState({ ...state, isOpen: false, query: '' })}>
                    <Box className="floating-options__container">
                        <Box className="search-input">
                            <Input
                                autoFocus
                                placeholder="search item"
                                onChange={(e) => setState({ ...state, query: e.target.value })}
                            />
                            <Search2Icon />
                        </Box>

                        {filteringDataByFuzzball({ options, value: state?.query })?.map((item) => (
                            <Box className="filter-item" onClick={() => onClickMenu({ selected: item.value })}>
                                <Text className="text">{item?.name}</Text>
                            </Box>
                        ))}
                    </Box>
                </OutsideClickHandler>
            )}
        </Box>
    );
};

export const FilterMultipleSelectSearch = ({
    options,
    placeholder,
    icon,
    margin,
    isLoading,
    onChange,
    filterName,
    value,
}) => {
    const [state, setState] = useState({ isOpen: false, selected: [], query: '' });
    const { selected, isOpen } = state;

    const onClickMenu = ({ selected }) => {
        let arr = [...state?.selected];

        if (arr?.includes(selected)) {
            arr = arr?.filter((item) => item !== selected);
        } else {
            arr = [...state?.selected, selected];
        }

        setState({ ...state, selected: arr });
        onChange({ type: filterName, value: arr });
    };

    const selectedScheduleFormatting = ({ selected }) => {
        return selected?.map((item) => get(find(options, { id: item }), 'name'))?.toString();
    };

    const onResetFilter = (e) => {
        e.stopPropagation();
        setState({ isOpen: false, selected: '', query: '' });
        onChange({ type: filterName, value: [] });
    };

    useEffect(() => {
        /* eslint-disable */
        if (value) {
            setState({ ...state, selected: value });
        }
    }, [value]);

    return (
        <Box className="filter-popup__container" margin={margin}>
            {/** ---------------------- BOX FILTER ---------------------------------- */}

            <Box className="box-filter" onClick={() => setState({ ...state, isOpen: true, query: '' })}>
                {isLoading ? (
                    <Skeleton h="20px" w="80px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                ) : (
                    <>
                        {selected?.length ? (
                            <Box maxW="100px" overflow="auto" className="selected-text__container">
                                <Text className="selected-text">{selectedScheduleFormatting({ selected })}</Text>
                            </Box>
                        ) : (
                            <Text className="placeholder">{placeholder}</Text>
                        )}
                    </>
                )}

                <Box className="icon">
                    {isLoading ? (
                        <Skeleton h="20px" w="20px" startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                    ) : (
                        <>
                            {selected?.length ? (
                                <ResetIcon color="var(--main-color)" size="16" onClick={onResetFilter} />
                            ) : (
                                icon
                            )}
                        </>
                    )}
                </Box>

                {selected?.length > 0 && <CircleActive styles={{ top: '-4px', right: '-3px' }} />}
            </Box>

            {/** ---------------------- FLOATING OPTIONS FILTER ---------------------------------- */}

            {isOpen && (
                <OutsideClickHandler onOutsideClick={() => setState({ ...state, isOpen: false, query: '' })}>
                    <Box className="floating-options__container" maxH="400px !important">
                        <Box className="search-input">
                            <Input
                                autoFocus
                                placeholder="search item"
                                onChange={(e) => setState({ ...state, query: e.target.value })}
                            />
                            <Search2Icon />
                        </Box>

                        {filteringDataByFuzzball({ options, value: state?.query })?.map((item) => (
                            <Box className="filter-item" onClick={() => onClickMenu({ selected: item.id })}>
                                <Text className="text">{item?.name}</Text>
                                {selected?.includes(item?.id) && <CheckIcon color="var(--main-color)" />}
                            </Box>
                        ))}
                    </Box>
                </OutsideClickHandler>
            )}
        </Box>
    );
};
