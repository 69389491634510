import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';

const { contentBlocks, entityMap } = htmlToDraft(`<p border="1" width="100%"><p>`);
const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

export const initialState = {
    title: '',
    startedAt: '',
    endedAt: '',
    marketPlaces: '',
    accounts: [],
    description: EditorState.createWithContent(contentState),
    isLoading: false,
};
