export const initialState = {
    scheduleType: 'Live',
    startedAt: null,
    endedAt: null,
    hostId: [],
    operatorId: [],
    studioId: '',
    reason: '',
    accountLivestreamId: [],
    ecommerce: '',
    title: 'BAU',
};
