import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ScreenTemplate from '@components/screenTemplate';
import { SelectComponent, TextAreaComponent, InputComponent } from '@components/formFields';
import { LeftArrowIcon } from '@assets/icons';
import { formattingSelectedStateHandler } from '@pages/studio/helpers/utils';

import { onSubmitStudio } from '../../helpers/crud';
import { initialState } from './const';
import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, assets, refetchStudioHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { areas, appTypes, room, name, address, isLoading } = state;

    const onClose = () => {
        mainSetState({ ...mainState, isCreatePage: false, isEditPage: false, selectedStudio: {} });
        setState(initialState);
    };
    const onChangeFormField = ({ fieldName, value }) => setState({ ...state, [fieldName]: value });

    useEffect(() => {
        /* eslint-disable */
        if (mainState?.selectedStudio?.id) {
            setState({
                ...state,
                name: mainState?.selectedStudio?.name,
                room: mainState?.selectedStudio?.room,
                address: mainState?.selectedStudio?.address,
                areas: {
                    label: mainState?.selectedStudio?.area?.name,
                    value: mainState?.selectedStudio?.area?.id,
                },
                appTypes: {
                    label: mainState?.selectedStudio?.appType?.name,
                    value: mainState?.selectedStudio?.appType?.id,
                },
            });
        }
    }, [mainState?.selectedStudio]);
    
    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__studio">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} Studio</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <InputComponent
                        label="Nama Studio"
                        placeholder="Nama Studio"
                        errorMessage="Nama Studio tidak boleh kosong!"
                        value={name}
                        onChange={onChangeFormField}
                        fieldName="name"
                    />
                    <InputComponent
                        label="Nama Ruangan"
                        placeholder="Nama Ruangan"
                        errorMessage="Nama Ruangan tidak boleh kosong!"
                        value={room}
                        onChange={onChangeFormField}
                        fieldName="room"
                    />
                    <TextAreaComponent
                        label="Alamat Studio"
                        placeholder="Alamat Studio"
                        errorMessage="Akanat Studio tidak boleh kosong!"
                        value={address}
                        onChange={onChangeFormField}
                        fieldName="address"
                    />
                    <SelectComponent
                        label="App Type"
                        placeholder="Pilih App Type"
                        errorMessage="App Type tidak boleh kosong!"
                        options={assets?.appTypes}
                        onChange={onChangeFormField}
                        fieldName="appTypes"
                        isMulti={false}
                        value={appTypes}
                    />
                    <SelectComponent
                        label="Area"
                        placeholder="Pilih Area"
                        errorMessage="Area tidak boleh kosong!"
                        value={areas}
                        options={assets?.areas} //filtering studios based selected marketplace
                        onChange={onChangeFormField}
                        isMulti={false}
                        fieldName="areas"
                    />
                </Flex>
                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className="save-button"
                        onClick={() =>
                            onSubmitStudio({
                                state,
                                dispatch,
                                setState,
                                refetchStudioHandler,
                                onClose,
                                isEdit,
                                selectedStudio: mainState?.selectedStudio,
                            })
                        }
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
