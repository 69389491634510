import { Box } from '@chakra-ui/react';
import MobileHeaderWithBack from '@components/mobileHeaderWithBack';

import './styles.scss';

const ScreenTemplateMobile = ({ children, onBack, headTitle }) => {
    return (
        <Box className="screen-template__container__mobile">
            <MobileHeaderWithBack onBack={onBack} title={headTitle} />
            {children}
        </Box>
    );
};

export default ScreenTemplateMobile;
