import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import SectionListLiveMobile from './sections/sectionListLiveMobile';
import SectionTargetMobile from './sections/sectionTargetMobile';

import { fetchTargetHandler } from './helpres/crud';
import { initialState } from './const';
import './styles.scss';

const MainDashboard = () => {
    const [target, setTarget] = useState(initialState);
    const { selectedMonth } = target;

    const onChangeTargetState = (newState) => setTarget({ ...target, ...newState });

    const refetchTargetHandler = () => fetchTargetHandler({ onChangeState: onChangeTargetState, target });

    useEffect(() => {
        /* eslint-disable */
        if (selectedMonth) {
            fetchTargetHandler({ onChangeState: onChangeTargetState, target });
        }
    }, [selectedMonth]);

    return (
        <>
            <Box className="maindashboard__container"></Box>

            <Box className="maindashboard__container__mobile">
                <SectionTargetMobile target={target} onChangeState={onChangeTargetState} />
                <SectionListLiveMobile refetchTargetHandler={refetchTargetHandler} />
            </Box>
        </>
    );
};

export default MainDashboard;
