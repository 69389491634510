import { Flex, Text, HStack } from '@chakra-ui/react';
import { ButtonActivate, ButtonAdd, ButtonCancel, ButtonSave } from '@components/button';
import { onShowingSaveConfirmationPopUp } from '../../helpers/events';

import './styles.scss';

const SectionHead = ({ setState, state, multiSelect, setMultiSelect, refetchKoinHandler, dispatch }) => {
    return (
        <Flex className="section-header__container__coin">
            {/** -------------------------- LEFT SIDE ----------------------------**/}
            <Flex className="left-side">
                <Text className="title">Koin</Text>
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}
            {multiSelect.isParamaConfirmation ? (
                <Flex className="right-side">
                    <HStack>
                        <ButtonCancel onClick={() => setMultiSelect({ coins: [], isParamaConfirmation: false })} />
                        {multiSelect?.coins?.length > 0 && (
                            <ButtonSave
                                onClick={() =>
                                    onShowingSaveConfirmationPopUp({
                                        dispatch,
                                        multiSelect,
                                        setMultiSelect,
                                        refetchKoinHandler,
                                    })
                                }
                            />
                        )}
                    </HStack>
                </Flex>
            ) : (
                <Flex className="right-side">
                    <ButtonAdd
                        textButton="Add Coin"
                        width="120px"
                        onClick={() => setState({ ...state, isCreatePage: true })}
                    />
                    <ButtonActivate
                        textButton="Confirmation"
                        onClick={() => setMultiSelect({ ...multiSelect, isParamaConfirmation: true })}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default SectionHead;
