import moment from 'moment';

export const onMappingUploadData = ({ json }) => {
    const arr = json?.map((item) => {
        return {
            userId: item['user id livestream'] || null,
            brandName: item['brand'] || null,
            targetAchievement: item['Target Sales'] >= 0 ? item['Target Sales'] : null,
            targetDuration: item['Target Durasi Jam'] >= 0 ? item['Target Durasi Jam'] : null,
            baId: Number(item['ba ID']),
            yearMonth: item['YYYY-MM'] || null,
            name: item['Nama Host'] || null,
        };
    });

    return {
        lines: arr,
    };
};

export const onMappingUploadInvalidData = ({ invalidData }) => {
    const arr = invalidData?.map((item) => {
        return {
            userId: Number(item?.userId?.value) || null,
            brandName: item?.brandName?.value || null,
            targetAchievement: item?.targetAchievement?.value >= 0 ? item?.targetAchievement?.value : null,
            targetDuration: item?.targetDuration?.value >= 0 ? item?.targetDuration?.value : null,
            baId: Number(item?.baId?.value),
            yearMonth: item?.yearMonth?.value ? moment(item?.value).format('YYYY-MM') : null,
            name: item?.name?.value || null,
        };
    });

    return {
        lines: arr,
    };
};
