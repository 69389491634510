import OutsideClickHandler from 'react-outside-click-handler';
import { Box, Flex, Text } from '@chakra-ui/react';
import { NotificationIcon, RightArrowIconBold } from '@assets/icons';
import { useEffect, useState } from 'react';
import { fetchNotificationHandler, notificationIcon } from './functions';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const Notification = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({ notifications: [] });

    const { notifications } = state;

    const notifLength = notifications?.length || 0;

    const onChangeState = (newState) => setState({ ...state, ...newState });
    const onClickHandler = ({ notification }) => {
        navigate(notification?.targetUrl);
        setIsOpen(false);
    };

    useEffect(() => {
        /* eslint-disable */

        fetchNotificationHandler({ onChangeState });
    }, []);

    return (
        <Box className="global-notification__container">
            <Box mr="4px" cursor="pointer" onClick={() => setIsOpen(!isOpen)} position="relative">
                <NotificationIcon color="black" />
                {notifLength > 0 && <Box className="notif-num">{notifLength}</Box>}
            </Box>
            {isOpen && (
                <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                    <Box className="floating-box__notification__container">
                        {notifications?.map((notification) => (
                            <Flex
                                className={`item ${notification?.isRead && 'not-active'}`}
                                onClick={() => onClickHandler({ notification })}
                            >
                                {notificationIcon({
                                    type: notification?.notificationType,
                                    color: notification?.isRead ? 'grey' : 'black',
                                })}
                                <Text className="text">{notification?.notificationMessage}</Text>
                                <Box ml="16px">
                                    <RightArrowIconBold color="var(--main-color)" />
                                </Box>
                            </Flex>
                        ))}
                    </Box>
                </OutsideClickHandler>
            )}
        </Box>
    );
};

export default Notification;
