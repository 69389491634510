export const initialState = {
    isLoading: false,
    access: [],
    accessList: [],
    options: [],
    selectedOptions: [],
    dataCount: 0,
    page: 1,
    name: '',
    id: '',
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    selectedAccess: {},
};

export const initialAssets = {
    brands: [],
    marketPlaces: [],
    parentAccounts: [],
};
