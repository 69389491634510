import { useState, createContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import SectionHead from './sections/sectionHead';
import SectionBody from './sections/sectionBody';
import { fetchScheduleHandler, fetchAllAssetHandler } from './helpers/crud';

import './styles.scss';

export const ScheduleContext = createContext();

const Schedule = () => {
    const { search } = useLocation();
    const calendarRef = useRef();

    const [state, setState] = useState({ calendarType: '3 Days' });
    const [assets, setAssets] = useState({ isLoadingAssets: true });
    const { currentEndDate, currentStartDate, brands, host, ecommerce, studio, account, operator } = state;
    const refetchScheduleHandler = () => fetchScheduleHandler({ setState, state, search });
    const onUpdateMainState = (newState) => setState({ ...state, ...newState });

    useEffect(() => {
        /* eslint-disable */

        if (currentStartDate && currentEndDate) {
            fetchScheduleHandler({ setState, state, search });
        }
    }, [currentStartDate, currentEndDate, brands, host, ecommerce, studio, account, operator]);

    useEffect(() => {
        fetchAllAssetHandler({ setAssets });
    }, []);

    console.log('schedule page');

    return (
        <ScheduleContext.Provider
            value={{
                assets,
                state,
                refetchScheduleHandler,
            }}
        >
            <Box className="schedule-container">
                <SectionHead onUpdateMainState={onUpdateMainState} mainState={state} calendarRef={calendarRef} />
                <SectionBody calendarRef={calendarRef} mainSetState={setState} mainState={state} />
            </Box>
        </ScheduleContext.Provider>
    );
};

export default Schedule;
