import { Box, Text, Flex, Table, Td, Th, Tr } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from 'react-router-dom';
import EmptyState from '@components/emptyState';
import { ResetIcon } from '@assets/icons';
import { dataManipulationComponent } from './functions';
import { searchData } from '@helpers/util';

import { ChevronRightIcon, ChevronLeftIcon } from '@assets/icons';
import './styles.scss';

const TableTemplate = ({ tableList, data, totalPage, page, handlePageClick, isDynamicTable, dataCount, totalData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const querySearch = searchData({ location, tableList });

    return (
        <Box className="table-template__container">
            <Box className="table-container" overflow={data?.length ? 'auto' : 'hidden'}>
                <Table w={isDynamicTable ? 'max-content' : '100%'}>
                    {/** --------------------------  TABLE HEAD -------------------------- */}

                    <Tr className="row-head__container">
                        {tableList?.map(
                            ({ name, width, isDivider, dataManipulationType, value, dataManipulationValue }) => (
                                <Th
                                    className={`table-head__item ${isDivider && 'divider'}`}
                                    w={width}
                                    maxWidth={width}
                                    key={name}
                                >
                                    {name}
                                    {dataManipulationComponent({
                                        dataManipulationType,
                                        value: value,
                                        dataManipulationValue,
                                    })}
                                </Th>
                            )
                        )}
                    </Tr>

                    {/* <Tr className="grey-box__wrapper">
                        {tableList?.map(({ value }) => (
                            <Td className="grey-box__container">
                                <Box className="grey-box">-</Box>
                            </Td>
                        ))}
                    </Tr> */}

                    {/** --------------------------  TABLE COLUMN-------------------------- */}
                    {data?.length > 0 &&
                        data?.map((values, index) => (
                            <Tr className="row-column__container">
                                {tableList?.map((item) => {
                                    return (
                                        <Td className={`table-column__item ${item.isDivider && 'divider'}`}>
                                            {item.component({ value: values[item.value], values, index })}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))}
                </Table>

                {data?.length === 0 && (
                    <EmptyState
                        text={'Data tidak ditemukan'}
                        description={'Harap melakukan dengan keyword atau filter lainnya.'}
                    />
                )}
            </Box>

            {/** -------------------------- PAGINATION ---------------------------- */}

            <Box className="section-pagination__container">
                <Flex>
                    <Text className="showing-text">
                        Menampilkan {dataCount} dari {totalData}{' '}
                        {querySearch?.name && (
                            <>
                                {querySearch.type} untuk <span>"{querySearch?.name}"</span>
                            </>
                        )}
                    </Text>
                    {querySearch?.name && (
                        <Box ml="4px" onClick={() => navigate()} cursor="pointer">
                            <ResetIcon color="var(--main-color)" />
                        </Box>
                    )}
                </Flex>
                <Flex className="pagination-host-menu__container">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<ChevronRightIcon />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={totalPage}
                        forcePage={page - 1}
                        previousLabel={<ChevronLeftIcon />}
                    />
                </Flex>
            </Box>
        </Box>
    );
};

export default TableTemplate;
