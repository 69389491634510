import { SearchByColumn } from "./components";

export const dataManipulationComponent = ({ dataManipulationType, valueName }) => {
    const obj = {
        search: <SearchByColumn valueName={valueName} />,
        // sort: ,
    };

    return obj[dataManipulationType];
};
