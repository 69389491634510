export const initialState = {
    isLoading: false,
    reporting: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    reportingType: 'Achievement Host',
};

export const initialAssets = {
    brands: [],
    accounts: [],
    typeAccounts: [],
    marketplaces: [],
};

export const initialFilter = {
    brand: [],
    dateFilter: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    },
};
