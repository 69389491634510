import { LockIcon } from '@assets/icons';
import { useState, useEffect } from 'react';
import { Box, Text, Button, Input, InputRightElement, InputGroup } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { ResetPasswordHandler, validateToken } from './functions';
import { initialState } from './const';
import './styles.scss';
const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    // const cookies = new Cookies();
    // const today = new Date();
    // const expirationDate = new Date(today.setMonth(today.getMonth() + 1));
    // cookies.set('userToken', token, { expires: expirationDate });

    const [loading, setLoading] = useState(true);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        async function validateAndFinish() {
            await validateToken({ token, navigate, dispatch });
            setLoading(false);
        }

        validateAndFinish();
    }, [token, navigate]);

    if (loading) {
        // buffer before rendering
        return null;
    }
    return (
        <Box className="reset-password__container">
            <Box className="white-box">
                <Text className="title">RESET PASSWORD</Text>

                {/** ---------------------- PASSWORD -------------------------- */}

                <Box className="input-container">
                    <Text className="label">Password</Text>
                    <Box className="input-field__container">
                        <Box className="icon">
                            <LockIcon color={'grey'} />
                        </Box>
                        <InputGroup>
                            <Input
                                type={state.showPassword ? 'text' : 'password'}
                                className="input-field"
                                placeholder="Type your password"
                                autoComplete="off"
                                value={state.password}
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    bg="transparent"
                                    fontSize="16px"
                                    onClick={() => setState({ ...state, showPassword: !state.showPassword })}
                                >
                                    {state.showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Box>
                </Box>

                <Box className="input-container">
                    <Text className="label">Confirm Password</Text>
                    <Box className="input-field__container">
                        <Box className="icon">
                            <LockIcon color={'grey'} />
                        </Box>
                        <Input
                            type="password"
                            className="input-field"
                            placeholder="Confirm your password"
                            autoComplete="off"
                            value={state.confirmPassword}
                            onChange={(e) => setState({ ...state, confirmPassword: e.target.value })}
                        />
                    </Box>
                </Box>

                {/** ---------------------- SUBMIT BUTTON -------------------------- */}

                <Button
                    className="submit-button"
                    isLoading={state.isLoading}
                    onClick={() => {
                        // ResetPasswordHandler({ setState, navigate, state, dispatch, cookies, token });
                        ResetPasswordHandler({ setState, navigate, state, dispatch, token });
                    }}
                >
                    RESET PASSWORD
                </Button>
            </Box>
        </Box>
    );
};

export default ResetPassword;
