import { popupHandler } from '@store/list/popup';
import { removeSingleVoucher, rejectSingleVoucherHandler, approveBulkVoucher } from './crud';

export const onShowingRemovePopUp = async ({ selectedVoucher, dispatch, refetchVoucherHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus voucher ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeSingleVoucher({
                    selectedVoucher,
                    refetchVoucherHandler,
                }),
        })
    );
};

export const onShowingResetApprovalPopUp = ({ dispatch, refetchVoucherHandler, selectedVoucher }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: `Apakah Anda Yakin Ingin Reject voucher`,
            onSubmit: () => {
                rejectSingleVoucherHandler({ dispatch, idVoucher: selectedVoucher?.id, refetchVoucherHandler });
            },
        })
    );
};

export const onCheckboxHandler = ({ selectedVoucher, onChangeState, multiSelectVoucher }) => {
    // Check if the coin is already selected
    const isSelected = multiSelectVoucher.includes(selectedVoucher.id);

    if (isSelected) {
        // If it's selected, remove it from the selected coins
        const data = multiSelectVoucher.filter((coin) => coin !== selectedVoucher.id);
        onChangeState({ multiSelectVoucher: data });
    } else {
        // If it's not selected, add it to the selected coins
        const data = multiSelectVoucher ? [...multiSelectVoucher, selectedVoucher.id] : [selectedVoucher.id];
        onChangeState({ multiSelectVoucher: data });
    }
};

export const onShowingApprovingPopUp = async ({ dispatch, refetchVoucherHandler, state }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: `Apakah Anda Yakin Ingin Approve voucher`,
            onSubmit: () => approveBulkVoucher({ dispatch, refetchVoucherHandler, state }),
        })
    );
};