import { Flex, Skeleton, Box } from "@chakra-ui/react";
import "./styles.scss";

const TableSkeleton = () => {
  return (
    <Box className="table-skeleton__container">
      {/** ----------------- SECTION HEAD -------------------- */}
      <Flex className="section-head__container__table-skeleton">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
          <Flex className="section-head__item-container">
            <Skeleton
              borderRadius="8px"
              h="20px"
              startColor="#cf9595"
              endColor="rgba(var(--main-color),0.5)"
              width="100px"
            />
          </Flex>
        ))}
      </Flex>

      {/** ----------------- SECTION COLUMN  -------------------- */}

      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, idx) => (
        <Flex key={idx} className="section-body__container__table-skeleton">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
            <Flex className="section-body__item-container">
              <Skeleton
                borderRadius="8px"
                h="20px"
                width="100px"
                startColor="#d1d1d1"
                endColor="rgba(#e3e3e3,0.8)"
              />
            </Flex>
          ))}
        </Flex>
      ))}
    </Box>
  );
};

export default TableSkeleton;
