import Axios from '@services/axios';
import { popupHandler } from '@store/list/popup';
// import Cookies from 'universal-cookie';

// export const ResetPasswordHandler = async ({ setState, navigate, state, dispatch, cookies, token }) => {
export const ResetPasswordHandler = async ({ setState, navigate, state, dispatch, token }) => {
    // Check if email or password is missing
    if (!state.password || !state.confirmPassword) {
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Please fill in both email and password fields.',
            })
        );

        setState({ ...state, isLoading: false });
        return; // Exit the function early
    } else if (state.password !== state.confirmPassword) {
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Password and Confirm Password must be same.',
            })
        );

        setState({ ...state, isLoading: false });
        return; // Exit the function early
    }

    await dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Mohon Tunggu....',
        })
    );

    try {
        const { password } = state;
        await Axios({
            method: 'POST',
            // eslint-disable-next-line no-use-before-define
            url: `/reset-password/${token}`,
            data: {
                password: password,
            },
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil melakukan reset password',
                popUpDescription: '',
            })
        );
    } catch (error) {
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Username or password is wrong. Please check your credentials.',
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
        navigate('/login');
    }
};

export const validateToken = async ({ token, navigate, dispatch }) => {
    try {
        await Axios({
            method: 'GET',
            url: `/check-token/${token}`,
        });
        // const cookies = new Cookies();
        // cookies.set('userToken', token);
    } catch (error) {
        if (error?.response?.status === 401) {
            console.log(error?.response?.status === 401);
            await dispatch(
                popupHandler({
                    popUpType: 'popup-failed',
                    popUpTitle: 'Token is expired.',
                })
            );
            navigate('/login');
        } else {
            navigate('/login');
        }
    }
};
