export const initialState = {
    title: '',
    startedAt: '',
    endedAt: '',
    marketPlaces: '',
    accounts: [],
    description: '',
    isLoading: false,
    promotionTypes: '',
};
