import { Box, Text, CircularProgress, Flex } from '@chakra-ui/react';
import './styles.scss';

const PopUpLoadingDownload = ({ description }) => {
    return (
        <Box className="popup-loading-download-global__container">
            <Flex alignItems="center">
                <Box className="box-circular-progress">
                    <CircularProgress size="21px" color="#1746A2" isIndeterminate />
                </Box>
                <Text className="text-desc-modal">{description}</Text>
            </Flex>
        </Box>
    );
};

export default PopUpLoadingDownload;
