import { Box, Modal, ModalContent, ModalOverlay, Text, Button, Flex } from '@chakra-ui/react';
import './styles.scss';
import { CloseIcon } from '@assets/icons';
import { useEffect, useState } from 'react';

const ModalMultipleDate = ({ onClose, onApply, values, minDate, maxDate }) => {
    const [state, setState] = useState({
        startedAt: new Date(),
        endedAt: new Date(),
    });

    const { startedAt, endedAt } = state;

    useEffect(() => {
        if (values?.startedAt) {
            setState({
                startedAt: values?.startedAt,
                endedAt: values?.endedAt,
            });
        }
    }, [values]);

    return (
        <Modal isOpen onClose={onClose}>
            <ModalOverlay onClick={onClose} />
            <ModalContent w="80vw">
                <Box className="modal-multiple-date__container">
                    <Flex justifyContent="flex-end" mb="16px">
                        <Box onClick={onClose}>
                            <CloseIcon color="black" />
                        </Box>
                    </Flex>
                    <Box mb="16px">
                        <Text className="label">Input Start Date:</Text>
                        <input
                            value={state?.startedAt}
                            className="date"
                            id="datePickerStartedAt"
                            type="date"
                            min={minDate}
                            onChange={(e) => setState({ ...state, startedAt: e.target.value })}
                        />
                    </Box>
                    <Box mb="16px">
                        <Text className="label">Input End Date:</Text>
                        <input
                            value={state?.endedAt}
                            className="date"
                            type="date"
                            id="datePickerEndedAt"
                            max={maxDate}
                            onChange={(e) => setState({ ...state, endedAt: e.target.value })}
                        />
                    </Box>
                    <Button
                        className="button-submit"
                        onClick={() => onApply({ startedAt, endedAt, isOpenMultipleDate: false })}
                    >
                        Apply
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalMultipleDate;
