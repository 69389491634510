//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------

import TableSkeleton from '@components/tableSkeleton';

//---------------- LOCAL COMPONENT ------------------------------------------

import SectionHead from './sections/sectionHead';
import CreateAndEditModal from './sections/sectionCreateAndEdit';
import SectionBody from './sections/sectionBody';

//---------------- UTILS ------------------------------------------


import { fetchRole } from './helpers/crud';
import { initialState } from './const';
import './styles.scss';

export const RoleContext = createContext();

const Role = () => {
    const { search } = useLocation();

    const [state, setState] = useState(initialState);
    const { isLoading, page, isEditPage } = state;

    //----------------------------------- EVENT FUNCTION -----------------------------------

    const onPagePaginationClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const refetchRoleHandler = () => fetchRole({ state, setState, search });

    //----------------------------------- USEFFECT FUNCTION -----------------------------------

    useEffect(() => {
        /* eslint-disable */
        fetchRole({ state, setState, search });
    }, [page, search]);

    return (
        <RoleContext.Provider
            value={{
                onEditColumnHandler: (selectedAccess) =>
                    setState({
                        ...state,
                        selectedAccess,
                        isEditPage: !state?.isEditPage,
                    }),
                state,
            }}
        >
            <Box className="role-management__container">
                <SectionHead setState={setState} state={state} />

                {!isLoading && <SectionBody state={state} handlePageClick={onPagePaginationClick} />}
                {isLoading && <TableSkeleton />}

                {isEditPage && (
                    <CreateAndEditModal
                        isEdit
                        mainState={state}
                        mainSetState={setState}
                        refetchRoleHandler={refetchRoleHandler}
                    />
                )}
            </Box>
        </RoleContext.Provider>
    );
};

export default Role;
