import { Text, Flex, Box, Checkbox } from '@chakra-ui/react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import { BadgeStatus } from '@components/badgeStatus';

import { PromotionContext } from '@pages/promotion';
import { onShowingResetApprovalPopUp } from '@pages/promotion/helpers/events';
import { EditIcon, RemoveIcon, ResetIcon, CommentIcon } from '@assets/icons';

export const tableList = ({ state }) => {
    const promotionTypeMap = {
        7: { color: '#0076CC', backgroundColor: '#BDE2FF' },
        8: { color: '#13AE5C', backgroundColor: '#AFF4C6' },
        9: { color: '#D47C00', backgroundColor: '#FFE8A3' },
    };

    return [
        {
            name: 'Nama',
            value: 'title',
            width: 260,
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'title',
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'Marketplace',
            width: 160,
            isSticky: true,
            value: 'accountLivestreams',
            dataManipulationType: 'search',
            dataManipulationValue: 'accountLivestreams.ecommerce.name',
            component: ({ value }) => <Text className="column-text">{value[0]?.ecommerce?.name}</Text>,
        },
        {
            name: 'Account',
            width: 300,
            value: 'accountLivestreams',
            dataManipulationType: 'search',
            dataManipulationValue: 'accountLivestreams.name',
            isSticky: true,
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        {value?.map(({ name, brands }) => (
                            <Text className="column-text">
                                {name}
                                <span>{`(${brands?.map(({ name }) => name).toString()})`}</span>
                            </Text>
                        ))}
                    </Flex>
                );
            },
        },

        {
            name: 'Date Start',
            width: 180,
            value: 'promotionLines',
            dataManipulationType: 'search',
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        {value.map((item, index) => {
                            const formattedDate = item.startedAt.slice(0, 10);
                            const options = { year: 'numeric', month: 'short', day: 'numeric' };
                            const date = new Date(formattedDate);
                            const formattedDateString = date.toLocaleDateString(undefined, options);
                            return (
                                <Text className="column-text" key={index}>
                                    {formattedDateString}
                                </Text>
                            );
                        })}
                    </Flex>
                );
            },
        },
        {
            name: 'Date End',
            width: 180,
            value: 'promotionLines',
            dataManipulationType: 'search',
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        {value.map((item, index) => {
                            const formattedDate = item.endedAt.slice(0, 10);
                            const options = { year: 'numeric', month: 'short', day: 'numeric' };
                            const date = new Date(formattedDate);
                            const formattedDateString = date.toLocaleDateString(undefined, options);
                            return (
                                <Text className="column-text" key={index}>
                                    {formattedDateString}
                                </Text>
                            );
                        })}
                    </Flex>
                );
            },
        },
        {
            name: 'Tipe Promosi',
            width: 200,
            isDivider: true,
            value: 'promotionType',
            dataManipulationType: 'search',
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        <BadgeStatus
                            title={value.name}
                            color={promotionTypeMap[value.id].color}
                            backgroundColor={promotionTypeMap[value.id].backgroundColor}
                        />
                    </Flex>
                );
            },
        },
        {
            name: 'Status Promosi',
            width: 200,
            isDivider: true,
            value: 'promotionLines',
            dataManipulationType: 'search',
            component: ({ value }) => {
                return (
                    <Flex className="account-container">
                        {value.map((item, index) => {
                            const statusMap = {
                                Mendatang: { color: '#0076CC', backgroundColor: '#BDE2FF' },
                                Berlangsung: { color: '#13AE5C', backgroundColor: '#AFF4C6' },
                                Selesai: { color: '#D47C00', backgroundColor: '#FFE8A3' },
                            };

                            const statusInfo = statusMap[item.status] || { color: '', backgroundColor: '' };

                            return (
                                <BadgeStatus
                                    title={item.status}
                                    color={statusInfo.color}
                                    backgroundColor={statusInfo.backgroundColor}
                                />
                            );
                        })}
                    </Flex>
                );
            },
        },
        {
            name: 'Konfirmasi Parama',
            width: 200,
            isDivider: true,
            value: 'isActivated',
            component: ({ value }) => {
                const konfirmasiMap = {
                    true: { title: 'Completed', color: '#13AE5C', backgroundColor: '#AFF4C6' },
                    false: { title: 'To do', color: '#F24822', backgroundColor: '#FFC7C2' },
                };

                const konfirmasiInfo = konfirmasiMap[value];

                return <BadgeStatus {...konfirmasiInfo} />;
            },
        },
        {
            name: '',
            width: 30,
            component: ({ values }) => {
                return state?.isConfirmation ? (
                    <CheckboxComponent selectedPromotion={values} multiSelectPromotions={state?.multiSelect} />
                ) : (
                    <ActionComponent selectedPromotion={values} />
                );
            },
        },
    ];
};

//---------------------------------------------- ADD ON ------------------------------

const ActionComponent = ({ selectedPromotion }) => {
    const { onEditColumnHandler, onShowingRemovePopUp, onShowingCommentPopUp } = useContext(PromotionContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedPromotion)} className="box-icon">
                <EditIcon />
            </Box>
            <Box onClick={() => onShowingRemovePopUp(selectedPromotion)} className="remove-icon">
                <RemoveIcon />
            </Box>
            <Box onClick={() => onShowingCommentPopUp(selectedPromotion)} className="comment-icon">
                <CommentIcon color={'#D47C00'} />
            </Box>
        </Flex>
    );
};

const CheckboxComponent = ({ selectedPromotion, multiSelectKoin }) => {
    const { onCheckboxHandler, refetchPromotionHandler } = useContext(PromotionContext);
    const dispatch = useDispatch();
    const isActivated = selectedPromotion?.isActivated; // is approved by parama

    const isChecked = includes(multiSelectKoin, selectedPromotion?.id);

    return (
        <div>
            {isActivated ? (
                <Box
                    onClick={() =>
                        onShowingResetApprovalPopUp({ dispatch, selectedPromotion, refetchPromotionHandler })
                    }
                >
                    <ResetIcon color="var(--main-color)" />
                </Box>
            ) : (
                <Checkbox
                    size="lg"
                    verticalAlign="middle"
                    onChange={() => onCheckboxHandler(selectedPromotion)}
                    checked={isChecked}
                    colorScheme="purple"
                    className="checkbox-field"
                />
            )}
        </div>
    );
};
