import { Box } from '@chakra-ui/react';
import './styles.scss';

const ProgressBar = ({ width = '140px', height = '10px', color }) => {
    return (
        <Box className="progress-container" width={width} height={height}>
            <Box className="bar">
                <Box className="value" bg={color}>
                    <Box className="shimmer" />
                </Box>
            </Box>
        </Box>
    );
};

export default ProgressBar;
