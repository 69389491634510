import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const fetchAllPromotion = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const response = await Axios({
            method: 'GET',
            url: `/promotion/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { promotion, totalData, totalPage } = response?.data.data;
        setState({
            ...state,
            promotions: promotion,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: promotion?.length,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, promotions: [], isLoading: false });
    }
};

export const fetchSinglePromotion = async ({ id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        await Axios({
            method: 'GET',
            url: `/promotion/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengambil promotion dengan id tersebut';
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { brands } = data?.data;

        brands = brands?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return brands || [];
    } catch (error) {
        return [];
    }
};

const fetchMarketPlaceHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/ecommerce/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { ecommerces } = data?.data;

        ecommerces = ecommerces?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return ecommerces || [];
    } catch (error) {
        return [];
    }
};

const fetchAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/account-livestream/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { accountLivestreams } = data?.data;

        accountLivestreams = accountLivestreams?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id, ecommerce: item?.ecommerce?.name };
        });

        return accountLivestreams || [];
    } catch (error) {
        return [];
    }
};

const fetchTypeHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/promotion/promotion-type/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        let res = data?.data.data;
        res = res?.map((item) => {
            return {
                label: item?.name,
                value: item?.name,
                id: item?.id,
            };
        });
        return res || [];
    } catch (error) {
        return [];
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const brands = await fetchBrandsHandler();
        const marketPlace = await fetchMarketPlaceHandler();
        const accounts = await fetchAccountHandler();
        const type = await fetchTypeHandler();

        setAsset({ brands, marketPlace, accounts, type });
    } catch (error) {
        console.log(error);
    }
};

export const fetchSingleComment = async (id) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const response = await Axios({
            method: 'GET',
            url: `/promotion/comment/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        return response.data.data;
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengambil komen id tersebut';
    }
};

export const addSingleComment = async ({ value, valueId, dispatch }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = {
        comment: value,
    };

    try {
        const response = await Axios({
            method: 'POST',
            url: `/promotion/comment/${valueId}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil menambahkan comment',
                popUpDescription: '',
            })
        );

        return response;
    } catch (error) {
        console.log(error);

        // eslint-disable-next-line no-throw-literal
        throw 'Gagal mengupload komen id tersebut';
    }
};

export const removeSinglePromotion = async ({ selectedPromotion, refetchPromotionHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        await Axios({
            method: 'DELETE',
            url: `/promotion/${selectedPromotion.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchPromotionHandler();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const approveBulkPromotion = async ({ dispatch, refetchPromotionHandler, state }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const payloads = {
            id: state?.multiSelectPromotion,
            isActivated: true,
        };

        await Axios({
            method: 'PATCH',
            url: `/promotion/approved`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });
        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil approve  promotion`,
                popUpDescription: '',
            })
        );

        await refetchPromotionHandler();
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal memodifikasi data!';
    }
};

export const rejectSinglePromotionHandler = async ({ dispatch, idPromotion, refetchPromotionHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const payloads = {
            id: [idPromotion],
            isActivated: false,
        };

        await Axios({
            method: 'PATCH',
            url: `/promotion/approved`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil Reject  promotion`,
                popUpDescription: '',
            })
        );

        await refetchPromotionHandler();
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: `Gagal Reject  promotion`,
                popUpDescription: '',
            })
        );
    }
};

export const onSubmitPromotion = async ({
    state,
    dispatch,
    setState,
    refetchPromotionHandler,
    onClose,
    isEdit,
    selectedPromotion,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = payloadFormatting({ state });

    if (state.startedAt === '' || state.endedAt === '') {
    }

    setState({ ...state, isLoading: true });

    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/promotion/${selectedPromotion?.id}` : `/promotion`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchPromotionHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil menambahkan promotion',
                popUpDescription: '',
            })
        );

        onClose();
        window.location.reload();
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        const messageDate = state.startedAt === '' || state.endedAt === '' ? 'Silahkan Isi Tanggal' : '';

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: message || `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} promotion\n ${messageDate}`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};
