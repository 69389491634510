import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { popupHandler } from '@store/list/popup';

export const fetchRole = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/access/list-role`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { access } = data?.data;

        access = access.map((item) => {
            return {
                id: item.roleId,
                label: item.name,
                value: item.hasAccess,
            };
        });

        access = access.sort((a, b) => (a.id || 0) - (b.id || 0));

        setState({ ...state, access: access, isLoading: false, isEditPage: false });

        return access || [];
    } catch (error) {
        return [];
    }
};

export const fetchAllOptions = async ({ state, setState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/access/list-menu`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { access } = data?.data;

        access = access.map((item) => {
            return {
                id: item.mainMenuId,
                label: item.name,
                value: item.name,
            };
        });

        let options = access.sort((a, b) => (a.id || 0) - (b.id || 0));

        setState({ ...state, options: options, isLoading: false });

        return options || [];
    } catch (error) {
        return [];
    }
};

export const updateRoleHandler = async ({ state, refetchRoleHandler, dispatch, onClose }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengubah role..',
            popUpDescription: '',
        })
    );

    try {
        await Axios({
            method: 'PATCH',
            url: `/access/${state.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                hasAccess: state.accessList.map((item) => item.id),
            },
        });

        await refetchRoleHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil modifikasi role',
                popUpDescription: '',
            })
        );
    } catch (error) {
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mengubah role..',
                popUpDescription: '',
            })
        );
    } finally {
        onClose();
    }
};
