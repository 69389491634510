import { useEffect, useState, createContext } from 'react';
import TableSkeleton from '@components/tableSkeleton';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';


import { fetchAllVoucher, fetchAssetHandler } from './helpers/crud';
import { onShowingRemovePopUp, onCheckboxHandler } from './helpers/events';
import SectionHead from './sections/sectionHead';
import SectionBody from './sections/sectionBody';

import CreateAndEditModal from './sections/sectionCreateAndEdit';
import { initialState, initialAssets } from './const';

import './styles.scss';
export const VoucherContext = createContext();

const Voucher = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [assets, setAsset] = useState(initialAssets);
    const { vouchers, isLoading, page, isCreatePage, isEditPage, multiSelectVoucher } = state;

    const { search } = useLocation();

    const handlePageClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const onChangeState = (newState) => setState({ ...state, ...newState });
    const refetchVoucherHandler = () => fetchAllVoucher({ state, setState, search });

    useEffect(() => {
        fetchAllVoucher({ state, setState, search });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search]);

    useEffect(() => {
        fetchAssetHandler({ assets, setAsset });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VoucherContext.Provider
            value={{
                onEditColumnHandler: (selectedProduct) =>
                    setState({ ...state, selectedProduct, isEditPage: !state?.isEditPage }),
                onShowingRemovePopUp: (selectedVoucher) =>
                    onShowingRemovePopUp({ dispatch, refetchVoucherHandler, selectedVoucher }),
                state,
                assets,
                refetchVoucherHandler,
                onCheckboxHandler: (selectedVoucher) =>
                    onCheckboxHandler({ selectedVoucher, onChangeState, multiSelectVoucher }),
            }}
        >
            <Box className="voucher__container section-body__container__voucher">
                <SectionHead
                    setState={setState}
                    state={state}
                    dispatch={dispatch}
                    refetchVoucherHandler={refetchVoucherHandler}
                />
                {!isLoading && <SectionBody state={state} handlePageClick={handlePageClick} vouchers={vouchers} />}
                {isLoading && <TableSkeleton />}
                {isCreatePage && (
                    <CreateAndEditModal
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchVoucherHandler={refetchVoucherHandler}
                    />
                )}
                {isEditPage && (
                    <CreateAndEditModal
                        isEdit
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchVoucherHandler={refetchVoucherHandler}
                    />
                )}
            </Box>
        </VoucherContext.Provider>
    );
};

export default Voucher;
