import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, Button, Text } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import ModalTemplate from '@components/modal';

import './styles.scss';

const Search = ({ placeholder, onSearchHandler, label, currentValue }) => {
    const [state, setState] = useState({});
    const { isOpenModal } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });
    const onSubmit = () => {
        onSearchHandler({ searchQuery: state?.key });
        setState({});
    };

    useEffect(() => {
        /* eslint-disable */

        if (!isEmpty) {
            onChangeState({ key: currentValue });
        }
    }, [currentValue]);

    return (
        <Box className="search-container">
            <Box className="search-icon__container" onClick={() => onChangeState({ isOpenModal: true })}>
                <SearchIcon fontSize="12px" fontWeight="bold" />
            </Box>
            {isOpenModal && (
                <ModalTemplate onClose={() => onChangeState({ isOpenModal: false })}>
                    <Box className="modal-search-box__container__mobile">
                        <Text className="label">{label}</Text>
                        <input
                            className="search-input"
                            placeholder={placeholder}
                            onChange={(e) => onChangeState({ key: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSubmit();
                                }
                            }}
                            value={state?.key}
                        />
                        <Button className="button-submit" onClick={onSubmit}>
                            Cari
                        </Button>
                    </Box>
                </ModalTemplate>
            )}
        </Box>
    );
};

export default Search;
