import { Skeleton, Flex, Box, Input } from '@chakra-ui/react';

export const DetailInformationSkeleton = () => (
    <Box p="16px" mb="4px" borderBottom="1px solid #dfdfdf">
        <Skeleton h="16px" w="100px" mb="16px" />
        <Flex justifyContent="space-between" mb="8px">
            <Skeleton h="22px" w="150px" startColor="var(--main-color)" endColor="rgba(var(--main-color),0.5)" />
            <Flex>
                <Skeleton h="22px" w="40px" startColor="#4cbfbf" endColor="rgba(#4cbfbf,0.5)" mr="4px" />
                <Skeleton h="22px" w="80px" startColor="var(--main-color)" endColor="rgba(var(--main-color),0.5)" />
            </Flex>
        </Flex>

        <Skeleton w="200px" h="14px" mb="4px" />
    </Box>
);

export const FormReportLiveInformationSkeleton = () => (
    <Box p="16px" mb="4px" borderBottom="1px solid #dfdfdf">
        <Skeleton h="16px" w="140px" mb="16px" />
        <Flex justifyContent="space-between" mb="16px">
            <Skeleton h="22px" w="100%" startColor="var(--main-color)" endColor="rgba(var(--main-color),0.5)" />
        </Flex>
        <Box mb="16px">
            <Skeleton w="80px" h="20px" mb="6px" />
            <Input h="32px" />
        </Box>
        <Box mb="16px">
            <Skeleton w="80px" h="20px" mb="6px" />
            <Input h="32px" />
        </Box>
        <Box mb="16px">
            <Skeleton w="80px" h="20px" mb="6px" />
            <Input h="32px" />
        </Box>
        <Box mb="16px">
            <Skeleton w="80px" h="20px" mb="6px" />
            <Input h="32px" />
        </Box>
        <Box mb="16px">
            <Skeleton w="80px" h="20px" mb="6px" />
            <Input h="32px" />
        </Box>
        <Skeleton w="100%" h="36px" mb="6px" startColor="var(--main-color)" endColor="rgba(var(--main-color),0.5)" />
    </Box>
);

export const AdditionalInformationSkeleton = () => (
    <Box p="16px" mb="4px" borderBottom="1px solid #dfdfdf">
        <Flex justifyContent="space-between" mb="16px">
            <Skeleton h="16px" w="140px" mb="16px" />
            <Skeleton h="16px" w="80px" startColor="var(--main-color)" endColor="rgba(var(--main-color),0.5)" />
        </Flex>
        <Box mb="24px" borderBottom="1px solid #dfdfdf" pb="16px">
            <Skeleton
                h="16px"
                w="100px"
                mb="16px"
                startColor="var(--main-color)"
                endColor="rgba(var(--main-color),0.5)"
            />
            <Skeleton h="14px" w="90px" mb="16px" ml="16px" startColor="" endColor="rgba(#4cbfbf,0.5)" />
            <Box ml="32px">
                <Skeleton h="12px" w="160px" mb="8px" />
                <Skeleton h="12px" w="120px" mb="8px" startColor="#4cbfbf" endColor="rgba(#4cbfbf,0.5)" />
                <Skeleton h="12px" w="160px" mb="8px" />
            </Box>
        </Box>
        <Box mb="24px" borderBottom="1px solid #dfdfdf" pb="16px">
            <Skeleton
                h="16px"
                w="100px"
                mb="16px"
                startColor="var(--main-color)"
                endColor="rgba(var(--main-color),0.5)"
            />
            <Skeleton h="14px" w="90px" mb="16px" ml="16px" />
            <Box ml="32px">
                <Skeleton h="12px" w="160px" mb="8px" />
                <Skeleton h="12px" w="120px" mb="8px" startColor="#4cbfbf" endColor="rgba(#4cbfbf,0.5)" />
                <Skeleton h="12px" w="160px" mb="8px" />
            </Box>
        </Box>
        <Box mb="24px" borderBottom="1px solid #dfdfdf" pb="16px">
            <Skeleton
                h="16px"
                w="100px"
                mb="16px"
                startColor="var(--main-color)"
                endColor="rgba(var(--main-color),0.5)"
            />
            <Skeleton h="14px" w="90px" mb="16px" ml="16px" />
            <Box ml="32px">
                <Skeleton h="12px" w="160px" mb="8px" />
                <Skeleton h="12px" w="120px" mb="8px" startColor="#4cbfbf" endColor="rgba(#4cbfbf,0.5)" />
                <Skeleton h="12px" w="160px" mb="8px" />
            </Box>
        </Box>
        <Box mb="24px" borderBottom="1px solid #dfdfdf" pb="16px">
            <Skeleton
                h="16px"
                w="100px"
                mb="16px"
                startColor="var(--main-color)"
                endColor="rgba(var(--main-color),0.5)"
            />
            <Skeleton h="14px" w="90px" mb="16px" ml="16px" />
            <Box ml="32px">
                <Skeleton h="12px" w="160px" mb="8px" />
                <Skeleton h="12px" w="120px" mb="8px" startColor="#4cbfbf" endColor="rgba(#4cbfbf,0.5)" />
                <Skeleton h="12px" w="160px" mb="8px" />
            </Box>
        </Box>
    </Box>
);