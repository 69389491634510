import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { onMappingUploadInvalidData } from './utils';
import { popupHandler } from '@store/list/popup';

export const fetchTargetUpload = async ({ onChangeState, search, state }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    onChangeState({
        isLoading: true,
    });

    const filterStringify = queryString.stringify(
        {
            ...queries,
            limit: 10,
            page: state?.page,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/target/list?${filterStringify}&month=`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({
            targets: data?.data?.targets,
            totalData: data?.data?.totalData,
            totalPage: data?.data?.totalPage,
            dataCount: data?.data?.targets?.length,
            isOpenUpload: false,
            isLoading: false,
            isOpenEditRowTarget: false,
            selectedTarget: null,
        });
    } catch (err) {
        console.log(err);
        onChangeState({
            isLoading: false,
        });
    }
};

export const onSubmitInvalidData = async ({ state, onChangeState, dispatch, refetchTargetUpload }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const payload = onMappingUploadInvalidData({ invalidData: state?.invalidData });

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengirim data',
        })
    );

    try {
        const response = await Axios({
            method: 'POST',
            url: '/target/import',
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });

        const invalidData = response?.data?.data?.invalidData;
        const successDataLength = payload?.lines?.length - invalidData?.length;
        const invalidaDataLength = invalidData?.length;

        if (invalidData?.length) {
            onChangeState({ uploadState: 'invalid-data', isDragging: false, invalidData });
            dispatch(
                popupHandler({
                    popUpType: 'popup-error',
                    popUpTitle: 'Warning',
                    popUpDescription: `${successDataLength} data berhasil terupload, ${invalidaDataLength} data gagal terupload`,
                })
            );
        } else {
            onChangeState({ uploadState: '', isDragging: false });
            refetchTargetUpload();

            dispatch(
                popupHandler({
                    popUpType: 'popup-success',
                    popUpTitle: 'Berhasil mengupload file',
                })
            );
        }
    } catch (err) {
        console.log(err);

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
            })
        );
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const brands = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return brands?.data?.data?.brands;
    } catch (error) {
        return [];
    }
};

export const fetchAssetsHandler = async ({ onChangeAssets }) => {
    try {
        const promises = [fetchBrandsHandler()];

        const [brands] = await Promise.all(promises);

        onChangeAssets({
            brands,
        });
    } catch (error) {
        console.error(error);
    }
};

export const onRemoveTarget = async ({ selectedTarget, refetchTargetUpload }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'DELETE',
            url: `/target/${selectedTarget?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchTargetUpload();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const onUpdateRowTarget = async ({ selectedTarget, refetchTargetUpload }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'PATCH',
            url: `/target/${selectedTarget?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                hours: Number(selectedTarget?.hours),
                revenue: Number(selectedTarget?.revenue),
            },
        });

        return {
            title: 'Berhasil mengupdate data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal mengupdate data!';
    } finally {
        await refetchTargetUpload();
    }
};
