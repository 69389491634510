import { SearchByColumn, SortByColumn } from './components';

export const dataManipulationComponent = ({ dataManipulationType, dataManipulationValue }) => {
    const obj = {
        search: <SearchByColumn dataManipulationValue={dataManipulationValue} />,
        sort: <SortByColumn dataManipulationValue={dataManipulationValue} />,
    };

    return obj[dataManipulationType];
};
