export const initialState = {
    isLoading: false,
    products: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
    selectedProduct: {},
};

export const initialAssets = {
    brands: [],
    marketPlaces: [],
    accounts: [],
};