import { Box, Flex, Text, Button, Spacer, HStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import ScreenTemplate from '@components/screenTemplate';
import {
    DateInputComponent,
    InputComponent,
    InputComment,
    SelectComponent,
    RichTextInputComponent,
} from '@components/formFields';
import { LeftArrowIcon } from '@assets/icons';
import { formattingSelectedStateHandler } from '@pages/productHighlight/helpers/utils';

import { onSubmitProduct, fetchSingleComment } from '../../helpers/crud';
import { formattedDate } from '@pages/voucher/helpers/utils';
import { initialState } from './const';

import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, assets, refetchVoucherHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [inputComments, setInputComments] = useState('');
    const [comments, setComments] = useState([]);
    const [renderAccount, setRenderAccount] = useState(false);
    const { title, startedAt, endedAt, isLoading, accounts, description, marketPlaces } = state;

    const onClose = () => mainSetState({ ...mainState, isCreatePage: false, isEditPage: false, selectedProduct: {} });
    const onChangeFormField = ({ fieldName, value }) => {
        setState({ ...state, [fieldName]: value });
        if (fieldName === 'marketPlaces') {
            setRenderAccount(true);
        }
    };

    const handleFetchComments = async (id) => {
        try {
            const { comments } = await fetchSingleComment(id);

            setComments(comments);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    useEffect(() => {
        /* eslint-disable */
        if (mainState?.selectedProduct?.id) {
            const { contentBlocks, entityMap } = htmlToDraft(
                draftToHtml(JSON.parse(mainState?.selectedProduct?.description))
            );
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            let obj = {
                ...state,
                ...mainState?.selectedProduct,
                accounts: formattingSelectedStateHandler({
                    arr: mainState?.selectedProduct?.accountLivestreams,
                    type: 'accounts',
                }),
                marketPlaces: formattingSelectedStateHandler({
                    arr: mainState?.selectedProduct?.accountLivestreams,
                    type: 'marketPlaces',
                }),
                description: EditorState.createWithContent(contentState),
            };
            setState(obj);
            handleFetchComments(obj.id);
        }
    }, [mainState?.selectedProduct?.id]);

    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__voucher">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} Voucher</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <Flex minWidth="max-content" alignItems="start" gap="2">
                        <Box>
                            <InputComponent
                                label="Title"
                                placeholder="Masukan Title"
                                errorMessage="Title tidak boleh kosong!"
                                type="text"
                                fieldName="title"
                                value={title}
                                onChange={onChangeFormField}
                            />
                            <Flex w="100%" maxWidth="500px" gap="16px">
                                <DateInputComponent
                                    label="Periode"
                                    value={startedAt}
                                    onChange={onChangeFormField}
                                    fieldName="startedAt"
                                />
                                <DateInputComponent
                                    label=""
                                    value={endedAt}
                                    onChange={onChangeFormField}
                                    fieldName="endedAt"
                                />
                            </Flex>

                            <SelectComponent
                                label="Marketplace"
                                placeholder="Pilih marketplace"
                                errorMessage="Marketplace tidak boleh kosong!"
                                options={assets?.marketPlace}
                                onChange={onChangeFormField}
                                fieldName="marketPlaces"
                                isMulti={false}
                                value={marketPlaces}
                            />

                            <SelectComponent
                                label="Account"
                                placeholder="Pilih account"
                                errorMessage="Account tidak boleh kosong!"
                                value={accounts}
                                options={[...assets?.accounts]?.filter(
                                    (account) => account.ecommerce === marketPlaces.label
                                )} //filtering accounts based selected marketplace
                                onChange={onChangeFormField}
                                fieldName="accounts"
                            />

                            <RichTextInputComponent
                                label="Deskripsi"
                                placeholder="Masukan deskripsi"
                                value={description}
                                onChange={onChangeFormField}
                                fieldName="description"
                                errorMessage="Deskripsi tidak boleh kosong!"
                            />

                            <Flex className="footer">
                                <Button className="cancel-button" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={isLoading}
                                    className="save-button"
                                    onClick={() =>
                                        onSubmitProduct({
                                            state,
                                            dispatch,
                                            setState,
                                            refetchVoucherHandler,
                                            onClose,
                                            isEdit,
                                            selectedProduct: mainState?.selectedProduct,
                                        })
                                    }
                                >
                                    Save
                                </Button>
                            </Flex>
                        </Box>
                        <Spacer />
                        {isEdit && (
                            <Box>
                                <InputComment
                                    label="Post a Comment"
                                    placeholder="Write your comment here"
                                    errorMessage="Comment tidak boleh kosong!"
                                    value={inputComments}
                                    dispatch={dispatch}
                                    valueId={mainState.selectedProduct.id}
                                    onChange={(e) => setInputComments(e.value)}
                                    setComments={setComments}
                                />
                                <Text fontWeight={'bold'} mb={5}>
                                    Comments
                                </Text>
                                {comments.map((item) => (
                                    <Box mt={2}>
                                        <HStack spacing={1} alignItems="baseline">
                                            <Text fontWeight={'semibold'} fontSize={'medium'}>
                                                {item.user.name}
                                            </Text>
                                            {/* <Divider orientation="vertical" borderColor="gray.300" h="12px" /> */}
                                            <Spacer />
                                            <Text fontWeight={'medium'} fontSize={'small'}>
                                                {formattedDate(item.createdAt)}
                                            </Text>
                                        </HStack>
                                        <Text fontWeight={'medium'} fontSize={'small'}>
                                            {item.comment}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
