import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const cookiesLogin = cookies.get('userToken');

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        isLogin: !!cookiesLogin,
    },
    reducers: {
        toggleLoginHandler: (state, action) => {
            state.isLogin = action.payload;
        },
    },
});

export const { toggleLoginHandler } = loginSlice.actions;

export default loginSlice.reducer;
