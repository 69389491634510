export const restrictPageHandler = ({ isLogin, pathname, navigate }) => {
    if (!isLogin) {
        if (!pathname.startsWith('/reset-password') && pathname !== '/login') {
            navigate('/login');
        } else if (pathname === '/login') {
            navigate('/login');
        }
    } else if (isLogin && pathname === '/login') {
        navigate('/');
    } else if (!isLogin && pathname.startsWith('/reset-password')) {
        // pass to reset password
    }
};


export const isHideMobileHeaderHandler = ({ pathname, isMobile }) => {
    if (isMobile) {
        const hideList = ['/schedule'];
        return hideList.includes(pathname);
    }
};
