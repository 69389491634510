//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

//---------------- GLOBAL COMPONENT ------------------------------------------

import TableSkeleton from '@components/tableSkeleton';

//---------------- LOCAL COMPONENT ------------------------------------------

import SectionHead from './sections/sectionHead';
import SectionBody from './sections/sectionBody';

//---------------- UTILS ------------------------------------------

import { fetchReportingAchievement } from './helpers/crud';
import { initialAssets, initialFilter, initialState } from './const';
import './styles.scss';

export const ReportingAchievementContext = createContext();

const ReportingAchievement = () => {
    const { search } = useLocation();

    const [state, setState] = useState(initialState);
    const [assets, setAssets] = useState(initialAssets);
    const [filter, setFilter] = useState(initialFilter);
    const { isLoading, page, reportingType } = state;

    //----------------------------------- EVENT FUNCTION -----------------------------------

    const onPagePaginationClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const onChangeState = (newState) => setState({ ...state, ...newState });
    const onChangeAssets = (newState) => setAssets({ ...state, ...newState });
    const onChangeFilter = (newState) => setFilter({ ...filter, ...newState });
    const resetFilter = () => setFilter(initialFilter);

    //----------------------------------- USEFFECT FUNCTION -----------------------------------

    useEffect(() => {
        /* eslint-disable */
        fetchReportingAchievement({ state, setState, filter, search });
    }, [page, filter, search, reportingType]);

    return (
        <ReportingAchievementContext.Provider
            value={{
                state,
            }}
        >
            <Box className="reporting-achievement__container">
                <SectionHead
                    setState={setState}
                    state={state}
                    assets={assets}
                    onChangeAssets={onChangeAssets}
                    setAssets={setAssets}
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                />

                <SectionBody
                    state={state}
                    onChangeState={onChangeState}
                    handlePageClick={onPagePaginationClick}
                    resetFilter={resetFilter}
                    filter={filter}
                />
                {isLoading && <TableSkeleton />}
            </Box>
        </ReportingAchievementContext.Provider>
    );
};

export default ReportingAchievement;
