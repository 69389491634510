import { Flex, Box, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import './styles.scss';

const MobileHeaderWithBack = ({ onBack, title }) => {
    return (
        <Flex className="mobile-header-with-back__container">
            <Box onClick={onBack}>
                <ArrowBackIcon fontSize={20} color="var(--main-color)" />
            </Box>
            <Text className="title">{title}</Text>
        </Flex>
    );
};

export default MobileHeaderWithBack;
