export const initialState = {
    brands: '',
    marketPlaces: '',
    name: '',
    accountType: '',
    accessList: [],
    options: [],
    parentAccount: '',
    isLoading: false,
};

export const accountTypes = [
    { label: 'Official', value: '1' },
    { label: 'Marketing', value: '2' },
    { label: 'Affiliate', value: '3' },
];
