import { useEffect, useState } from 'react';
import { fetchImageHandler } from './functions';
import { Skeleton } from '@chakra-ui/react';

const ImageS3 = ({ fileId, fileUrl }) => {
    const [state, setState] = useState({ isLoading: true, imageSource: '' });
    const { isLoading, source } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    useEffect(() => {
        /* eslint-disable */

        if (fileUrl) {
            onChangeState({ source: fileUrl });
        } else {
            fetchImageHandler({ fileId, onChangeState });
        }
    }, [fileId]);

    return <>{isLoading ? <Skeleton h="100%" w="100%" /> : <img src={source} />}</>;
};

export default ImageS3;
