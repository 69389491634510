import { createSlice } from '@reduxjs/toolkit';

export const isMobileSlice = createSlice({
    name: 'isMobile',
    initialState: {
        isMobile: false,
    },
    reducers: {
        checkIsMobileHandler: (state) => {
            /* eslint-disable */

            let isMobile = false;

            const width = window.innerWidth;
            if (width < 768) {
                isMobile = true;
            }

            state.isMobile = isMobile;
        },
    },
});

export const { checkIsMobileHandler } = isMobileSlice.actions;

export default isMobileSlice.reducer;
