import { Text, Flex, Box } from '@chakra-ui/react';
import { EditIcon } from '@assets/icons';
import { useContext } from 'react';
import { RoleContext } from '@pages/roleManagement';

import { List, SimpleGrid, ListItem, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react';

const ActionComponent = ({ selectedAccess }) => {
    const { onEditColumnHandler } = useContext(RoleContext);
    return (
        <Flex className="action-container">
            <Box onClick={() => onEditColumnHandler(selectedAccess)} className="box-icon">
                <EditIcon />
            </Box>
        </Flex>
    );
};

const generateItem = (item) => {
    let res = [];
    for (let i = 0; i < item.length; i += 3) {
        let temp = [];
        for (let j = 0; j < 3; j++) {
            if (item[i + j]) {
                temp.push(item[i + j].name);
            }
        }
        res.push(generateItemBy3(temp));
    }
    return res;
};

const generateItemBy3 = (item) => {
    return item.map((elem, index) => (
        <UnorderedList>
            <ListItem key={item[index]}>{item[index]}</ListItem>
        </UnorderedList>
    ));
};

export const tableList = [
    {
        name: 'ID',
        width: '20px',
        value: 'id',
        dataManipulationValue: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Name',
        width: 200,
        value: 'label',
        dataManipulationValue: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Access List',
        width: 500,
        value: 'value',
        dataManipulationValue: 'name',
        component: ({ value }) => {
            return (
                <Flex className="account-container__role-management">
                    <SimpleGrid columns={3} spacingX={10}>
                        {generateItem(value)}
                    </SimpleGrid>
                </Flex>
            );
        },
    },
    {
        name: 'Action',
        width: '40px',
        component: ({ values }) => <ActionComponent selectedAccess={values} />,
    },
];
