import Cookies from 'universal-cookie';
import Axios from '@services/axios';

export const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const brands = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { data } = brands?.data?.data;

        data = data?.map((item) => {
            return { label: item.name, value: item?.name };
        });

        return data || [];
    } catch (error) {
        return [];
    }
};

export const fetchRoleHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        let role = await Axios({
            method: 'GET',
            url: `/role/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        role = role?.data?.data?.role;

        role = role?.map((item) => {
            return { label: item.name, value: item?.name };
        });

        return role || [];
    } catch (error) {
        return [];
    }
};

export const fetchFilterOptionsHandler = async ({ setFilterOptions }) => {
    const brands = await fetchBrandsHandler();
    const role = await fetchRoleHandler();

    setFilterOptions([
        {
            label: 'Jenis Kelamin',
            type: 'dropdown',
            filterName: 'gender',
            options: [
                {
                    value: 'Laki-Laki',
                    name: 'Laki-Laki',
                },
                {
                    value: 'Perempuan',
                    name: 'Perempuan',
                },
            ],
        },
        {
            label: 'Brand',
            filterName: 'brand.name',
            type: 'multipleSelect',

            options: [...brands],
        },
        {
            label: 'Role',
            filterName: 'role.name',
            type: 'multipleSelect',

            options: [...role],
        },
    ]);
};
