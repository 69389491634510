import Cookies from 'universal-cookie';
import { toggleLoginHandler } from '@store/list/login';

export const isActivePageHandler = ({ path, page, children }) => {
    let result = false;
    // ----------------- checking similarity between page with path, or with children ----------
    if (page === path) result = true;

    children?.forEach((child) => {
        if (child.path === page) result = true;
    });

    return result;
};

export const changeNavigationHandler = ({ navigate, link }) => {
    navigate(link);
};

export const onLogout = ({ dispatch, navigate }) => {
    const cookies = new Cookies();
    cookies.remove('userToken');
    dispatch(toggleLoginHandler(false));
    navigate('/login');
};

export const getRoutes = ({ routeList }) => {
    const filterResult = routeList?.filter(({ isSideMenu }) => isSideMenu);
    return filterResult;
};

export const onClickSideMenu = ({ children, navigate, path, state, setState }) => {
    if (children?.length) {
        const firstChildPath = children[0]?.path;
        setState({ isOpenChildren: !state?.isOpenChildren });
        navigate(firstChildPath);

        return;
    }

    navigate(path);
};
