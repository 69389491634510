import { removeKoinHandler, approveKoinHandler, disapproveSingleKoinHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingRemovePopUp = async ({ selectedKoin, dispatch, refetchKoinHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus koin ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeKoinHandler({
                    selectedKoin,
                    refetchKoinHandler,
                }),
        })
    );
};

// Function to handle the confirmation action
export const onShowingSaveConfirmationPopUp = ({ dispatch, multiSelect, setMultiSelect, refetchKoinHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: 'Apakah anda yakin ingin melakukan approve pada koin ini?',
            popUpDescription: '',
            onSubmit: () => approveKoinHandler({ multiSelect, setMultiSelect, refetchKoinHandler, dispatch }),
        })
    );
};

export const onCheckboxHandler = ({ selectedKoin, multiSelect, setMultiSelect }) => {
    // Check if the coin is already selected
    const isCoinSelected = multiSelect?.coins.includes(selectedKoin.id);

    if (isCoinSelected) {
        // If it's selected, remove it from the selected coins
        const updatedCoins = multiSelect?.coins.filter((coin) => coin !== selectedKoin.id);
        setMultiSelect({ ...multiSelect, coins: updatedCoins });
    } else {
        // If it's not selected, add it to the selected coins
        const updatedCoins = multiSelect ? [...multiSelect.coins, selectedKoin.id] : [selectedKoin.id];
        setMultiSelect({ ...multiSelect, coins: updatedCoins });
    }
};

export const onShowingRejectApprovalPopUp = ({ dispatch, selectedKoin, refetchKoinHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus approve koin ini?',
            popUpDescription: '',
            onSubmit: () => {
                // Call the disapproveSingleKoinHandler when confirmed
                disapproveSingleKoinHandler({ selectedKoin, dispatch, refetchKoinHandler });
            },
        })
    );
};
