import { Box } from "@chakra-ui/react";
import TableTemplate from "@components/tableTemplate";
import { tableList } from "./const";

import "./styles.scss";

const SectionBody = ({ state, handlePageClick }) => {
  const { users, dataCount, totalPage, totalData, page } = state;
  return (
      <Box className="section-body__container__usermanagement">
          <TableTemplate
              tableList={tableList}
              data={users}
              dataCount={dataCount}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              handlePageClick={handlePageClick}
              isDynamicTable
          />
      </Box>
  );
};

export default SectionBody;
