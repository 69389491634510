import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ScreenTemplate from '@components/screenTemplate';
import { DateInputComponent, InputComponent, RichTextInputComponent, SelectComponent } from '@components/formFields';
import { LeftArrowIcon } from '@assets/icons';
import draftToHtml from 'draftjs-to-html';
import { ContentState, EditorState } from 'draft-js';

import { formattingSelectedStateHandler } from '@pages/productHighlight/helpers/utils';
import htmlToDraft from 'html-to-draftjs';

import { onSubmitProduct } from '../../helpers/crud';
import { initialState } from './const';
import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, assets, refetchProductHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { title, startedAt, endedAt, isLoading, accounts, description, marketPlaces } = state;

    const onClose = () => mainSetState({ ...mainState, isCreatePage: false, isEditPage: false, selectedProduct: {} });
    const onChangeFormField = ({ fieldName, value }) => setState({ ...state, [fieldName]: value });

    useEffect(() => {
        /* eslint-disable */
        if (mainState?.selectedProduct?.id) {
            const { contentBlocks, entityMap } = htmlToDraft(
                draftToHtml(JSON.parse(mainState?.selectedProduct?.description))
            );
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            setState({
                ...state,
                ...mainState?.selectedProduct,
                accounts: formattingSelectedStateHandler({
                    arr: mainState?.selectedProduct?.accountLivestreams,
                    type: 'accounts',
                }),
                description: EditorState.createWithContent(contentState),
            });
        }
    }, [mainState?.selectedProduct]);

    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__product-highlight">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} Product Highlight</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <InputComponent
                        label="Title"
                        placeholder="Masukan Title"
                        errorMessage="Title tidak boleh kosong!"
                        type="text"
                        fieldName="title"
                        value={title}
                        onChange={onChangeFormField}
                    />
                    <Flex w="100%" maxWidth="500px" gap="16px">
                        <DateInputComponent
                            label="Periode"
                            value={startedAt}
                            onChange={onChangeFormField}
                            fieldName="startedAt"
                        />
                        <DateInputComponent label="" value={endedAt} onChange={onChangeFormField} fieldName="endedAt" />
                    </Flex>

                    <SelectComponent
                        label="MarketPlace"
                        placeholder="Pilih ecommerce"
                        errorMessage="Ecommerce tidak boleh kosong!"
                        value={marketPlaces}
                        options={[...assets?.marketPlace]}
                        onChange={onChangeFormField}
                        fieldName="marketPlaces"
                        isMulti={false}
                    />

                    <SelectComponent
                        label="Account"
                        placeholder="Pilih account"
                        errorMessage="Account tidak boleh kosong!"
                        value={accounts}
                        options={[...assets?.accounts]?.filter((account) => account.ecommerce === marketPlaces?.label)} //filtering accounts based selected marketplace
                        onChange={onChangeFormField}
                        fieldName="accounts"
                    />

                    <RichTextInputComponent
                        label="Deskripsi"
                        placeholder="Masukan deskripsi"
                        value={description}
                        onChange={onChangeFormField}
                        fieldName="description"
                        errorMessage="Deskripsi tidak boleh kosong!"
                    />
                </Flex>
                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className="save-button"
                        onClick={() =>
                            onSubmitProduct({
                                state,
                                dispatch,
                                setState,
                                refetchProductHandler,
                                onClose,
                                isEdit,
                                selectedProduct: mainState?.selectedProduct,
                            })
                        }
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
