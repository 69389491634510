import { Box } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import moment from 'moment';

import TableTemplateMobile from '@components/tableTemplateMobile';
import { getTableListHandler } from '@pages/mainDashboard/helpres/utils';
import TableTemplateMobileSkeleton from '@components/tableTemplateSkeletonMobile';
import ScreenTemplateMobile from '@components/screenTemplateMobile';

import SectionSearchAndFilter from './components/sectionSearchAndFilter';
import { fetchListLiveMobile } from '../../helpres/crud';
import { initialState, initialStateOthers } from './const';
import './styles.scss';

const AllLiveListScreen = ({ onChangeMainState, selectedTableType, selectedHeadTitle, onClickRow }) => {
    const [state, setState] = useState({
        ...initialState,
        ...initialStateOthers[selectedTableType],
    });

    const { isLoading, data, startedAt, endedAt, searchQuery, isFinish, minDate, maxDate } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    useEffect(() => {
        /* eslint-disable */

        fetchListLiveMobile({
            onChangeState,
            queries: {
                isFinish,
                startedAt: `gte.${moment(startedAt).utc().toISOString()}`,
                endedAt: `lte.${moment(endedAt).add(1, 'days').utc().toISOString()}`,
                searchparam: `title.ilike.${searchQuery}`,
            },
        });
    }, [selectedTableType, startedAt, endedAt, searchQuery]);

    return (
        <ScreenTemplateMobile onBack={() => onChangeMainState({ isOpenShowMore: false })} headTitle={selectedHeadTitle}>
            <Box className="live-list-screen__container__maindashboard">
                {/** //----------------------------------------- FILTER MONTH ------------------------------* */}
                <SectionSearchAndFilter
                    state={state}
                    onChangeState={onChangeState}
                    maxDate={maxDate}
                    minDate={minDate}
                />
                {/** ----------------------------------- TABLE ---------------------------------------------- */}
                {isLoading ? (
                    <TableTemplateMobileSkeleton />
                ) : (
                    <TableTemplateMobile
                        tableList={getTableListHandler({ selectedTableType })}
                        data={data?.schedules}
                        isDynamicTable={true}
                        usePagination={false}
                        onClickRow={(value) =>
                            onClickRow({
                                value,
                            })
                        }
                    />
                )}
            </Box>
        </ScreenTemplateMobile>
    );
};

export default AllLiveListScreen;
