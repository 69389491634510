export const EmptyStateIcon = () => (
  <svg
    width="104"
    height="100"
    viewBox="0 0 104 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_948_15464)">
      <path
        d="M65.8163 22.4898H38.2074C37.5782 22.4905 36.975 22.7408 36.5301 23.1856C36.0853 23.6304 35.835 24.2335 35.8342 24.8625V86.2143L35.5178 86.3108L28.7449 88.3846C28.4239 88.4825 28.0772 88.449 27.7809 88.2915C27.4846 88.134 27.2629 87.8654 27.1644 87.5447L7.01812 21.7479C6.92008 21.4269 6.95349 21.0802 7.11102 20.7839C7.26855 20.4876 7.5373 20.2659 7.85821 20.1676L18.2953 16.9723L48.5526 7.71217L58.9896 4.51685C59.1484 4.46797 59.3154 4.45089 59.4808 4.46659C59.6462 4.48228 59.807 4.53044 59.9538 4.60832C60.1006 4.68619 60.2306 4.79224 60.3363 4.9204C60.4421 5.04857 60.5215 5.19633 60.5701 5.35523L65.7198 22.1734L65.8163 22.4898Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.84 22.1735L65.6335 1.90374C65.5302 1.56601 65.3614 1.25193 65.1367 0.979455C64.912 0.706978 64.6358 0.481445 64.3239 0.31574C64.012 0.150035 63.6704 0.0474058 63.3188 0.0137207C62.9672 -0.0199645 62.6124 0.0159545 62.2747 0.119421L47.6009 4.61027L17.3451 13.872L2.6713 18.3644C1.98963 18.5737 1.41886 19.0448 1.08427 19.6745C0.74968 20.3041 0.678608 21.0407 0.886658 21.7227L22.0993 90.9963C22.2683 91.5468 22.6093 92.0286 23.0723 92.3711C23.5353 92.7136 24.0959 92.8987 24.6718 92.8993C24.9383 92.8994 25.2034 92.8594 25.458 92.7807L35.5169 89.7024L35.8333 89.6043V89.2737L35.5169 89.3702L25.3647 92.4785C24.763 92.6619 24.1131 92.5992 23.5576 92.3041C23.0021 92.0091 22.5863 91.5057 22.4015 90.9046L1.19048 21.6293C1.09889 21.3315 1.06697 21.0184 1.09655 20.7082C1.12612 20.398 1.2166 20.0966 1.36282 19.8214C1.50904 19.5462 1.70813 19.3026 1.94867 19.1044C2.1892 18.9062 2.46648 18.7574 2.76461 18.6665L17.4384 14.1741L47.6943 4.91399L62.3681 0.421557C62.5942 0.352556 62.8293 0.317375 63.0658 0.317154C63.5732 0.318293 64.0669 0.481849 64.4745 0.783865C64.8822 1.08588 65.1825 1.51049 65.3314 1.99549L71.5094 22.1735L71.6075 22.4899H71.9366L71.84 22.1735Z"
        fill="#3F3D56"
      />
      <path
        d="M20.1794 20.2195C19.8745 20.2193 19.5776 20.1213 19.3324 19.9401C19.0872 19.7588 18.9066 19.5038 18.817 19.2124L16.7792 12.5573C16.7245 12.3785 16.7055 12.1907 16.7233 12.0046C16.7411 11.8185 16.7955 11.6377 16.8832 11.4726C16.9709 11.3075 17.0903 11.1613 17.2346 11.0423C17.3788 10.9233 17.5451 10.8339 17.7239 10.7792L45.559 2.25883C45.92 2.14866 46.3101 2.18623 46.6435 2.3633C46.9769 2.54037 47.2265 2.84247 47.3373 3.2033L49.3751 9.85847C49.4852 10.2195 49.4476 10.6095 49.2705 10.9428C49.0934 11.2762 48.7913 11.5257 48.4305 11.6366L20.5954 20.157C20.4606 20.1983 20.3204 20.2194 20.1794 20.2195Z"
        fill="var(--main-color)"
      />
      <path
        d="M30.8536 7.11107C32.6011 7.11107 34.0178 5.69463 34.0178 3.94738C34.0178 2.20012 32.6011 0.783691 30.8536 0.783691C29.1061 0.783691 27.6895 2.20012 27.6895 3.94738C27.6895 5.69463 29.1061 7.11107 30.8536 7.11107Z"
        fill="var(--main-color)"
      />
      <path
        d="M30.8533 5.95054C31.9598 5.95054 32.8569 5.05361 32.8569 3.94719C32.8569 2.84078 31.9598 1.94385 30.8533 1.94385C29.7467 1.94385 28.8496 2.84078 28.8496 3.94719C28.8496 5.05361 29.7467 5.95054 30.8533 5.95054Z"
        fill="white"
      />
      <path
        d="M96.1122 92.0907H42.6377C42.2812 92.0903 41.9394 91.9485 41.6873 91.6964C41.4352 91.4444 41.2934 91.1026 41.293 90.7461V26.6815C41.2934 26.325 41.4352 25.9832 41.6873 25.7312C41.9394 25.4791 42.2812 25.3373 42.6377 25.3369H96.1122C96.4687 25.3373 96.8105 25.4791 97.0626 25.7312C97.3147 25.9832 97.4566 26.325 97.457 26.6815V90.7461C97.4565 91.1026 97.3147 91.4444 97.0626 91.6964C96.8105 91.9485 96.4687 92.0903 96.1122 92.0907Z"
        fill="#E6E6E6"
      />
      <path
        d="M71.51 22.1733H38.2071C37.4941 22.1743 36.8106 22.458 36.3064 22.9621C35.8023 23.4662 35.5186 24.1496 35.5176 24.8625V89.37L35.834 89.2736V24.8625C35.8348 24.2334 36.085 23.6303 36.5299 23.1855C36.9748 22.7407 37.578 22.4905 38.2071 22.4897H71.6081L71.51 22.1733ZM100.541 22.1733H38.2071C37.4941 22.1743 36.8106 22.458 36.3064 22.9621C35.8023 23.4662 35.5186 24.1496 35.5176 24.8625V97.3109C35.5186 98.0238 35.8023 98.7072 36.3064 99.2113C36.8106 99.7154 37.4941 99.999 38.2071 100H100.541C101.254 99.999 101.938 99.7154 102.442 99.2113C102.946 98.7072 103.23 98.0238 103.231 97.3109V24.8625C103.23 24.1496 102.946 23.4662 102.442 22.9621C101.938 22.458 101.254 22.1743 100.541 22.1733ZM102.914 97.3109C102.914 97.94 102.663 98.543 102.218 98.9879C101.774 99.4327 101.17 99.6829 100.541 99.6837H38.2071C37.578 99.6829 36.9748 99.4327 36.5299 98.9879C36.085 98.543 35.8348 97.94 35.834 97.3109V24.8625C35.8348 24.2334 36.085 23.6303 36.5299 23.1855C36.9748 22.7407 37.578 22.4905 38.2071 22.4897H100.541C101.17 22.4905 101.774 22.7407 102.218 23.1855C102.663 23.6303 102.914 24.2334 102.914 24.8625V97.3109Z"
        fill="#3F3D56"
      />
      <path
        d="M83.9288 29.1336H54.8184C54.4409 29.1332 54.079 28.983 53.812 28.7162C53.5451 28.4493 53.395 28.0874 53.3945 27.7099V20.7498C53.395 20.3724 53.5451 20.0105 53.812 19.7436C54.079 19.4767 54.4409 19.3266 54.8184 19.3262H83.9288C84.3063 19.3266 84.6682 19.4767 84.9351 19.7436C85.2021 20.0105 85.3522 20.3724 85.3526 20.7498V27.7099C85.3522 28.0874 85.2021 28.4493 84.9351 28.7162C84.6682 28.983 84.3063 29.1332 83.9288 29.1336Z"
        fill="var(--main-color)"
      />
      <path
        d="M69.3751 19.8005C71.1226 19.8005 72.5393 18.3841 72.5393 16.6368C72.5393 14.8896 71.1226 13.4731 69.3751 13.4731C67.6276 13.4731 66.2109 14.8896 66.2109 16.6368C66.2109 18.3841 67.6276 19.8005 69.3751 19.8005Z"
        fill="var(--main-color)"
      />
      <path
        d="M69.3745 18.5639C70.4389 18.5639 71.3018 17.7012 71.3018 16.6369C71.3018 15.5727 70.4389 14.71 69.3745 14.71C68.3101 14.71 67.4473 15.5727 67.4473 16.6369C67.4473 17.7012 68.3101 18.5639 69.3745 18.5639Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_948_15464">
        <rect
          width="102.462"
          height="100"
          fill="white"
          transform="translate(0.769531)"
        />
      </clipPath>
    </defs>
  </svg>
);
