import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ScreenTemplate from '@components/screenTemplate';
import { InputComponent, SelectComponent } from '@components/formFields';
import { LeftArrowIcon } from '@assets/icons';

import { fetchAllOptions } from '../../helpers/crud';
import { initialState } from './const';
import { updateRoleHandler } from '../../helpers/crud';
import './styles.scss';

const SectionCreateAndEdit = ({ mainState, mainSetState, refetchRoleHandler, isEdit }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { name, accessList, isLoading } = state;
    const [optionList, setOptionList] = useState([]);

    const onClose = () => {
        setState(initialState);
    };
    const onChangeFormField = ({ fieldName, value }) => {
        setState({ ...state, [fieldName]: value });
    };

    const handleFormatting = (dataArray) => {
        const accessList = dataArray.map(({ mainMenuId, name }) => {
            return {
                label: name,
                id: mainMenuId,
                value: name,
            };
        });

        return accessList;
    };

    useEffect(() => {
        const fetchDataAndSetState = async () => {
            if (mainState?.selectedAccess) {
                const res = await fetchAllOptions({ state, setState });
                setOptionList(res);

                setState({
                    ...state,
                    name: mainState?.selectedAccess?.label,
                    id: mainState?.selectedAccess?.id,
                    accessList: handleFormatting(mainState?.selectedAccess?.value),
                });
            }
        };

        fetchDataAndSetState(); // Call the async function
    }, [mainState?.selectedAccess]);

    return (
        <ScreenTemplate>
            <Box className="section-create-and-edit__container__role-management">
                <Flex className="header">
                    <Flex onClick={onClose} cursor="pointer">
                        <LeftArrowIcon color="black" />
                        <Text className="title">{isEdit ? 'Edit' : 'Add'} Role</Text>
                    </Flex>
                </Flex>
                <Flex className="body">
                    <InputComponent
                        label="Nama Role"
                        placeholder="Masukan Nama Role"
                        errorMessage="Nama Role tidak boleh kosong!"
                        type="text"
                        fieldName="name"
                        value={name}
                        onChange={onChangeFormField}
                    />
                    <Box className="form-field__container">
                        <SelectComponent
                            label="Access List"
                            placeholder="Pilih access"
                            errorMessage="Account tidak boleh kosong!"
                            value={accessList}
                            options={optionList}
                            onChange={onChangeFormField}
                            fieldName="accessList"
                        />
                    </Box>
                </Flex>
                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className="save-button"
                        onClick={() => {
                            updateRoleHandler({
                                state,
                                setState,
                                refetchRoleHandler,
                                dispatch,
                                onClose,
                            });
                        }}
                    >
                        Update
                    </Button>
                </Flex>
            </Box>
        </ScreenTemplate>
    );
};

export default SectionCreateAndEdit;
