import { Flex, Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import { MobileCalendarIcon } from '@assets/icons';
import ModalMultipleDate from '@components/modalMultipleDate';
import Search from '@components/search';
import './styles.scss';

const SectionSearchAndFilter = ({ state, onChangeState, maxDate, minDate }) => {
    const { startedAt, endedAt, isOpenMultipleDate, searchQuery } = state;

    return (
        <Flex className="section-search-and-filter__container">
            <Flex className="filter-month" onClick={() => onChangeState({ isOpenMultipleDate: true })}>
                <MobileCalendarIcon color="grey" />
                <Box className="divider" />
                <Text className="filter-text">
                    {moment(startedAt).format('DD MMM YY')} - {moment(endedAt).format('DD MMM YY')}
                </Text>
            </Flex>
            {isOpenMultipleDate && (
                <ModalMultipleDate
                    onClose={() => onChangeState({ isOpenMultipleDate: false })}
                    onApply={onChangeState}
                    values={{ startedAt, endedAt }}
                    maxDate={maxDate ? moment(maxDate).format('YYYY-MM-DD') : null}
                    minDate={minDate ? moment(minDate).format('YYYY-MM-DD') : null}
                />
            )}

            <Search
                label="Cari schedule"
                onSearchHandler={({ searchQuery }) => onChangeState({ searchQuery })}
                currentValue={searchQuery}
            />
        </Flex>
    );
};

export default SectionSearchAndFilter;
