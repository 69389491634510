import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import moment from 'moment';
import { isMobileHandler } from '@helpers/util';

export const fetchScheduleHandler = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const { currentStartDate, currentEndDate, brands, host, ecommerce, studio, account, operator } = state;

    const queries = queryString.parse(search);

    setState({ ...state, isLoadingEvent: true });
    const stringStartedAt = moment(currentStartDate).utc().subtract(1, 'days').format('YYYY-MM-DD');
    const stringEndedAt = moment(currentEndDate).utc().add(1, 'days').format('YYYY-MM-DD');
    const multipleAccount = account?.length ? `[${account?.toString()}]` : [];

    const filterStringify = queryString.stringify(
        {
            ...queries,
            page: state?.page,
            startedAt: `gte.${stringStartedAt}`,
            endedAt: `lte.${stringEndedAt}`,
            'accountLivestreams.brands.name': brands,
            'hosts.name': host,
            'accountLivestreams.ecommerce.name': ecommerce,
            'studio.name': studio,
            'accountLivestreams.id': multipleAccount,
            'operators.name': operator,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: isMobileHandler() ? `/productivity-host/list?${filterStringify}` : `/schedule/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { schedules } = data?.data;

        setState({
            ...state,
            schedules,
            isLoadingEvent: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, schedules: [], isLoadingEvent: false });
    }
};

const assetFormatted = ({ data, type, otherType }) => {
    let result = [];
    //------------- GET VALUE BY NAME -------------------

    result = data?.map((item) => {
        let name = item.name;

        if (type === 'accountLivestreams') {
            name = `${item.name} (${item?.ecommerce?.name})`;
        }

        if (otherType === 'host') {
            name = item?.nameWithBrands;
        }

        return {
            ...item,
            value: item.name,
            name,
        };
    });

    return result;
};

const fetchItemAssetHandler = async ({ url, type, otherType }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `${url}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const assets = assetFormatted({ data: data.data[type], type, otherType });

        return assets;
    } catch (err) {
        console.log(err);
    }
};

export const fetchAllAssetHandler = async ({ setAssets }) => {
    try {
        let [studios, brands, ecommerces, accounts, operators, hosts] = await Promise.all([
            fetchItemAssetHandler({ url: '/studio/list', type: 'studios' }),
            fetchItemAssetHandler({ url: '/brand/list', type: 'brands' }),
            fetchItemAssetHandler({ url: '/ecommerce/list', type: 'ecommerces' }),
            fetchItemAssetHandler({ url: '/account-livestream/list?accountType.id=[1,2]', type: 'accountLivestreams' }),
            fetchItemAssetHandler({ url: `/user/list?roleId=6`, type: 'users', otherType: 'operator' }),
            fetchItemAssetHandler({ url: '/user/list?roleId=4', type: 'users', otherType: 'host' }),
        ]);

        setAssets({
            studios,
            brands,
            ecommerces,
            accounts,
            operators,
            hosts,
        });
    } catch (error) {
        console.log(error);
    }
};

const payloadFormattingHandler = ({ state }) => {
    const { title, scheduleType, startedAt, endedAt, hostId, operatorId, studioId, reason, accountLivestreamId, date } =
        state;
    const dateTimeStarted = `${date}T${startedAt}:00`;
    const dateTimeEnded = `${date}T${endedAt === '00:00' ? '23:59' : endedAt}:00`;

    let payload = {
        title,
        accountLivestreamId: accountLivestreamId?.map((item) => item?.id),
        scheduleType,
        startedAt: `${moment(dateTimeStarted).utc().toISOString()}`,
        endedAt: `${moment(dateTimeEnded).utc().toISOString()}`,
    };

    if (state?.scheduleType === 'Live') {
        payload = {
            ...payload,
            hostId: hostId?.map((item) => item?.id),
            operatorId: operatorId?.map((item) => item?.id),
            studioId: studioId?.id,
        };
    } else {
        payload = {
            ...payload,
            reason,
        };
    }

    return payload;
};

export const submitScheduleHandler = async ({
    state,
    selectedDate,
    dispatch,
    refetchScheduleHandler,
    onClose,
    isEdit,
    eventId,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const payload = payloadFormattingHandler({ state, selectedDate });

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: `Sedang ${isEdit ? 'mengupdate' : 'membuat'} event....`,
        })
    );

    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/schedule/${eventId}` : `/schedule`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });

        await refetchScheduleHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil ${isEdit ? 'mengupdate' : 'membuat'} event....`,
            })
        );
        onClose();
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal membuat event....',
                popUpDescription: message || '',
            })
        );
    } 
};

export const removeEventHandler = async ({ selectedEvent, onClose, dispatch, refetchScheduleHandler }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang menghapus event....',
        })
    );

    try {
        await Axios({
            url: `/schedule/${selectedEvent?.schedule?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            method: 'DELETE',
        });

        await refetchScheduleHandler();

        return {
            title: 'Berhasil menghapus event',
            desc: '',
        };
    } catch (error) {
    } finally {
        onClose();
    }
};

export const onUpdateDetailField = async ({ eventId, payload, refetchDetailScheduleHandler }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        await Axios({
            method: 'PATCH',
            url: `/schedule/${eventId}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });
        refetchDetailScheduleHandler();
    } catch (error) {
        console.log(error);
    }
};

export const fetchScheduleDetail = async ({ id, onChangeDetailState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeDetailState({ isLoading: true });
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/schedule/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeDetailState({
            selectedEvent: data?.data,
            isLoading: false,
        });
    } catch (error) {
        console.log(error);
        onChangeDetailState({ isLoading: false });
    }
};