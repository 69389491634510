import DatePicker from 'react-datepicker';
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import { CalendarIcon, CouponIcon, DownloadIcon } from '@assets/icons';

import './styles.scss';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { FilterSelect } from '@components/globalFilter';
import { updateFilterQueriesHandler } from '@helpers/util';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAssetsHandler } from '@pages/targetSchedule/helpers/crud';
import { useEffect } from 'react';
import moment from 'moment';

const SectionHead = ({ onChangeState, filter, onChangeFilter, assets, onChangeAssets }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        /* eslint-disable */
        fetchAssetsHandler({ onChangeAssets });
    }, []);

    return (
        <Flex className="section-header__container__target-achievement">
            {/** -------------------------- LEFT SIDE ----------------------------**/}

            <Flex className="left-side">
                <Text className="title">Target Achievement</Text>
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            <Flex className="right-side" gap={4}>
                <FilterSelect
                    placeholder="Pilih brand"
                    icon={<CouponIcon color="gray" />}
                    options={assets?.brands?.map((brand) => {
                        return {
                            id: brand?.name,
                            name: brand?.name,
                            value: brand?.name,
                        };
                    })}
                    filterName="brand"
                    onChange={({ type, value }) => {
                        onChangeFilter({
                            brand: value,
                        });
                        updateFilterQueriesHandler({
                            location,
                            type: 'brand.name',
                            value,
                            navigate,
                            valueName: type,
                        });
                    }}
                    value={filter?.brand}
                />

                <Box position="relative" zIndex="5">
                    <DatePicker
                        selected={filter?.date}
                        onChange={(value) => {
                            onChangeFilter({
                                date: value,
                            });
                            updateFilterQueriesHandler({
                                location,
                                type: 'appliedAt',
                                value: moment(value).format('YYYY-MM-DD'),
                                navigate,
                                valueName: 'appliedAt',
                            });
                        }}
                        placeholderText="Pilih bulan"
                        dateFormat="MMM yyyy"
                        wrapperClassName="session-form"
                        showMonthYearPicker
                        showIcon
                        icon={<CalendarIcon color="grey" />}
                    />
                </Box>
                <Button className="upload-button" onClick={() => onChangeState({ isOpenUpload: true })}>
                    <DownloadIcon color="white" size="16" styles={{ marginRight: '8px' }} />
                    Upload
                </Button>
            </Flex>
        </Flex>
    );
};

export default SectionHead;
