import Axios from '@services/axios';
import { toggleLoginHandler } from '@store/list/login';
import { popupHandler, resetPopUpHandler } from '@store/list/popup';

export const loginHandler = async ({ setState, navigate, dispatch, cookies, state }) => {
    setState({ ...state, isLoading: true });

    // Check if email or password is missing
    if (!state.email || !state.password) {
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Please fill in both email and password fields.',
            })
        );

        setState({ ...state, isLoading: false });
        return; // Exit the function early
    }

    await dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang login....',
        })
    );

    try {
        const { email, password } = state;
        const { data } = await Axios({
            method: 'POST',
            url: '/login',
            data: {
                email,
                password,
            },
        });

        const today = new Date();
        const expirationDate = new Date(today.setMonth(today.getMonth() + 1));

        const { token, roleId, name } = data?.data;

        cookies.set('username', name, { expires: expirationDate });
        cookies.set('userToken', token, { expires: expirationDate });
        cookies.set('roleId', roleId, { expires: expirationDate });
        await dispatch(toggleLoginHandler(true));

        dispatch(resetPopUpHandler());

        navigate('/');
    } catch (error) {
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Username or password is wrong. Please check your credentials.',
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};

export const forgotPasswordHandler = async ({ state, setState, dispatch }) => {
    await dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Send email....',
        })
    );

    if (!state?.resetEmail) {
        // Display a toast notification for an empty email
        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Please fill email fields.',
            })
        );
        return; // Return early if the email is empty
    }

    // Regular expression for basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(state?.resetEmail)) {
        // Display a toast notification for an invalid email format

        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Please enter a valid email address to reset your password.',
            })
        );
        return; // Return early if the email format is invalid
    }

    try {
        await Axios({
            method: 'POST',
            url: '/forgot-password',
            data: {
                email: state?.resetEmail,
            },
        });

        // Display a success toast notification
        await dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Password Reset Email Sent.',
            })
        );

        setState({ ...state, isForgotPassword: false });
    } catch (error) {
        console.log(error);

        // Display an error toast notification

        await dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'An error occurred while sending the password reset email. Please try again later.',
            })
        );
    }
};