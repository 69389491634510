import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import _ from 'lodash';

export const reportingTypes = ['Achievement Host', 'All Parameter by Host', 'Session', 'Achievement Account Platform'];

export const tableList = {
    'Achievement Host': [
        {
            name: 'Host ID (BA ID)',
            value: 'baId',
            width: 150,
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'baId',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Nama Host',
            width: 280,
            value: 'hostName',
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'hostName',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Brand',
            width: 180,
            value: 'brandName',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Pencapaian Penghasilan',
            width: 220,
            value: 'totalRevenue',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalRevenue',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Target Penghasilan',
            width: 200,
            value: 'targetAchievement',
            dataManipulationType: 'sort',
            dataManipulationValue: 'targetAchievement',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Presentase Achievement',
            width: 230,
            value: 'targetAchievementPercentage',
            dataManipulationType: 'sort',
            dataManipulationValue: 'targetAchievementPercentage',
            component: ({ value }) => <Text className="column-text">{value || 0}%</Text>,
        },
        {
            name: 'Pencapaian Jam',
            width: 180,
            value: 'totalDuration',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalDuration',
            component: ({ value }) => <Text className="column-text">{value || 0} Jam</Text>,
        },
        {
            name: 'Target Jam',
            width: 180,
            value: 'targetDuration',
            dataManipulationType: 'sort',
            dataManipulationValue: 'targetDuration',
            component: ({ value }) => <Text className="column-text">{value || 0} Jam</Text>,
        },
        {
            name: 'Achievement Jam',
            width: 180,
            value: 'targetDurationPercentage',
            dataManipulationType: 'sort',
            dataManipulationValue: 'targetDurationPercentage',
            component: ({ value }) => <Text className="column-text">{value || 0}%</Text>,
        },
    ],
    'All Parameter by Host': [
        {
            name: 'Host ID (ba.id)',
            value: 'baId',
            width: 150,
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'baId',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Nama Host',
            width: 280,
            value: 'hostName',
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'hostName',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Brand',
            width: 180,
            value: 'brandName',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Sesi Live',
            width: 180,
            value: 'totalLive',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalLive',
            component: ({ value }) => <Text className="column-text">{value || 0}</Text>,
        },
        {
            name: 'Durasi Live',
            width: 180,
            value: 'totalDuration',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalDuration',
            component: ({ value }) => (
                <Text className="column-text">{_.round(value || 0, 2).toLocaleString('id-ID')} jam</Text>
            ),
        },
        {
            name: 'Penghasilan',
            width: 180,
            value: 'totalRevenue',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalRevenue',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Penghasilan/live',
            width: 180,
            value: 'totalRevenuePerLive',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalRevenuePerLive',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Buyer',
            width: 180,
            value: 'totalBuyer',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalBuyer',
            component: ({ value }) => <Text className="column-text">{value || 0}</Text>,
        },
        {
            name: 'Viewers',
            width: 180,
            value: 'totalViewer',
            dataManipulationType: 'sort',
            dataManipulationValue: 'totalViewer',
            component: ({ value }) => <Text className="column-text">{value || 0}</Text>,
        },
        {
            name: 'Conversion rate',
            width: 180,
            value: 'conversionRate',
            component: ({ value }) => <Text className="column-text">{value || 0}%</Text>,
        },
    ],
    Session: [
        {
            name: 'Host ID (BA ID)',
            width: 150,
            value: 'schedule.hosts.baId',
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'schedule.hosts.baId',
            component: ({ value }) => (
                <Flex flexDirection="column">
                    {value?.map((item) => (
                        <li className="column-text">{item || '-'}</li>
                    ))}
                </Flex>
            ),
        },
        {
            name: 'Jam mulai jadwal',
            width: 180,
            value: 'schedule.startedAt',
            component: ({ value }) => (
                <Text className="column-text">{moment(value).format('D MMM YYYY, HH:mm:ss') || '-'}</Text>
            ),
        },
        {
            name: 'Jam selesai  jadwal',
            width: 180,
            value: 'schedule.endedAt',
            component: ({ value }) => (
                <Text className="column-text">{moment(value).format('D MMM YYYY, HH:mm:ss') || '-'}</Text>
            ),
        },
        {
            name: 'Nama Host',
            width: 280,
            value: 'schedule.hosts.name',
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'schedule.hosts.name',
            component: ({ value }) => (
                <Flex flexDirection="column">
                    {value?.map((item) => (
                        <li className="column-text">{item || '-'}</li>
                    ))}
                </Flex>
            ),
        },
        {
            name: 'Schedule name',
            width: 270,
            value: 'schedule.title',
            isSticky: true,
            dataManipulationType: 'search',
            dataManipulationValue: 'schedule.title',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Brand',
            width: 180,
            value: 'accountLivestreams.brands.name',
            component: ({ value }) => (
                <Flex flexDirection="column">
                    {value?.map((item) => (
                        <li className="column-text">{item || '-'}</li>
                    ))}
                </Flex>
            ),
        },
        {
            name: 'Durasi Aktual',
            width: 180,
            value: 'hours',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'hours',
            component: ({ value }) => <Text className="column-text">{value || 0} jam</Text>,
        },
        {
            name: 'Marketplace',
            width: 180,
            value: 'accountLivestreams.ecommerce.name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Account',
            width: 180,
            value: 'accountLivestreams.name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Studio',
            width: 180,
            value: 'schedule.studio.name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },

        {
            name: 'Penghasilan live',
            width: 180,
            value: 'revenue',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'revenue',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Viewers',
            width: 180,
            value: 'viewer',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'viewer',
            component: ({ value }) => <Text className="column-text">{(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Avg view duration',
            width: 180,
            value: 'fulltimeAvgViewDuration',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'fulltimeDuration',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Buyer',
            width: 180,
            value: 'buyer',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'buyer',
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'Converstion rate',
            width: 180,
            value: 'conversationRate',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'conversationRate',
            component: ({ value }) => <Text className="column-text">{value || 0}%</Text>,
        },
        {
            name: 'CTR',
            width: 180,
            value: 'ctrPercentage',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'ctrPercentage',
            component: ({ value }) => <Text className="column-text">{value || 0}%</Text>,
        },
    ],
    'Achievement Account Platform': [
        {
            name: 'Brand',
            width: 180,
            value: 'brands.name',
            component: ({ value }) => (
                <Flex flexDirection="column">
                    {value?.map((item) => (
                        <li className="column-text">{item || '-'}</li>
                    ))}
                </Flex>
            ),
        },
        {
            name: 'Marketplace',
            width: 180,
            value: 'ecommerce.name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Account',
            width: 180,
            value: 'name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Tipe account',
            width: 180,
            value: 'accountType.name',
            component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
        },
        {
            name: 'Total revenue',
            width: 180,
            value: 'totalRevenue',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalRevenue',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Jumlah live',
            width: 180,
            value: 'totalLive',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalLive',
            component: ({ value }) => <Text className="column-text">{(value || 0).toLocaleString('id-ID')}</Text>,
        },
        {
            name: 'Durasi live',
            width: 180,
            value: 'totalDuration',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalDuration',
            component: ({ value }) => <Text className="column-text">{_.round(value || 0, 2)} jam</Text>,
        },
        {
            name: 'Buyer',
            width: 180,
            value: 'totalBuyer',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalBuyer',
            component: ({ value }) => <Text className="column-text">{value || 0}</Text>,
        },
        {
            name: 'Viewers',
            width: 180,
            value: 'totalViewer',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalViewer',
            component: ({ value }) => <Text className="column-text">{value || 0}</Text>,
        },
        {
            name: 'Rata2 waktu tonton',
            width: 180,
            value: 'totalAvgViewDuration',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalAvgViewDuration',
            component: ({ value }) => <Text className="column-text">{value} </Text>,
        },
        {
            name: 'Conversion rate',
            width: 180,
            value: 'totalConversationRate',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalConversationRate',
            component: ({ value }) => <Text className="column-text">{_.round((value || 0) * 100, 2)}%</Text>,
        },
        {
            name: 'Penghasilan/live',
            width: 180,
            value: 'totalRevenuePerLive',
            // dataManipulationType: 'sort',
            // dataManipulationValue: 'totalRevenuePerLive',
            component: ({ value }) => <Text className="column-text">Rp {(value || 0).toLocaleString('id-ID')}</Text>,
        },
    ],
};
