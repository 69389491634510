import { FileUploader } from 'react-drag-drop-files';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { useState, createContext } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import ProgressBar from '@components/progressBar';

import ModalTemplate from '@components/modal';
import DownloadTemplateButton from '../downloadTemplate';
import { FileIcon, FileIconFill, UploadIconSm } from '@assets/icons';
import { onChangeFile } from '@pages/targetSchedule/helpers/events';
import { initialState } from './const';
import { onSubmitInvalidData } from '../../helpers/crud';
import InvalidDataTable from '../invalidDataTable';

import './styles.scss';

export const UploadPopUpContext = createContext();

const UploadPopUp = ({ onChangeMainState, refetchTargetUpload }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState(initialState);
    const { isDragging, uploadState, invalidData } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });
    return (
        <UploadPopUpContext.Provider
            value={{
                onChangePopUpState: onChangeState,
                popUpState: state,
            }}
        >
            <ModalTemplate onClose={() => onChangeMainState({ isOpenUpload: false })}>
                <Box className="upload-target__container">
                    {/** ======================== HEAD ========================= */}
                    <Flex className="head">
                        <Text className="title" onClick={() => onChangeMainState({ isOpenUpload: false })}>
                            <ArrowBackIcon marginRight="16px" />
                            Upload File
                        </Text>
                        {uploadState === 'invalid-data' ? (
                            <Flex className="button-container__invalid-data">
                                <Button className="cancel" onClick={() => onChangeState({ uploadState: '' })}>
                                    Cancel
                                </Button>
                                <Button
                                    className="submit"
                                    onClick={() =>
                                        onSubmitInvalidData({
                                            state,
                                            onChangeState,
                                            dispatch,
                                            refetchTargetUpload,
                                            onChangeMainState,
                                        })
                                    }
                                >
                                    Submit
                                </Button>
                            </Flex>
                        ) : (
                            <DownloadTemplateButton />
                        )}
                    </Flex>

                    {/** ======================== UPLOAD SECTION ========================= */}
                    {uploadState === '' && (
                        <Box className="body">
                            <Flex className="upload-box">
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => onChangeState({ isDragging: dragging })}
                                    onDrop={(files) =>
                                        onChangeFile({
                                            files,
                                            onChangeState,
                                            dispatch,
                                            refetchTargetUpload,
                                            onChangeMainState,
                                        })
                                    }
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx', 'csv']}
                                    disabled
                                    // onTypeError={(err) => setError(true)}
                                />

                                {isDragging && (
                                    <Box className="drag-zone__actived">
                                        <Text className="text">"Drop file here"</Text>
                                    </Box>
                                )}

                                <FileIcon />
                                <Text className="upload-text">Drag and Drop atau pilih file Exel untuk di upload</Text>
                                <Button className="upload-button">
                                    <FileUploader
                                        name="file"
                                        onDraggingStateChange={(dragging) => onChangeState({ isDragging: dragging })}
                                        handleChange={(files) =>
                                            onChangeFile({
                                                files,
                                                onChangeState,
                                                dispatch,
                                                refetchTargetUpload,
                                                onChangeMainState,
                                            })
                                        }
                                        classes="file-uploader__drag-and-drop-only"
                                        types={['xlsx', 'csv']}
                                        // onTypeError={(err) => setError(true)}
                                    />
                                    <UploadIconSm />
                                    Upload File
                                </Button>
                            </Flex>
                        </Box>
                    )}

                    {uploadState === 'process' && (
                        <Box className="upload-process__box">
                            <Box className="icon">
                                <FileIconFill color="var(--main-color)" size={36} />
                            </Box>
                            <Box className="progress">
                                <Flex className="process-text">
                                    <Text className="text">Processing data..</Text>
                                    <Text className="text">
                                        <CountUp end={87} duration={8} />%
                                    </Text>
                                </Flex>
                                <ProgressBar color="var(--main-color)" width={'100%'} />
                            </Box>
                        </Box>
                    )}

                    {uploadState === 'invalid-data' && <InvalidDataTable data={invalidData} />}
                </Box>
            </ModalTemplate>
        </UploadPopUpContext.Provider>
    );
};

export default UploadPopUp;
