import { Flex, Text } from '@chakra-ui/react';

import { ButtonAdd, ButtonActivate, ButtonCancel, ButtonSave } from '@components/button';
import { onShowingApprovingPopUp } from '../../helpers/events';

import './styles.scss';

const SectionHead = ({ setState, state, dispatch, refetchPromotionHandler }) => {
    const { isConfirmation } = state;

    return (
        <>
            <Flex className="section-header__container__promotion">
                {/** -------------------------- LEFT SIDE ----------------------------**/}
                <Flex className="left-side">
                    <Text className="title">Promotion List</Text>
                </Flex>

                {/** -------------------------- RIGHT SIDE ----------------------------**/}

                <Flex className="right-side">
                    {isConfirmation ? (
                        <>
                            <ButtonCancel onClick={() => setState({ ...state, isConfirmation: false })} />
                            <ButtonSave
                                textButton="Save"
                                color="#14AE5C"
                                width="max-content"
                                onClick={() => onShowingApprovingPopUp({ dispatch, refetchPromotionHandler, state })}
                                styles={{ marginLeft: '6px' }}
                            />
                        </>
                    ) : (
                        <>
                            <ButtonAdd
                                textButton="Add Promotion"
                                width="160px"
                                onClick={() => setState({ ...state, isCreatePage: true })}
                            />
                            <ButtonActivate
                                textButton="Confirmation"
                                onClick={() => setState({ ...state, isConfirmation: true })}
                            />
                        </>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default SectionHead;
