import React from 'react';
import { Text } from '@chakra-ui/react';
import './styles.scss';

export const BadgeStatus = ({ title, color, backgroundColor }) => (
    <Text
        className="bagde-status-global__container"
        style={{
            backgroundColor,
            color,
        }}
    >
        {title}
    </Text>
);
