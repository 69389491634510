//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------

import TableSkeleton from '@components/tableSkeleton';

//---------------- LOCAL COMPONENT ------------------------------------------

import SectionHead from './sections/sectionHead';
import CreateAndEditModal from './sections/sectionCreateAndEdit';
import SectionBody from './sections/sectionBody';

//---------------- UTILS ------------------------------------------

import { fetchKoinHandler, fetchAssetHandler } from './helpers/crud';
import { onShowingRemovePopUp, onCheckboxHandler } from './helpers/events';
import { initialState, initialAssets, initialMultiSelect } from './const';
import './styles.scss';

export const KoinContext = createContext();

const Koin = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();

    const [state, setState] = useState(initialState);
    const [assets, setAsset] = useState(initialAssets);
    const [multiSelect, setMultiSelect] = useState(initialMultiSelect);
    const { isLoading, page, isCreatePage, isEditPage } = state;

    //----------------------------------- EVENT FUNCTION -----------------------------------

    const onPagePaginationClick = ({ selected }) => setState({ ...state, page: selected + 1 });
    const refetchKoinHandler = async () => await fetchKoinHandler({ state, setState, search });

    //----------------------------------- USEFFECT FUNCTION -----------------------------------

    useEffect(() => {
        /* eslint-disable */
        fetchKoinHandler({ state, setState, search });
    }, [page, search]);

    useEffect(() => {
        fetchAssetHandler({ assets, setAsset });
    }, []);

    return (
        <KoinContext.Provider
            value={{
                onEditColumnHandler: (selectedKoin) =>
                    setState({ ...state, selectedKoin, isEditPage: !state?.isEditPage }),
                onShowingRemovePopUp: (selectedKoin) =>
                    onShowingRemovePopUp({ dispatch, refetchKoinHandler, selectedKoin }),
                state,
                assets,
                onCheckboxHandler: (selectedKoin) =>
                    onCheckboxHandler({
                        selectedKoin,
                        multiSelect,
                        setMultiSelect,
                    }),
                multiSelect,
                setMultiSelect,
                refetchKoinHandler,
                onKonfirmasiParamaPopUp: (multiSelect) =>
                    onKonfirmasiParamaPopUp({ multiSelect, dispatch, refetchKoinHandler, setMultiSelect }),
            }}
        >
            <Box className="coin__container">
                <SectionHead
                    setState={setState}
                    state={state}
                    multiSelect={multiSelect}
                    setMultiSelect={setMultiSelect}
                    refetchKoinHandler={refetchKoinHandler}
                    dispatch={dispatch}
                />

                {!isLoading && (
                    <SectionBody
                        state={state}
                        handlePageClick={onPagePaginationClick}
                        setMultiSelect={setMultiSelect}
                        multiSelect={multiSelect}
                    />
                )}
                {isLoading && <TableSkeleton />}

                {isCreatePage && (
                    <CreateAndEditModal
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchKoinHandler={refetchKoinHandler}
                    />
                )}
                {isEditPage && (
                    <CreateAndEditModal
                        isEdit
                        mainState={state}
                        mainSetState={setState}
                        assets={assets}
                        refetchKoinHandler={refetchKoinHandler}
                    />
                )}
            </Box>
        </KoinContext.Provider>
    );
};

export default Koin;
