import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react';
import { get, find } from 'lodash';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useSelector } from 'react-redux';
import interactionPlugin from '@fullcalendar/interaction';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';

import { LoadingState3 } from '@components/loadingState';
import ScheduleDetail from './components/detail';
import renderEventContent from './components/card';
import CreateOrEditEventPopUp from './components/createOrUpdate';
import { formattingScheduleHandler } from '../../helpers/utlis';
import { initialState } from './const';
import { calendarTypeOption } from '../sectionHead/const';

import './styles.scss';

const SectionBody = ({ calendarRef, mainSetState, mainState }) => {
    const [state, setState] = useState(initialState);
    const { isMobile } = useSelector((state) => state.isMobileSlice);
    const navigate = useNavigate();

    const { isOpenAddEvent, selectedDate, isOpenDetail, isOpenEditEvent, selectedEvent } = state;

    const clickDateHandler = (event) => {
        if (!isMobile) {
            setState({ ...state, selectedDate: event, isOpenAddEvent: true });
        }
    };

    return (
        <Box className="section-body__container__schedule">
            <FullCalendar
                ref={calendarRef}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView={'timeGridFourDay'}
                views={{
                    timeGridFourDay: {
                        type: 'timeGrid',
                        duration: {
                            days: get(find(calendarTypeOption, { value: mainState?.calendarType }), 'days'),
                        },
                    },
                }}
                weekends={true}
                events={formattingScheduleHandler({
                    schedules: mainState?.schedules,
                })}
                eventContent={(eventInfo) =>
                    renderEventContent({ eventInfo, state, setState, navigate, clickDateHandler })
                }
                headerToolbar={false}
                height={'calc(100vh - 150px)'}
                dateClick={clickDateHandler}
                dayHeaderContent={(args) => (
                    <>
                        <span>{moment(args.date).format('Do')}</span>
                        {moment(args.date).format('ddd')}
                    </>
                )}
                datesSet={(arg) => {
                    mainSetState({
                        ...mainState,
                        currentStartDate: arg.start,
                        currentEndDate: arg.end,
                    });
                }}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'narrow',
                    hour12: false,
                }}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: 'numeric',
                    omitZeroMinute: false,
                    hour12: false,
                }}
                axisFormat="HH:mm"
                locale="en-GB"
            />

            {mainState?.isLoadingEvent && <LoadingState3 />}

            {isOpenAddEvent && (
                <CreateOrEditEventPopUp
                    onClose={() => setState({ ...initialState, isOpenAddEvent: false, selectedEvent: {} })}
                    selectedDate={selectedDate}
                    mainState={mainState}
                />
            )}

            {isOpenEditEvent && (
                <CreateOrEditEventPopUp
                    onClose={() => setState({ ...initialState, isOpenEditEvent: false, isOpenDetail: false })}
                    selectedDate={selectedDate}
                    selectedEvent={selectedEvent}
                    isEdit
                />
            )}

            {isOpenDetail && (
                <ScheduleDetail
                    onClose={() => setState({ ...state, isOpenDetail: false, selectedEvent: {} })}
                    state={state}
                    setState={setState}
                />
            )}
        </Box>
    );
};

export default SectionBody;
