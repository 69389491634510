// eslint-disable-next-line
import { configureStore } from "@reduxjs/toolkit";

//---------------- slice list --------------------------
import loginSlice from "./list/login";
import userSlice from "./list/user";
import popupSlice from './list/popup';
import isMobileSlice from './list/isMobile';

export default configureStore({
    reducer: { loginSlice, userSlice, popupSlice, isMobileSlice },
});
