export const initialState = {
    isOpenUpload: false,
    targets: [],
    page: 1,
};

export const initialAssets = {
    brands: [],
    hosts: [],
};

export const initialFilter = {
    brand: '',
    date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
};
