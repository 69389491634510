import { Box, Text, Button, Modal, ModalOverlay, ModalBody, ModalContent, Switch, Flex } from '@chakra-ui/react';
import DatePicker from 'react-date-picker';
import { useState } from 'react';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { CalendarIcon, IconImage } from '@assets/icons';
import { useDispatch } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { CameraIcon } from '@assets/icons';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { TimePicker as TimePickerIos } from 'react-ios-time-picker';
import { popupHandler } from '@store/list/popup';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-html5-camera-photo/build/css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './styles.scss';
import { addSingleComment, fetchSingleComment } from '@pages/voucher/helpers/crud';

export const InputComponent = ({ label, placeholder, errorMessage, isError, type, value, onChange, fieldName }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <input
            className={`input-field ${isError && 'error-empty__field'}`}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={(e) => onChange({ value: e.target.value, fieldName })}
            accept="image/png, image/jpeg"
        />

        {isError && (
            <Box className="error-section">
                <Text className="error-field__text">{errorMessage}</Text>
            </Box>
        )}
    </Box>
);

export const InputComment = ({
    label,
    placeholder,
    errorMessage,
    isError,
    type,
    value,
    valueId,
    onChange,
    fieldName,
    dispatch,
    setComments,
}) => (
    <Box className="form-field__container_comment">
        <Text className="label">{label}</Text>
        <input
            className={`input-field ${isError && 'error-empty__field'}`}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={(e) => onChange({ value: e.target.value, fieldName })}
        />
        <Flex justify="flex-end">
            <Button
                marginTop={3}
                fontSize={12}
                onClick={async () => {
                    await addSingleComment({ value, valueId, dispatch });
                    const { comments } = await fetchSingleComment(valueId);
                    setComments(comments);
                }}
                className="button"
            >
                POST
            </Button>
        </Flex>

        {isError && (
            <Box className="error-section">
                <Text className="error-field__text">{errorMessage}</Text>
            </Box>
        )}
    </Box>
);

export const SelectComponent = ({
    label,
    placeholder,
    errorMessage,
    isError,
    options,
    isMulti = true,
    value,
    onChange,
    fieldName,
}) => (
    <Box className="form-field__container">
        {label && <Text className="label">{label}</Text>}
        <Select
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: '#a5a5a5',
                    minHeight: '48px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '16px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                }),
            }}
            isMulti={isMulti}
            placeholder={placeholder}
            options={options}
            name="brand"
            value={value}
            onChange={(value) => onChange({ value, fieldName })}
        />
        {isError && <Text className="required-field__text">{errorMessage}</Text>}
    </Box>
);

export const TextAreaComponent = ({ label, placeholder, errorMessage, isError, value, fieldName, onChange }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <textarea
            value={value}
            className={`input-field text-area__container ${isError && 'error-empty__field'}`}
            placeholder={placeholder}
            onChange={(e) => onChange({ value: e.target.value, fieldName })}
        />
        {isError && <Text className="required-field__text">{errorMessage}</Text>}
    </Box>
);

export const DateInputComponent = ({ value, label, onChange, fieldName }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <DatePicker
            value={value}
            onChange={(value) => onChange({ value, fieldName })}
            className="date-picker__container__form-field"
            clearIcon={null}
            calendarIcon={<CalendarIcon color="grey" />}
            calendarClassName="calendar-container"
            format="dd/MM/yyyy"
            dayPlaceholder="1"
            monthPlaceholder="1"
            yearPlaceholder="2023"
        />
    </Box>
);

export const TimePickerComponent = ({ onChange, label, value, fieldName, type = 'minute', disabled }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <TimePicker
            onChange={(value) => onChange({ fieldName, value })}
            value={disabled ? '00:00:00' : value}
            disableClock={true}
            locale="sv-sv"
            maxDetail={type}
            disabled={disabled}
        />
    </Box>
);

export const TimePickerComponent2 = ({ onChange, label, value, fieldName }) => {
    return (
        <Box className="form-field__container">
            <Text className="label">{label}</Text>
            <Box className="time-picker__container">
                <TimePickerIos
                    onChange={(value) => onChange({ fieldName, value })}
                    value={value}
                    pickerDefaultValue="02:00"
                />
            </Box>
        </Box>
    );
};

export const TimePickerNativeComponent = ({ onChange, label, value, fieldName }) => {
    return (
        <Box className="form-field__container">
            <Text className="label">{label}</Text>
            <Box className="time-picker-native__container">
                <input
                    className="input-time-picker-native__container"
                    step="1"
                    type="time"
                    onChange={(e) => onChange({ fieldName, value: e.target.value })}
                    value={value}
                />
            </Box>
        </Box>
    );
};

export const RichTextInputComponent = ({ value, onChange, label, placeholder, fieldName }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <Editor
            editorState={value}
            editorClassName="editor-field__container"
            toolbarClassName="editor-field-toolbar__container"
            wrapperClassName="wrapperClassName"
            onEditorStateChange={(value) => onChange({ fieldName, value })}
            placeholder={placeholder}
            toolbarHidden
        />
    </Box>
);

export const CameraFieldComponent = ({ label, handleTakePhoto, value }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({ isOpen: false, isLoading: false });
    const onSelectFile = (e) => {
        if (e.target.files[0].size > 5000000) {
            dispatch(
                popupHandler({
                    popUpType: 'popup-failed',
                    popUpTitle: 'Maximal size 5MB',
                })
            );
            return;
        }
        handleTakePhoto({
            isFromGallery: true,
            uri: e.target.files[0],
        });
        setState({ ...state, isOpen: false });
    };

    return (
        <Box className="form-field__container">
            <Text className="label">{label}</Text>
            <Box className="camera-button" onClick={() => setState({ ...state, isOpen: true })}>
                <Text className="text-placeholder" color={value ? 'blue' : '#9c9898'}>
                    {value || 'Ambil Foto '}
                </Text>

                <Box className="icon">
                    <CameraIcon />
                </Box>
            </Box>
            <Modal isOpen={state?.isOpen} onClose={() => setState({ ...state, isOpen: false })}>
                <ModalOverlay onClick={() => setState({ ...state, isOpen: false })} />
                <ModalBody>
                    <ModalContent w="80%" padding="16px">
                        <Box className="select-image__container">
                            <IconImage color="black" />

                            <input
                                type="file"
                                className={`input-field`}
                                onChange={onSelectFile}
                                accept="image/png, image/jpeg"
                            />
                        </Box>
                        <Camera
                            onTakePhoto={(uri) => {
                                handleTakePhoto({
                                    uri,
                                    onCloseCamera: () => setState({ ...state, isOpen: false }),
                                });
                            }}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                        />
                    </ModalContent>
                </ModalBody>
            </Modal>
        </Box>
    );
};

export const ToggleFieldComponent = ({ label, onChange, fieldName, value }) => (
    <Box className="form-field__container">
        <Text className="label">{label}</Text>
        <Switch isChecked={value} onChange={(e) => onChange({ value: e.target.checked, fieldName })} />
    </Box>
);
