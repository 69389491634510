import { Box, Button } from '@chakra-ui/react';
import { AddIconBlue, CheckIcon } from '@assets/icons';
import './styles.scss';

export const ButtonAdd = ({ onClick, textButton, width }) => (
    <Box onClick={onClick} className="global-button__container" marginLeft={5} width={width}>
        <AddIconBlue />
        {textButton}
    </Box>
);

export const ButtonActivate = ({ onClick, textButton }) => (
    <Box
        onClick={onClick}
        className="global-button-white-stroke__container"
        marginLeft={5}
        style={{ background: 'var(--main-color)', color: 'white', width: 'max-content' }}
    >
        <CheckIcon className="white-stroke" />
        {textButton}
    </Box>
);

export const ButtonSave = ({ onClick, styles }) => (
    <Button onClick={onClick} className="global-button-save__container" style={styles}>
        Save
    </Button>
);

export const ButtonCancel = ({ onClick, styles }) => (
    <Button onClick={onClick} className="global-button-cancel__container" style={styles}>
        Cancel
    </Button>
);
