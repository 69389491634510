import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    isLoading: false,
  },
  reducers: {
    loadingHandler: (state, action) => {
      state.isLoading = action.payload;
    },
    getUserHandler: (state, action) => {
      state.users = action.payload;
    },
    resetUserStateHandler: (state) => {
      state = {
        users: [],
        isLoading: false,
      };
    },
  },
});

export const { getUserHandler, loadingHandler } = userSlice.actions;

export default userSlice.reducer;
