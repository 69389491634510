import { Box, Flex } from '@chakra-ui/react';
import './styles.scss';
import { useState } from 'react';

const Switch = ({ width, value1, value2, onChange, fieldName }) => {
    const [val, setVal] = useState(value1);

    const onChangeSwitch = (value) => {
        setVal(value);
        onChange({ value, fieldName });
    };

    return (
        <Flex className="switch-container" w={width}>
            <Box className={`box-switch box-1 ${val === value1 && 'active'}`} onClick={() => onChangeSwitch(value1)}>
                {value1}
            </Box>
            <Box className={`box-switch box-2 ${val === value2 && 'active'}`} onClick={() => onChangeSwitch(value2)}>
                {value2}
            </Box>
        </Flex>
    );
};

export default Switch;
