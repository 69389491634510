import { removeAccountHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingRemovePopUp = async ({ selectedAccount, dispatch, refetchAccountHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus akun ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeAccountHandler({
                    selectedAccount,
                    refetchAccountHandler,
                }),
        })
    );
};
