import { sortBy } from 'lodash';

export const payloadFormatting = ({ state }) => {
    const { areas, appTypes, name, address,room } = state; 
        return {
            name: name.toString(),
            areaId: areas?.value.toString(),
            room: room.toString(),
            address: address.toString(),
            appTypeId: appTypes?.value.toString(),
        };
    
};

export const getBrandColor = ({ name }) => {
    const obj = {
        Wonderly: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wonderly.png',
            name: 'Wonderly',
        },
        Biodef: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/biodef.jpg',
            name: 'Biodef',
        },
        Instaperfect: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/instaperfect.png',
            name: 'Instaperfect',
        },
        OMG: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/omg-icon.png',
            name: 'OMG',
        },
        Putri: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/putri.png',
            name: 'Putri',
        },
        'Make Over': {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/makeover.jpg',
            name: 'Make Over',
        },
        Labore: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/labore.png',
            name: 'Labore',
        },
        Beyondly: {
            link: 'https://www.beyondlyid.com/favicon.ico',
            name: 'Beyondly',
        },
        Crystallure: {
            link: 'https://crystallurebeauty.com/favicon.ico',
            name: 'Crystallure',
        },
        Tavi: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/tavi.jpg',
            name: 'Tavi',
        },
        Emina: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/emina.jpeg',
            name: 'Emina',
        },
        Kahf: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/kahf.jpeg',
            name: 'Kahf',
        },
        Wardah: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wardah.jpg',
            name: 'Wardah',
        },
    };
    return obj[name];
};

export const sortByBrandHandler = (arr) => {
    return sortBy(arr, ({ name }) => name);
};

export const formattingSelectedStateHandler = ({ arr, type }) => {
    // Check if 'arr' is null or undefined, and return an appropriate default value
    if (arr === null || arr === undefined) {
        if (type === 'areas') {
            return []; // Return an empty array for 'areas' type
        } else {
            return null; // Return null for other types
        }
    }

    // Ensure 'arr' is always an array, even if it's a single object
    const dataArray = Array.isArray(arr) ? arr : [arr];

    // Initialize an empty object to store the formatted data
    const obj = {
        areas: dataArray.map(({ name, id }) => {
            return {
                label: name,
                value: id,
            };
        }),
        appTypes: dataArray.map(({ name, id }) => {
            return {
                label: name,
                value: id,
            };
        }),
    };

    // Return the formatted data based on the 'type' provided
    return obj[type];
};
