import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { downloadServiceHandler } from './utils';
import queryString from 'query-string';
import { popupHandler, resetPopUpHandler } from '@store/list/popup';

export const onDownloadData = async ({ state, search, filter, dispatch }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );

    dispatch(
        popupHandler({
            popUpType: 'popup-download',
            popUpTitle: 'Sedang mendownload data',
        })
    );

    const { url, body, fileName } = downloadServiceHandler({ reportingType: state?.reportingType, filter });
    try {
        const res = await Axios({
            method: 'POST',
            responseType: 'blob',
            url: `${url}?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: body,
        });

        const urlHref = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlHref;
        link.setAttribute('download', `${fileName}.xlsx`);

        document.body.appendChild(link);

        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);

        dispatch(resetPopUpHandler());
    } catch (error) {
        console.log(error);
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mendownload data!',
            })
        );
    }
};
