import { Box, Skeleton, Flex } from '@chakra-ui/react';
import './styles.scss';
import { numberToArray } from '@helpers/util';

const TableTemplateMobileSkeleton = ({ row = 5 }) => {
    return (
        <Box className="table-template-mobile__skeleton">
            <Flex className="header">
                {[1, 2, 3, 4, 5]?.map(() => (
                    <Skeleton h="12px" flex={1} startColor="#cf9595" endColor="rgba(var(--main-color),0.5)" />
                ))}
            </Flex>
            <Box className="body">
                {numberToArray(row)?.map(() => (
                    <Flex className="skeleton-column">
                        {[1, 2, 3, 4, 5]?.map(() => (
                            <Skeleton h="12px" flex={1} />
                        ))}
                    </Flex>
                ))}
            </Box>
        </Box>
    );
};

export default TableTemplateMobileSkeleton;
