import { removeProductHighlightHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingRemovePopUp = async ({ selectedProduct, dispatch, refetchProductHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus produk ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeProductHighlightHandler({
                    selectedProduct,
                    refetchProductHandler,
                }),
        })
    );
};
