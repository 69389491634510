import { Box, Text, Flex, Table, Td, Th, Tr } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { isEmpty } from 'lodash';
import EmptyStateMobile from '@components/emptyStateMobile';

import { ChevronRightIcon, ChevronLeftIcon } from '@assets/icons';
import { dataManipulationComponent } from './functions';
import './styles.scss';

const TableTemplateMobile = ({
    tableList,
    data,
    totalPage,
    page,
    handlePageClick,
    isDynamicTable,
    usePagination,
    onClickRow,
}) => {
    return (
        <Box className="table-template-mobile__container">
            <Box className="table-container" overflow={data?.length ? 'auto' : 'hidden'}>
                <Table w={isDynamicTable ? 'max-content' : '100%'}>
                    {/** --------------------------  TABLE HEAD -------------------------- */}

                    <Tr className="row-head__container">
                        {tableList?.map(({ name, width, isDivider, dataManipulationType, value }) => (
                            <Th
                                className={`table-head__item ${isDivider && 'divider'}`}
                                w={width}
                                maxWidth={width}
                                key={name}
                            >
                                {name}
                                {dataManipulationComponent({ dataManipulationType, valueName: value })}
                            </Th>
                        ))}
                    </Tr>

                    {/** --------------------------  TABLE COLUMN-------------------------- */}
                    {data?.length > 0 &&
                        data?.map((values) => (
                            <Tr className="row-column__container" onClick={() => onClickRow(values)}>
                                {tableList?.map((item) => (
                                    <Td
                                        className={`table-column__item ${item.isDivider && 'divider'}`}
                                        w={item?.width}
                                        maxWidth={item?.width}
                                    >
                                        {item.component({ value: values[item.value], values })}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                </Table>

                {isEmpty(data) && <EmptyStateMobile text={'Data tidak ditemukan'} description={''} />}
            </Box>

            {/** -------------------------- PAGINATION ---------------------------- */}

            {usePagination && (
                <Box className="section-pagination__container">
                    <Text className="showing-text"></Text>
                    <Flex className="pagination-host-menu__container">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<ChevronRightIcon />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={totalPage}
                            forcePage={page - 1}
                            previousLabel={<ChevronLeftIcon />}
                        />
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default TableTemplateMobile;
