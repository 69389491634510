import { Box, Text, Flex } from '@chakra-ui/react';
import { onOpenDetailCard } from '@pages/schedule/helpers/event';
import { IconAddCircle, PeopleIconMini } from '@assets/icons';
import { getListHost } from '@pages/schedule/helpers/utlis';

import './styles.scss';

const renderEventContent = ({ eventInfo, state, setState, navigate, clickDateHandler, headWidth }) => {
    const { accounts, eventName, hosts, status, eventId, studio, startedAt, timeOffset } =
        eventInfo.event.extendedProps;

    const onClickAddNew = (e) => {
        e.stopPropagation();
        clickDateHandler({ dateStr: startedAt, timeOffset });
    };

    return (
        <Box
            className="card-event__container"
            id={`card-event__container__${eventId}`}
            bg={status === 'Block' ? '#ffbfbf' : '#cdffe7'}
            onClick={() => onOpenDetailCard({ state, setState, eventId, navigate })}
        >
            <Box className="add-new" display="none" onClick={onClickAddNew}>
                <Box w="20px">
                    <IconAddCircle color="green" size={'20px'} />
                </Box>
                <Text className="text-click">Add schedule</Text>
            </Box>
            <Box>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text className="time-text" color={status === 'Block' ? '#c53542' : '#376837'}>
                        {eventInfo.timeText}
                    </Text>
                    <Text className="studio-text">{studio}</Text>
                </Flex>

                <Text className="event-text" color={status === 'Block' ? '#c53542' : '#376837'}>
                    {eventName}
                </Text>

                <Text className="host-text" color={status === 'Block' ? '#c53542' : '#376837'}>
                    Host: {getListHost(hosts)}
                </Text>
            </Box>
            <Flex className="section-bottom">
                <Flex className="account-box" bg={status === 'Block' ? '#d9a5a9' : '#9ee9d1'}>
                    {accounts?.length > 1 && (
                        <Box className="circle-count" bg={status === 'Block' ? 'var(--main-color)' : '#5da979'}>
                            +{accounts?.length - 1}
                        </Box>
                    )}
                    <PeopleIconMini color="var(--main-color)" />
                    <Text className="account-text" color={status === 'Block' ? '#c53542' : '#376837'}>
                        {accounts[0]?.name}
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
};

export default renderEventContent;
