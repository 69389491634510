const { Modal, ModalOverlay, ModalContent } = require('@chakra-ui/react');

const ModalTemplate = ({ onClose, children }) => {
    return (
        <Modal isOpen onClose={onClose}>
            <ModalOverlay onClick={onClose} />
            <ModalContent
                maxWidth="unset"
                w="max-content"
                maxH="95vh"
                overflow="auto"
                mt="0 !important"
                className="modal-content__container"
            >
                {children}
            </ModalContent>
        </Modal>
    );
};

export default ModalTemplate;
