//---------------------------- Library -------------------------
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

//---------------------------- Global Components -------------------------
import SideMenu from '@components/sideMenu';
import MobileFooter from '@components/mobileFooter';
import MobileHeader from '@components/mobileHeader';
import Header from '@components/header';
import { checkIsMobileHandler } from '@store/list/isMobile';
import { isHideMobileHeaderHandler, restrictPageHandler } from './functions';

//---------------------------- Local Components -------------------------
import './styles.scss';

const DashboardContainer = ({ className, children }) => {
    const [state, setState] = useState({ isHideSideMenu: false });
    const onChangeState = (newState) => setState({ ...state, ...newState });
    const [isHideHeader, setIsHideHeader] = useState(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onHideSideMenu = () => onChangeState({ isHideSideMenu: !state?.isHideSideMenu });
    const isLogin = useSelector((state) => state.loginSlice.isLogin);
    const { isMobile } = useSelector((state) => state.isMobileSlice);

    const handleScroll = () => {
        const pageEl = document.getElementById('page-container');
        setIsHideHeader(pageEl?.scrollTop > 60);
    };

    //------------------------------------------- RESTRICT PAGE ----------------------------------------------

    useEffect(() => {
        restrictPageHandler({ pathname, isLogin, navigate });
    }, [pathname, isLogin, navigate]);

    //------------------------------------------- CHECK ISMOBILE ----------------------------------------------

    useEffect(() => {
        /* eslint-disable */
        dispatch(checkIsMobileHandler());
    }, []);

    //------------------------------------------- HIDE OR SHOW HEADER MOBILE  ----------------------------------------------

    useEffect(() => {
        const pageEl = document.getElementById('page-container');

        if (isMobile) {
            pageEl?.addEventListener('scroll', handleScroll);
        }

        return () => {
            pageEl?.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile]);

    if (isLogin && pathname !== '/login' && !pathname.startsWith('/reset-password')) {
        return (
            <Box
                className={`${className} page-container ${state?.isHideSideMenu && 'hide-sidemenu'} `}
                id="page-container"
                paddingTop={isHideMobileHeaderHandler({ pathname, isMobile }) && '4px !important'} // adjust padding in pages
            >
                {isMobile ? (
                    <>
                        {!isHideMobileHeaderHandler({ pathname, isMobile }) && (
                            <MobileHeader isHideHeader={isHideHeader} />
                        )}{' '}
                        {children}
                        <MobileFooter />
                    </>
                ) : (
                    <>
                        {/** -------------- LEFT SECTION ---------------- */}

                        <Box className={`dashboard-section-left__container `}>
                            <SideMenu onHideSideMenu={onHideSideMenu} isHideSideMenu={state?.isHideSideMenu} />
                        </Box>

                        {/** -------------- RIGHT SECTION ---------------- */}
                        <Box className="dashboard-section-right__container">
                            <Header />
                            <Box className="dashboard-content__container">{children}</Box>
                        </Box>
                    </>
                )}
            </Box>
        );
    } else if (pathname === '/login' || pathname.startsWith('/reset-password')) {
        return children;
    } else {
        return <></>;
    }
};
export default DashboardContainer;
