import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CalendarIcon, CouponIcon, IconLogout, MainDashboardIcon } from '@assets/icons';
import { useDispatch } from 'react-redux';
import CircleActive from '@components/circleActive';
import { onLogout } from '@components/sideMenu/functions';

import './styles.scss';

const MobileFooter = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Flex className="mobile-footer__container">
            <Box className={`item ${pathname === '/' && 'active'}`} onClick={() => navigate('/')}>
                <MainDashboardIcon color={pathname === '/' && 'active' ? 'var(--main-color)' : 'black'} />
                <Text className="text">Home</Text>
                {pathname === '/' && <CircleActive />}
            </Box>
            <Box className={`item ${pathname === '/presence' && 'active'}`} onClick={() => navigate('/presence')}>
                <CouponIcon color={pathname === '/presence' && 'active' ? 'var(--main-color)' : 'black'} />
                <Text className="text">Absen</Text>
                {pathname === '/presence' && <CircleActive />}
            </Box>
            <Box className={`item ${pathname === '/schedule' && 'active'}`} onClick={() => navigate('/schedule')}>
                <CalendarIcon color={pathname === '/schedule' && 'active' ? 'var(--main-color)' : 'black'} />
                <Text className="text">Calendar</Text>
                {pathname === '/schedule' && <CircleActive />}
            </Box>
            <Box className={`item`} onClick={() => onLogout({ dispatch, navigate })}>
                <IconLogout color="black" />
                <Text className="text">Logout</Text>
            </Box>
        </Flex>
    );
};

export default MobileFooter;
