export const initialState = {
    isLoading: true,
    users: [],
    dataCount: 0,
    page: 1,
    totalData: 0,
    totalPage: 0,
    isCreatePage: false,
    isEditPage: false,
};
export const initialAssets = {
    brands: [],
    roles: [],
};
