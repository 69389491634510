import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { remove } from 'lodash';
import { popupHandler } from '@store/list/popup';
import { removeEventHandler } from './crud';
import { isMobileHandler } from '@helpers/util';

export const onOpenDetailCard = async ({ state, setState, eventId, navigate }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    if (isMobileHandler()) {
        return navigate(`/schedule/${eventId}?title=Detail Schedule`);
    }

    setState({
        ...state,
        isLoadingDetail: true,
        isOpenDetail: true,
    });

    try {
        const { data } = await Axios({
            url: `/schedule/${eventId}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        setState({
            ...state,
            selectedEvent: data?.data,
            isOpenDetail: true,
            isLoadingDetail: false,
        });
    } catch (error) {
        console.log(error);
        setState({
            ...state,
            selectedEvent: null,
            isOpenDetail: true,
            isLoadingDetail: false,
        });
    }
};

export const onDeleteEvent = ({ dispatch, selectedEvent, onClose, refetchScheduleHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus event ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeEventHandler({
                    selectedEvent,
                    onClose,
                    dispatch,
                    refetchScheduleHandler,
                }),
        })
    );
};

export const onOpenEditPopUp = ({ state, setState }) => {
    setState({
        ...state,
        isOpenEditEvent: true,
        isOpenDetail: false,
    });
};

export const onChangeCheckbox = ({ value, setFilterShow, filterName, filterShow }) => {
    if (value) {
        setFilterShow([...filterShow, filterName]);
    } else {
        const newArray = remove(filterShow, (item) => item !== filterName);
        setFilterShow(newArray);
    }
};

export const onResetFilter = ({ onUpdateMainState }) => {
    onUpdateMainState({
        brand: '',
        ecommerce: '',
        account: [],
        host: '',
        studio: '',
    });
};