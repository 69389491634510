import { Box, Spinner, Text } from '@chakra-ui/react';
import { IllustrationWaiting1 } from '@assets/illustrations';
import './styles.scss';

export const LoadingState1 = () => {
    return (
        <Box className="loading-state1__container">
            <Box className="icon-jiggle">
                <IllustrationWaiting1 />
            </Box>
            <Text className="loading-state__text">Sedang mengambil data.....</Text>
        </Box>
    );
};

export const LoadingState2 = () => {
    return (
        <Box className="loading-state2__container">
            <Spinner color="var(--main-color)" size={'lg'} />
        </Box>
    );
};

export const LoadingState3 = () => {
    return (
        <Box className="loading-state3__wrapper">
            <Box className="loading-state3__container">
                <Box className="box">
                    <Spinner color="white" size={'lg'} />
                </Box>
            </Box>
        </Box>
    );
};
