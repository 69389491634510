import { Box } from '@chakra-ui/react';

export const onChangeFormField = ({ fieldName, value, onChangeState, accountId, state }) => {
    onChangeState({
        form: {
            ...state.form,
            [accountId]: {
                ...state.form[accountId],
                [fieldName]: value,
            },
        },
    });
};

export const onCopyClipboard = ({ toast }) => {
    toast({
        position: 'bottom-center',
        duration: 500,
        isClosable: true,
        render: () => <Box className="toast-container__clipboard">ID berhasil di copy</Box>,
    });
};
