import { Box } from '@chakra-ui/react';
import { useEffect, useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TableSkeleton from '@components/tableSkeleton';
import SectionBody from './sections/sectionBody';
import SectionHead from './sections/sectionHead';
import UploadPopUp from './components/uploadPopUp';
import { fetchTargetUpload } from './helpers/crud';
import { onShowingRemovePopUp, onShowingUpdateRowPopUp } from './helpers/events';
import { initialState, initialFilter, initialAssets } from './const';

import './styles.scss';

export const TargetScheduleContext = createContext();

const TargetSchedule = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [filter, setFilter] = useState(initialFilter);
    const [assets, setAssets] = useState(initialAssets);

    const { isOpenUpload, isLoading, isOpenEditRowTarget, selectedTarget } = state;

    const refetchTargetUpload = () => fetchTargetUpload({ onChangeState, state, search });
    const onPagePaginationClick = ({ selected }) => setState({ ...state, page: selected + 1 });

    const onChangeState = (newState) => setState({ ...state, ...newState });
    const onChangeFilter = (newState) => setFilter({ ...filter, ...newState });
    const onChangeAssets = (newState) => setAssets({ ...state, ...newState });

    useEffect(() => {
        fetchTargetUpload({ onChangeState, state, search });
        // eslint-disable-next-line
    }, [state?.page, filter, search]);

    return (
        <TargetScheduleContext.Provider
            value={{
                onShowingRemovePopUp: ({ selectedTarget }) =>
                    onShowingRemovePopUp({ dispatch, refetchTargetUpload, selectedTarget }),
                onEditColumnHandler: ({ selectedTarget }) =>
                    onChangeState({ isOpenEditRowTarget: true, selectedTarget }),
                selectedTarget,
                isOpenEditRowTarget,
                onChangeState,
                onShowingUpdateRowPopUp: () =>
                    onShowingUpdateRowPopUp({ dispatch, refetchTargetUpload, selectedTarget }),
            }}
        >
            <Box className="target-schedule__container">
                <SectionHead
                    onChangeState={onChangeState}
                    onChangeFilter={onChangeFilter}
                    filter={filter}
                    assets={assets}
                    onChangeAssets={onChangeAssets}
                />

                {isLoading ? (
                    <TableSkeleton />
                ) : (
                    <SectionBody state={state} onChangeState={onChangeState} handlePageClick={onPagePaginationClick} />
                )}

                {isOpenUpload && (
                    <UploadPopUp onChangeMainState={onChangeState} refetchTargetUpload={refetchTargetUpload} />
                )}
            </Box>
        </TargetScheduleContext.Provider>
    );
};

export default TargetSchedule;
