import { removeUserHandler } from './crud';
import { popupHandler } from '@store/list/popup';

export const onShowingRemovePopUp = async ({ selectedUser, dispatch, refetchUserHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus user ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeUserHandler({
                    selectedUser,
                    refetchUserHandler,
                }),
        })
    );
};
