import { Flex, Box, Text } from '@chakra-ui/react';
import { HamburgerIcon } from '@assets/icons';
import { LiveLogo } from '@components/sideMenu/icons';
import './styles.scss';

const MobileHeader = ({ isHideHeader }) => {
    return (
        <Flex className={`mobile-header__container ${isHideHeader && 'active-header__mobile'}`}>
            <Flex alignItems="center">
                <Box className="logo-image">
                    <LiveLogo />
                </Box>
                <Text className="logo-text">Livestream</Text>
            </Flex>
            <HamburgerIcon color="#464040" />
        </Flex>
    );
};

export default MobileHeader;
