import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const fetchImageHandler = async ({ fileId, onChangeState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    onChangeState({
        isLoading: true,
    });
    try {
        const { data } = await Axios({
            method: 'POST',
            url: `/download/presigned-url`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                fileId,
            },
        });

        onChangeState({
            isLoading: false,
            source: data?.data?.url,
        });
    } catch (error) {
        console.log(error, '<<<<');

        onChangeState({
            isLoading: false,
            source: null,
        });
    }
};
