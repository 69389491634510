import { popupHandler } from '@store/list/popup';
import { removeSinglePromotion, rejectSinglePromotionHandler, approveBulkPromotion } from './crud';

export const onShowingRemovePopUp = async ({ selectedPromotion, dispatch, refetchPromotionHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-delete',
            popUpTitle: 'Apakah anda yakin ingin menghapus promotion ini?',
            popUpDescription: '',
            onSubmit: () =>
                removeSinglePromotion({
                    selectedPromotion,
                    refetchPromotionHandler,
                }),
        })
    );
};

export const onShowingCommentPopUp = async ({ selectedPromotion, dispatch, refetchPromotionHandler }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-comment',
            popUpTitle: selectedPromotion,
            popUpDescription: '',
        })
    );
};

export const onShowingResetApprovalPopUp = ({ dispatch, refetchPromotionHandler, selectedPromotion }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: `Apakah Anda Yakin Ingin Reject promotion`,
            onSubmit: () => {
                rejectSinglePromotionHandler({ dispatch, idPromotion: selectedPromotion?.id, refetchPromotionHandler });
            },
        })
    );
};

export const onCheckboxHandler = ({ selectedPromotion, onChangeState, multiSelectPromotion }) => {
    // Check if the coin is already selected
    const isSelected = multiSelectPromotion.includes(selectedPromotion.id);

    if (isSelected) {
        // If it's selected, remove it from the selected coins
        const data = multiSelectPromotion.filter((coin) => coin !== selectedPromotion.id);
        onChangeState({ multiSelectPromotion: data });
    } else {
        // If it's not selected, add it to the selected coins
        const data = multiSelectPromotion ? [...multiSelectPromotion, selectedPromotion.id] : [selectedPromotion.id];
        onChangeState({ multiSelectPromotion: data });
    }
};

export const onShowingApprovingPopUp = async ({ dispatch, refetchPromotionHandler, state }) => {
    dispatch(
        popupHandler({
            popUpType: 'popup-confirmation-submit',
            popUpTitle: `Apakah Anda Yakin Ingin Approve promotion`,
            onSubmit: () => approveBulkPromotion({ dispatch, refetchPromotionHandler, state }),
        })
    );
};
