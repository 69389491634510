import { Flex, Text, Checkbox, Button } from '@chakra-ui/react';
import OutsideClickHandler from 'react-outside-click-handler';
import { onChangeCheckbox } from '@pages/schedule/helpers/event';
import { filterList } from '../const';
import { useState } from 'react';

const FilterSettingPopUp = ({ onChangeState, onClosePopup, initiateFilterShow }) => {
    const [filterShow, setFilterShow] = useState(initiateFilterShow);

    return (
        <OutsideClickHandler onOutsideClick={onClosePopup}>
            <Flex className="popup-container">
                <Flex className="head">
                    <Text className="title">Pilih Filter</Text>
                </Flex>

                <Flex className="body">
                    {filterList?.map(({ name, value }) => (
                        <Flex className="item">
                            <Text className="name">{name}</Text>
                            <Checkbox
                                className="box-checkbox"
                                size="md"
                                isChecked={filterShow?.includes(value)}
                                onChange={(event) =>
                                    onChangeCheckbox({
                                        value: event.target.checked,
                                        filterName: value,
                                        setFilterShow,
                                        filterShow,
                                    })
                                }
                            />
                        </Flex>
                    ))}
                </Flex>

                <Flex className="footer">
                    <Button className="cancel-button" onClick={onClosePopup}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() =>
                            onChangeState({
                                filterShow: filterShow,
                                isOpenSettingPopup: false,
                            })
                        }
                    >
                        Submit
                    </Button>
                </Flex>
            </Flex>
        </OutsideClickHandler>
    );
};

export default FilterSettingPopUp;
