import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Flex, Button } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TableTemplate from '@components/tableTemplate';
import { tableList, reportingTypes } from './const';
import { DownloadIcon } from '@assets/icons';
import { onDownloadData } from '../../helpers/events';

import './styles.scss';

const SectionBody = ({ handlePageClick, onChangeState, state, resetFilter, filter }) => {
    const { reporting, dataCount, totalPage, totalData, page, reportingType } = state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const onChangeTab = ({ reportingType }) => {
        onChangeState({ reportingType, page: 1 });
        resetFilter();
        navigate('/reporting-achievement');
    };

    return (
        <Box className="section-body__container__reporting-achievement">
            <Tabs colorScheme="red" index={reportingTypes.indexOf(reportingType)}>
                <TabList className="tab-list">
                    <Flex>
                        {reportingTypes?.map((type) => (
                            <Tab className="tab-item" onClick={() => onChangeTab({ reportingType: type })} key={type}>
                                {type}
                            </Tab>
                        ))}
                    </Flex>

                    <Button
                        className="button-download"
                        onClick={() => onDownloadData({ state, search, filter, dispatch })}
                    >
                        <DownloadIcon size="12px" styles={{ marginRight: '6px' }} />
                        Download
                    </Button>
                </TabList>

                {!state?.isLoading && (
                    <TabPanels>
                        {reportingTypes?.map((type) => (
                            <TabPanel className="table-panel">
                                <TableTemplate
                                    tableList={tableList[type]}
                                    data={reporting}
                                    dataCount={dataCount}
                                    totalData={totalData}
                                    page={page}
                                    totalPage={totalPage}
                                    handlePageClick={handlePageClick}
                                    isDynamicTable
                                />
                            </TabPanel>
                        ))}
                    </TabPanels>
                )}
            </Tabs>
        </Box>
    );
};

export default SectionBody;
