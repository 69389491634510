export const initialState = {
    brand: [],
    email: '',
    name: '',
    password: '',
    handphone: '',
    address: '',
    dateOfBirth: '',
    gender: '',
    placeOfBirth: '',
    domicile: '',
    roleId: '',
    username: '',
    isLdap: false,
    baId: '',
    isLoading: false,
};
export const genderTypes = [
    { label: 'Laki-laki', value: 'Laki-laki' },
    { label: 'Perempuan', value: 'Perempuan' },
];
export const isLdapTypes = [
    { label: 'Iya', value: true },
    { label: 'Tidak', value: false },
];
