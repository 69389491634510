import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { CoinIcon, PromotionIcon, VoucherIcon } from '@assets/icons';

export const fetchNotificationHandler = async ({ onChangeState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/notification`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({ notifications: data?.data?.notifications });
    } catch (error) {
        console.log(error);
    }
};

export const notificationIcon = ({ type, color }) => {
    switch (type) {
        case 'voucher':
            return <VoucherIcon color={color} />;
        case 'promotion':
            return <PromotionIcon color={color} />;
        case 'coin':
            return <CoinIcon color={color} />;
        default:
            break;
    }
};
