import { Flex, Text, Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchData } from '@helpers/util';
import { ResetIcon } from '@assets/icons';
import { tableList } from '../sectionBody/const';
import './styles.scss';

const SectionHead = ({ setState, state }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Flex className="section-header__container__role-management">
            {/** -------------------------- LEFT SIDE ----------------------------**/}
            <Flex className="left-side">
                {location?.search ? (
                    <Flex>
                        <Text className="showing-text ">
                            Menampilkan {state?.dataCount} dari {state?.totalData}{' '}
                            {searchData({ location, tableList }).type} untuk
                            <span>"{searchData({ location, tableList }).name}"</span>
                        </Text>
                        <Box ml="4px" onClick={() => navigate('/account-data')} cursor="pointer">
                            <ResetIcon color="var(--main-color)" />
                        </Box>
                    </Flex>
                ) : (
                    <Text className="title">Role Management</Text>
                )}
            </Flex>

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            {/* <Flex className="right-side">
                <ButtonAdd
                    textButton="Add Role"
                    width="120px"
                    onClick={() => setState({ ...state, isCreatePage: true })}
                />
            </Flex> */}
        </Flex>
    );
};

export default SectionHead;
